/* eslint-disable no-return-await */
/* eslint-disable import/extensions */
import { useQuery, useMutation } from 'react-query';
import axios from 'axios';

import SkorrApi from '../../Services/SkorrApi/new';
import {
  logApiRequestError,
  isAuthenticated,
} from '../../Services/Utils/Utils';
import { IErrorObject } from '../../Services/Utils/types';
import { ISignInParams } from '../../Services/SkorrApi/new/Auth/types';
import { ISigninData } from './types';

const { auth } = SkorrApi;

export const useSendForgotPasswordCodeEmail = (
  setHasError: (hasError: boolean) => any,
  setMsgError: (errorMsg: string) => any,
  t: (path: string) => string,
) => {
  return useMutation(async (email: string) => {
    try {
      const { data }: { data: boolean } =
        await auth.sendForgotPasswordCodeEmail(email);
      return data;
    } catch (error) {
      setHasError(true);
      if ((error as any).response!.data.message.includes('Thirty seconds')) {
        setMsgError(t('signin.generic.verificationCodeEmailTimeoutError'));
      } else if (
        (error as any).response!.data.message.includes('maximum ammount')
      ) {
        setMsgError(t('signin.generic.verificationCodeEmailCapError'));
      } else {
        setMsgError(
          t(
            'signin.generic.forgotPasswordCodeCouldNotBeSentToTheProvidedEmail',
          ),
        );
      }
      logApiRequestError(error as IErrorObject);
      throw error;
    }
  });
};

export const useVerifyForgotPasswordCode = (
  email: string,
  code: string,
  enabled: boolean,
) => {
  return useQuery(
    ['forgotPassword', `${email}`],
    async () => {
      try {
        const { data }: { data: boolean } = await auth.verifyForgotPasswordCode(
          email,
          code,
        );
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
      refetchOnWindowFocus: false,
      staleTime: 500000,
      enabled,
    },
  );
};

export const useUpdatePasswordWithCode = () => {
  return useMutation(
    async ({
      email,
      code,
      password,
    }: {
      email: string;
      code: string;
      password: string;
    }) => {
      try {
        const { data }: { data: boolean } = await auth.updatePasswordWithCode(
          email,
          code,
          password,
        );
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
  );
};

export const useSigninMutation = (
  setHasError: (hasError: boolean) => any,
  setMsgError: (errorMsg: string) => any,
  t: (arg: string) => string,
) => {
  return useMutation(
    async (userCredentials: ISignInParams) => {
      try {
        const { data }: { data: { result: ISigninData } } = await auth.signIn(
          userCredentials,
        );
        return data.result;
      } catch (error) {
        setHasError(true);
        if ((error as any).response?.data?.errorCode === 'AUTH_0000001') {
          setMsgError(t('signin.generic.incorrectEmailPasswordCombination'));
        } else {
          setMsgError(t('signin.generic.couldNotSignIn'));
        }
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      // onSuccess: (data: ISigninData | undefined) => {
      //     jwtParser(data as ISigninData);
      // },
      retry: false,
    },
  );
};

export const useIsOperatorOrPartnerAdmin = (isBrandOrAgency: boolean) => {
  const agentId = isAuthenticated()?.userId;
  return useQuery(
    ['isOperatorOrPartnerAdmin', agentId],
    async () => {
      try {
        const { data }: { data: boolean } =
          await auth.isOperatorOrPartnerAdmin();
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled: isBrandOrAgency && !!agentId,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
};

export const verifyToken = async (token: string) => {
  return await axios
    .post('http://localhost:3334/v1/authentication/validate-token-captcha', {
      token,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      console.log('TOKEN: ', err);
      throw err.response;
    });
};
