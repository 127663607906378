import LoaderFixed from 'components/shared/LoaderFixed/LoaderFixed';
import Pagination from 'components/shared/Pagination/Pagination';
import { DiscoverProfilesCardGrid, DiscoverToolBar } from 'features/Discover';
import DiscoverEmptyState from 'features/Discover/components/DiscoverEmptyState';
import DiscoverSaveUsersList from 'features/Discover/components/DiscoverSaveUsersList';
import { useDiscoverContext } from 'features/Discover/hooks/contexts/use-discover-context';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';

import * as S from './Styles';

const Discover = () => {
  const {
    page,
    resultsPerPage,
    handleChangePage,
    publicUsersByFilter,
    isLoadingPublicUsersByFilter,
  } = useDiscoverContext();

  return (
    <>
      <LoaderFixed
        show={isLoadingPublicUsersByFilter}
        message={t('generic.loading')}
      />

      <DiscoverToolBar />

      {publicUsersByFilter?.total === 0 ? (
        <DiscoverEmptyState />
      ) : (
        <S.MainContainer>
          <DiscoverProfilesCardGrid />

          <Pagination
            page={page}
            changePage={handleChangePage}
            totalRecordsPerPage={resultsPerPage}
            totalRecords={publicUsersByFilter?.total ?? 0}
          />
        </S.MainContainer>
      )}

      <DiscoverSaveUsersList />
    </>
  );
};

export default withTranslation()(Discover);
