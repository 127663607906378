import InstagramIcon from 'assets/iconComponents/InstagramIcon';
import TiktokIcon from 'assets/iconComponents/TiktokIcon';
import YoutubeIcon from 'assets/iconComponents/YoutubeIcon';
import i18n from 'i18n/i18n';
import { GENDERS } from 'Services/Utils/types';

export const gendersAvailableValues = [
  {
    value: GENDERS.MALE,
    label: i18n.t('userProfile.male'),
  },
  {
    value: GENDERS.FEMALE,
    label: i18n.t('userProfile.female'),
  },
  {
    value: GENDERS.OTHER,
    label: i18n.t('userProfile.other'),
  },
];

export const socialNetworkAvailableValues = [
  {
    value: 'INSTAGRAM',
    label: (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <InstagramIcon
          svgWidth="2rem"
          svgHeight="2rem"
          gradientColored={true}
          containerMarginRight="1rem"
        />
        {i18n.t('socialNetworks.INSTAGRAM')}
      </div>
    ),
  },
  {
    value: 'YOUTUBE',
    label: (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <YoutubeIcon
          svgWidth="2rem"
          svgHeight="2rem"
          containerMarginRight="1rem"
        />
        {i18n.t('socialNetworks.YOUTUBE')}
      </div>
    ),
  },
  {
    value: 'TIKTOK',
    label: (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <TiktokIcon
          svgWidth="2rem"
          svgHeight="2rem"
          containerMarginRight="1rem"
        />
        {i18n.t('socialNetworks.TIKTOK')}
      </div>
    ),
  },
];

export const categoriesAvailableValues = [
  { value: 'BRAND', label: i18n.t('discover.categories.BRAND') },
  { value: 'BLOG', label: i18n.t('discover.categories.BLOG') },
  { value: 'INFLUENCER', label: i18n.t('discover.categories.INFLUENCER') },
  { value: 'SOCIALMEDIA', label: i18n.t('discover.categories.SOCIALMEDIA') },
];
