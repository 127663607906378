/* eslint-disable consistent-return */
/* eslint-disable default-case */
import { FaFemale, FaMale, FaMapMarkerAlt } from 'react-icons/fa';
import styled from 'styled-components';

import { FilterEntryProps, IUserSocialNetworkCircleProps } from './types';

export const NameContainer = styled.div`
  display: flex;
  margin-left: 0.4rem;
  margin-bottom: 0.8rem;
  align-items: center;
`;

export const NameContent = styled.h5`
  font-size: 2.6rem;
  font-weight: 600;
  line-height: 1.2;
  display: block;
`;

export const UserHandlerVerifiedIconContainer = styled.a`
  display: inline-flex;
  align-items: center;
  margin-bottom: 0.8rem;
`;

export const UserHandlerText = styled.i`
  color: rgb(0, 123, 255);
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
`;

export const UserHandlerTextSmall = styled.small`
  font-style: normal;
  cursor: pointer;
`;

export const UserGenderAgeContainer = styled.div`
  display: flex;

  @media (max-width: 1320px) {
    justify-content: center;
  }
`;

export const MaleIcon = styled(FaMale)`
  color: #8492a6;
`;

export const FemaleIcon = styled(FaFemale)`
  color: #8492a6;
`;

export const UserGenderAgeContent = styled.h6`
  font-size: 1.6rem;
  margin-bottom: 0.8rem;
  font-weight: 500;
`;

export const UserLocationContainer = styled.p`
  color: #8492a6;
  font-size: 1.28rem;
  margin-bottom: 0.5rem;

  @media (max-width: 1320px) {
    text-align: center;
  }
`;

export const LocationIcon = styled(FaMapMarkerAlt)`
  color: #8492a6;
`;

export const UserBiographyContainer = styled.small`
  line-height: 1.4;
  display: flex;
  font-size: 80%;
  width: 100%;
  max-height: 7rem;
  overflow-y: auto;

  @media (max-width: 1320px) {
    text-align: center;
    justify-content: center;
  }
`;

export const UserTagsContainer = styled.span`
  padding: 0.64rem 0.8rem;
  color: #29435d;
  font-size: 1.36rem;
  font-weight: 400;
  line-height: 1;
  margin-right: 0.8rem;
  margin-top: 0.8rem;
  border: 0.1rem solid #cbcbcb;
  border-radius: 0.4rem;
`;

export const UserHashTagsContainer = styled.span`
  background: #eff1f6;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(41, 67, 93);
  padding: 0.4rem 1.4rem;
  font-size: 1.4rem;
  border-radius: 1.9rem;
  height: 3.2rem;
  white-space: nowrap;
  margin-top: 0.4rem;
  margin-right: 0.4rem;

  @media (max-width: 1320px) {
    text-align: center;
  }
`;

export const UserHashtagsOuterContainer = styled.div`
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  width: calc(100% - 2.5rem);
  overflow-x: auto;
  height: auto;

  @media (max-width: 1320px) {
    margin-right: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export const PartnerContainer = styled.div`
  cursor: pointer;
  margin-right: -1rem;
  margin-left: 2rem;
  display: inline-flex;
  flex-wrap: wrap;

  img {
    max-width: unset;
    max-height: 8rem;
  }

  @media (max-width: 1320px) {
    margin-right: unset;
    padding-top: 0.5rem;
  }
`;

export const PartnerText = styled.p`
  color: #8492a6;
  font-size: 1.28rem;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
`;

export const UserSocialNetworkContainer = styled.div``;

export const UserSocialNetworkColumns = styled.div`
  display: flex;
  border-top: 0.1rem solid rgb(231, 235, 244);
  -webkit-box-align: center;
  align-items: center;

  @media (max-width: 1150px) {
    flex-direction: column;
    padding-top: 1rem;
  }
`;

export const SocialNetworkContainer = styled.div`
  display: flex;
  min-width: 25.7rem;
  padding-left: 2.4rem;

  @media (max-width: 1150px) {
    margin: 2rem 0 1rem 0;
    justify-content: center;
    padding-left: 0;
  }
`;

export const UserSocialNetworkCircle = styled.div<IUserSocialNetworkCircleProps>`
  margin: 0px 0.8rem 0px 0px;
  width: 4.2rem;
  height: 4.2rem;
  border-radius: 10rem;
  text-align: center;
  line-height: 4.2rem;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
`;

export const UserSocialNetworkIcon = styled.img`
  font-size: 2.88rem;
  line-height: 4.48rem;
  width: inherit;
`;

export const UserSocialNetworkLink = styled.span`
  color: rgb(22, 43, 57);
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 4rem;
  margin-left: 1.6rem;
  text-decoration: none;

  @media (max-width: 1150px) {
    margin-left: 0;
  }
`;

export const UserCardKpisContainer = styled.div`
  display: flex;
  justify-content: space-around;
  -webkit-box-flex: 1;
  flex-grow: 1;
  -webkit-box-pack: justify;
  flex-wrap: wrap;

  @media (max-width: 1150px) {
    justify-content: start;
    margin: 2rem;
  }
`;

export const TimeIntervalOuterContainer = styled.div`
  margin-left: auto;
  margin-right: 2rem;
  display: inline-flex;
  align-items: center;
  width: 19rem;

  @media (max-width: 1200px) {
    justify-content: center;
  }

  @media (max-width: 1320px) {
    margin-left: 0;
    margin-right: 0;
  }

  @media (max-width: 800px) {
    width: 100%;
    text-align: center;
  }
`;

export const TimeInvervalContainer = styled.small`
  color: #6c757d;
  font-size: 1.6rem;
`;

export const UserKpisContainer = styled.div`
  display: grid;
  width: auto;
  padding: 2.4rem;

  @media (max-width: 650px) {
    width: 50%;
    text-align: center;
  }
`;

export const UserKpisValue = styled.span`
  color: rgb(22, 43, 57);
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.72rem;
`;

export const UserKpisTitle = styled.span`
  color: rgb(132, 146, 166);
  font-weight: 400;
  font-size: 1.28rem;
  margin-top: 0.4rem;
`;

export const UserAnalyticsPanelContainer = styled.div`
  display: flex;

  @media (max-width: 1320px) {
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
  }
`;

export const GoBackButton = styled.div`
  width: 4rem;
  height: 4rem;
  margin: 1rem;
  display: flex;
  min-width: 4rem;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 2rem;
    height: 2rem;
    vertical-align: middle;
  }

  &:hover {
    background: #f7f8fa;
  }

  &:active {
    background: #eff1f6;
  }
`;

export const UserAnalyticsPanelPictureContainer = styled.div`
  display: inline-block;
  width: 23rem;

  @media (max-width: 1320px) {
    display: flex;
    justify-content: center;
  }
`;

export const UserAnalyticsPanelPicturePadding = styled.div`
  padding: 0;
  margin: 4rem;
  margin-bottom: 2rem;
`;

export const UserAnalyticsPanelPicturePosition = styled.div`
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
`;

export const UserAnalyticsPanelPictureBackground = styled.div`
  position: absolute;
`;

export const UserAnalyticsPanelPictureBackgroundCircle = styled.div`
  background: rgb(237, 241, 249);
  border-radius: 50%;
  height: 19.6rem;
  width: 19.6rem;
  margin-left: -0.8rem;
`;

export const UserAnalyticsPanelPictureUser = styled.div`
  position: relative;
  overflow: hidden;
  width: 18rem;
  height: 18rem;
  min-width: 18rem;
  border-radius: 10rem;
  margin-right: 1.6rem;
  background-color: rgb(229, 233, 236);

  @media (max-width: 1320px) {
    margin-right: 0rem;
  }
`;

export const UserAnalyticsPanelPictureUserImage = styled.img`
  width: 18rem;
  height: 18rem;
`;

export const UserAnalyticsPanelPictureUserSkorrValue = styled.span`
  position: absolute;
  left: 13.5rem;
  top: 13.5rem;
  width: 5rem;
  height: 5rem;
  background: #30384d;
  font-weight: 700;
  border-radius: 10rem;
  color: #fff;
  font-size: 2.5rem;
  line-height: 4.4rem;
  text-align: center;
  border: 0.4rem solid #fe669f;
`;

export const UserAnalyticsPanelMidSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 4rem 2rem 1.5rem 4rem;
  padding-left: 0;
  flex-grow: 1;

  @media (max-width: 1200px) {
    width: auto;
  }

  @media (max-width: 1320px) {
    padding-left: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 2rem;
    margin-bottom: 0;
  }
`;

export const UserAnalyticsPanelMidSectionRow = styled.div`
  margin: 0;
  display: flex;
  flex-wrap: wrap;

  & > div:first-child {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Roboto, Arial, sans-serif;
  }

  @media (max-width: 1320px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

export const UserAnalyticsPanelMidSectionRowInner = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Roboto, Arial, sans-serif;
`;

export const UserTagsMainContainer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  width: auto;

  @media (max-width: 1320px) {
    margin-top: 1rem;
    justify-content: center;
  }
`;

export const UserHashTagsMainContainer = styled.div`
  margin: 0;
  margin: 2rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;

  @media (max-width: 1320px) {
    text-align: center;
  }

  & div:nth-child(2) {
    @media (max-width: 1320px) {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      padding: 0.2rem;
    }
  }
`;

export const UserHashtagsInnerContainer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: start;
  width: 100%;

  @media (max-width: 1320px) {
    justify-content: center;
  }
`;

export const UserHashTagsIcon = styled.div`
  margin-top: 0.8rem;
  width: 2.4rem;

  &::after {
    content: '#';
    font-size: 2.4rem;
    position: relative;
    line-height: 2.56rem;
    font-weight: 100;
    font-family: monospace;
    color: rgb(22, 43, 57);
  }

  @media (max-width: 1320px) {
    text-align: center;
  }
`;

export const UserAnalyticsPanelRadialChartContainer = styled.div`
  display: inline-block;
  width: 36rem;
`;

export const UserAnalyticsPanelMargin = styled.div`
  margin-top: 2.4rem;
`;

export const UserCardInnerContainer = styled.div`
  border: solid 1px #eee;
  border-radius: 2rem;
  background-color: rgb(255, 255, 255);
`;

export const UserCardOuterContainer = styled.div`
  margin-bottom: 2rem;
  padding: 1rem 1rem 0rem;
`;

export const UserAnalyticsPanelMidSectionRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin: 4rem 4rem 1.5rem 4rem;
  padding-left: 0;
  align-items: end;
  flex-grow: 1;

  @media (max-width: 1320px) {
    padding-left: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }

  @media (max-width: 1200px) {
    width: auto;
  }
`;

export const UserVerifiedIconContainer = styled.div`
  color: #14b56f;
  font-size: 1.6rem;
  font-weight: 600;
  height: 5.2rem;
  border-radius: 2.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2.4rem 1rem 2rem;
  margin-bottom: 3rem;

  @media (max-width: 1320px) {
    width: 100%;
    margin-bottom: 1rem;
  }
`;

export const FiltersOuterContainer = styled.div``;

export const FiltersContainer = styled.div`
  display: flex;
  margin-bottom: 2rem;
  flex-wrap: wrap;

  & > div:last-child {
    margin-right: 0rem;
  }

  @media (max-width: 1320px) {
    padding-left: 1rem;
    padding-right: 1rem;
    justify-content: center;
    margin-bottom: 0;
  }
`;

export const FilterEntry = styled.div<FilterEntryProps>`
  background: #eff1f6;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(41, 67, 93);
  padding: 0.4rem 1.4rem;
  font-size: 1.4rem;
  border-radius: 1.9rem;
  min-height: 3.2rem;
  white-space: nowrap;
  margin-top: 0.4rem;
  margin-right: 0.4rem;
  cursor: ${props => props.cursor ?? 'default'};

  & > span {
    max-width: ${props => props.maxWidth ?? 'unset'};
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const FiltersModalContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
`;

export const FiltersModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 30%;
  height: 50%;
  border-radius: 2rem;
  background-color: white;
  z-index: 101;
  opacity: 1;
  border: 2px solid #eff1f6;
  box-shadow: 0 0 0.2rem #eff1f6;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  padding: 2.5rem;
  overflow-y: auto;

  @media (max-width: 1200px) {
    width: 50%;
  }

  @media (max-width: 800px) {
    width: 70%;
  }
`;

export const FiltersModalHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.2;
`;

export const FiltersModalBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;

  & > div {
    white-space: wrap;
    word-break: break-word;
    padding: 0.4rem 1.4rem;
  }
`;
