import styled from 'styled-components';

export const ToolBarSearchContainer = styled.div`
  display: flex;
  width: 24.4rem;
  height: 5.2rem;
  margin: 0 2rem;
  align-items: center;
  justify-content: flex-end;
`;

export const InputContainer = styled.div`
  display: flex;
  padding: 0 1.2rem;
  width: fit-content;
  align-items: center;
  border-radius: 5rem;
  background-color: #f7f8fa;

  svg {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
  }

  input {
    width: 14rem;
    height: 4rem;
    border: none;
    outline: none;
    color: #8492a6;
    line-height: 18;
    font-weight: 400;
    font-size: 1.4rem;
    background: transparent;
    transition: width 0.3s ease, color 0.3s ease;

    &::placeholder {
      color: #8492a6;
    }

    &:focus {
      width: 19rem;
      color: #8492a6;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-text-fill-color: #8492a6;
      -webkit-box-shadow: 0 0 0px 40rem #f7f8fa inset;
    }
  }
`;
