/* eslint-disable import/extensions */
/* eslint-disable default-param-last */
import { useMutation, useQuery } from 'react-query';

import SkorrApi from '../../Services/SkorrApi/new';
import { IErrorObject } from '../../Services/Utils/types';
import { logApiRequestError } from '../../Services/Utils/Utils';

const { user } = SkorrApi;

export type UseGetListByIdWithPublicUsersInput = {
  enabled: boolean;
  id: string;
};

export const useGetListByIdWithPublicUsers = ({
  enabled,
  id,
}: UseGetListByIdWithPublicUsersInput) => {
  return useQuery(
    ['get-list-by-id-with-public-users', id],
    async () => {
      try {
        const data = await user.getListByIdWithPublicUsers(id);

        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      staleTime: 0,
      retry: false,
    },
  );
};

type UseDeletePublicUserFromListIinput = {
  listId: string;
  publicUserId: string[];
};

export const useDeletePublicUserFromList = () => {
  return useMutation(
    async ({ listId, publicUserId }: UseDeletePublicUserFromListIinput) => {
      try {
        await user.deletePublicUserFromList(listId, publicUserId);
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
    },
  );
};
