/* eslint-disable consistent-return */
/* eslint-disable no-use-before-define */
import styled, { css } from 'styled-components';

import {
  BrandAvatarProps,
  BrandDropdownProps,
  BrandNameProps,
  MainContainerProps,
} from './types';

type Props = {
  bg?: string;
  active?: boolean;
  selected?: boolean;
  showNavBar?: boolean;
};

export const MainNavbar = styled.div<Props>`
  left: 0;
  top: 0;
  bottom: 0;
  width: 6rem;
  position: fixed;
  z-index: 9999;
  background: #fff;
  border-right: 1px solid #e0e5ea;
  display: ${({ showNavBar }) => (showNavBar ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  transition: all 0.08s ease;
  visibility: visible;
  opacity: 1;

  ${({ active }) =>
    active &&
    css`
      transform: translateX(-6rem);
      visibility: hidden;
      opacity: 0;
    `}

  @media (max-width: 600px), (max-height: 650px) {
    display: none;
  }
`;

export const LogoLink = styled.a`
  position: absolute;
  top: 1rem;

  img {
    width: 100%;
    max-width: 4rem;
  }
`;

export const WebappNav = styled.nav``;

export const NotificationDot = styled.div<Props>`
  position: absolute;
  right: 0.8rem;
  top: 0.6rem;
  width: 0.9rem;
  height: 0.9rem;
  background: #f5a623;
  z-index: 9999;
  border-radius: 50%;
  border: solid 0.2rem #fff;
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
`;

export const Hoverbox = styled.div`
  position: absolute;
  width: 4.4rem;
  left: 0;
  top: 0;
  bottom: 0;
  height: 4.4rem;
  border-radius: 0.6rem;
  display: flex;
  align-items: center;
  z-index: 999;
`;

export const NavIcon = styled.div`
  margin: 0 1rem;

  svg {
    height: 2.4rem;
    width: 2.4rem;
    fill: #8492a6;
    vertical-align: middle;
  }
`;

export const NavLabel = styled.div`
  color: #fff;
  font-size: 1.4rem;
  height: 1.6rem;
  font-weight: 500;
  white-space: nowrap;
  margin-left: 0.6rem;
  margin-right: 2.4rem;
  opacity: 0;
  visibility: hidden;
`;

export const NavItem = styled.div<Props>`
  height: 4.4rem;
  width: 4.4rem;
  margin: 1.2rem 0;
  border-radius: 0.6rem;
  position: relative;
  cursor: pointer;

  ${({ active }) =>
    active &&
    css`
      background-color: #5870f6;

      ${NotificationDot} {
        border: solid 0.2rem #5870f6 !important;
      }

      ${NavIcon} {
        svg {
          fill: #fff !important;
        }
      }
    `}

  &:hover {
    ${NotificationDot} {
      border: solid 0.2rem #292929 !important;
    }

    ${Hoverbox} {
      background-color: #292929 !important;
      width: auto !important;
    }

    ${NavLabel} {
      opacity: 1 !important;
      visibility: visible !important;
    }

    ${NavIcon} {
      svg {
        fill: #fff !important;
      }
    }
  }
`;

export const NavDropdown = styled.div`
  position: absolute;
  width: 18.7rem;
  height: auto;
  background: #373737;
  visibility: hidden;
  left: 5.3rem;
  top: 4.4rem;
  z-index: 998;
  border-bottom-right-radius: 0.6rem;
  box-sizing: border-box;
  padding: 0.8rem;
`;

export const DropdownItem = styled.a<Props>`
  font-size: 1.2rem;
  font-weight: 400;
  color: #fff;
  padding: 1.4rem;
  border-radius: 0.6rem;
  position: relative;
  padding-right: 2.4rem;
  display: block;
  text-decoration: none;
  cursor: pointer;

  ${({ active }) =>
    active &&
    css`
      &::after {
        content: '';
        position: absolute;
        right: 1.4rem;
        top: 1.8rem;
        width: 0.5rem;
        height: 0.5rem;
        background: #f5a623;
        z-index: 9999;
        border-radius: 50%;
      }
    `}

  &:hover {
    background-color: #67676721;
  }
`;

export const SubMenuContainer = styled.div<Props>`
  height: 4.4rem;
  width: 4.4rem;
  margin: 1.2rem 0;
  border-radius: 0.6rem;
  position: relative;

  ${({ active }) =>
    active &&
    css`
      background-color: #5870f6;

      ${NotificationDot} {
        border: solid 0.2rem #5870f6 !important;
      }

      ${NavIcon} {
        svg {
          fill: #fff !important;
        }
      }
    `}

  &:hover {
    ${NotificationDot} {
      border: solid 0.2rem #292929 !important;
    }

    ${Hoverbox} {
      width: 24rem !important;
      background: #292929 !important;
      border-bottom-right-radius: 0 !important;
    }

    ${NavLabel} {
      opacity: 1 !important;
      visibility: visible !important;
    }

    ${NavDropdown} {
      visibility: visible !important;
    }
  }
`;

export const NavHelp = styled.div`
  position: absolute;
  bottom: 1rem;
  text-decoration: none;
  text-align: center;
  width: 4.4rem;
  border-top: solid 1px #ededed;
  padding-top: 2.4rem;
`;

export const NavHelpInner = styled.a`
  cursor: pointer;
`;

export const HelpIcon = styled.div`
  background: #f2f5fa;
  height: 4.2rem;
  width: 4.2rem;
  border-radius: 50%;
  margin-bottom: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: #c2c9d3;
    height: 1.8rem;
    width: 1.8rem;
    vertical-align: middle;
  }
`;

export const HelpText = styled.div`
  font-size: 1.3rem;
  font-weight: 400;
  color: #8492a6;
`;

export const TopBar = styled.div<Props>`
  position: fixed;
  right: 0;
  top: 0;
  height: ${props => props.theme['menu-navbar'].height};
  left: ${({ active }) => (active ? '0' : '6rem')};
  z-index: 99999;
  background: #fff;
  border-bottom: 1px solid #e0e5ea;
  display: ${({ showNavBar }) => (showNavBar ? 'flex' : 'none')};
  justify-content: space-between;
  align-items: center;
  transition: all 0.08s ease;
  box-sizing: content-box;

  @media (max-width: 600px), (max-height: 650px) {
    left: 0;
    height: 5.6rem;
  }
`;

export const TopBarLeft = styled.div<Props>`
  display: ${({ showNavBar }) => (showNavBar ? 'flex' : 'none')};
  align-items: center;
`;

export const MobileLogo = styled.a`
  display: none;

  @media (max-width: 430px) {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 3.6rem;
      height: 3.6rem;
    }
  }

  @media (max-width: 600px), (max-height: 650px) {
    display: inline-block;
    height: 4rem;
    width: 4rem;
    margin: 0 1rem;

    img {
      width: 100%;
      max-width: 4rem;
    }
  }
`;

export const ToggleMainbar = styled.div`
  height: 4rem;
  width: 4rem;
  min-width: 4rem;
  border-radius: 50%;
  margin: 0 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: 600px), (max-height: 650px) {
    display: none;
  }

  svg {
    height: 2rem;
    width: 2rem;
    fill: #adbcd2;
    vertical-align: middle;
  }

  &:active {
    background: #eff1f6;
  }

  &:hover {
    background: #f7f8fa;
  }
`;

export const BrandDropdown = styled.div<BrandDropdownProps>`
  max-width: calc(100vw - 18.6rem);
  background: #fff;
  height: 6rem;
  width: ${props => (props.isOperatorOrPartnerAdmin ? '23rem' : '20rem')};
  z-index: 5;
  cursor: pointer;
  pointer-events: ${props => (props.isOperatorOrPartnerAdmin ? 'all' : 'none')};

  @media (max-width: 600px), (max-height: 650px) {
    height: 5.6rem;
  }

  &:hover {
    background: #f7f8fa;
  }
`;

export const BrandProfileSelected = styled.div`
  height: 6rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  @media (max-width: 600px), (max-height: 650px) {
    height: 5.6rem;
  }
`;

export const BrandAvatarEdit = styled.div`
  display: none;

  svg {
    height: 2.4rem;
    width: 2.4rem;
    fill: #bfbfbf;
    vertical-align: middle;

    @media (max-width: 430px) {
      height: 2rem;
      width: 2rem;
    }
  }
`;

export const BrandAvatarEmpty = styled.div`
  svg {
    height: 2.4rem;
    width: 2.4rem;
    fill: #bfbfbf;
    vertical-align: middle;

    @media (max-width: 430px) {
      height: 2rem;
      width: 2rem;
    }
  }
`;

export const BrandAvatarUserImg = styled.img`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  vertical-align: middle;
  object-fit: cover;
`;

export const BrandAvatar = styled.div<BrandAvatarProps>`
  height: 4rem;
  width: 4rem;
  background: #f4f4f4;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1.2rem;
  min-width: 4rem;

  @media (max-width: 430px) {
    height: 3.2rem;
    width: 3.2rem;
    min-width: 3.2rem;
  }

  ${props => {
    if (props.isOperatorOrPartnerAdmin) {
      return css`
        &:hover {
          background: none;

          ${BrandAvatarEdit} {
            display: flex;
          }

          ${BrandAvatarEmpty} {
            display: none;
          }
        }
      `;
    }
  }}
`;

export const BrandName = styled.div<BrandNameProps>`
  flex-grow: 1;
  max-width: ${props =>
    props.isOperatorOrPartnerAdmin
      ? 'calc(100% - 11.8rem)'
      : 'calc(100% - 9rem)'};

  @media (max-width: 430px) {
    max-width: calc(100% - 10.4rem);
  }
`;

export const LabelBrandname = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #292929;
`;

export const LabelAccounts = styled.div`
  font-size: 1.1rem;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #676767;
  margin-top: 0.4rem;
`;

export const BrandDropdownIcon = styled.div<Props>`
  margin: 0 1.6rem;

  ${({ active }) =>
    active &&
    css`
      svg {
        transform: rotate(180deg);
        fill: #292929 !important;
      }
    `}

  @media (max-width: 430px) {
    margin: 0 0.8rem;
  }

  svg {
    height: 1.6rem;
    width: 1.6rem;
    fill: #adbcd2;
    vertical-align: middle;
  }
`;

export const BrandDropdownMenuOuter = styled.div<Props>`
  display: flex;
  flex-direction: column;
  max-width: calc(100vw - 18.6rem);
  width: 23.3rem;
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
  opacity: ${({ active }) => (active ? '1' : '0')};
  position: absolute;
  top: 6rem;
  left: 6.4rem;
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  z-index: 3;
  background: #fff;
  transition: opacity 0.08s;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12),
    0px 3px 5px rgba(0, 0, 0, 0.2);

  @media (max-width: 600px), (max-height: 650px) {
    padding-top: 5.6rem;
    left: 6rem;
  }

  ${({ active }) =>
    active &&
    css`
      ${BrandName} {
        max-width: calc(100% - 8.2rem) !important;
      }
    `}
`;

export const BrandDropdownMenu = styled.div`
  max-width: calc(100vw - 18.6rem);
  width: 23.3rem;
  height: calc(100% - 6rem);
  overflow: auto;
  background: #fff;
  transition: opacity 0.08s;

  &::-webkit-scrollbar,
  & *::-webkit-scrollbar {
    width: 0.7rem;
    height: 0.7rem;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track,
  & *::-webkit-scrollbar-track {
    border-radius: 0.4rem;
    background-color: ${props =>
      props.theme.container['scrollbar-track-background-color']};
  }

  &::-webkit-scrollbar-thumb,
  & *::-webkit-scrollbar-thumb {
    background-color: ${props =>
      props.theme.container['scrollbar-thumb-background-color']};
    border-radius: 0.4rem;
  }

  &::-webkit-scrollbar-thumb:hover,
  & *::-webkit-scrollbar-thumb:hover {
    background-color: ${props =>
      props.theme.container['scrollbar-thumb-hover-background-color']};
  }

  &,
  & * {
    scrollbar-color: ${props =>
      props.theme.container['scrollbar-thumb-background-color']};
  }

  @media (max-width: 600px), (max-height: 650px) {
    padding-top: 5.6rem;
    left: 6rem;
  }
`;

export const BrandProfile = styled.div`
  border-top: solid 0.1rem #ededed;
  display: flex;
  align-items: center;
  height: 6rem;
  cursor: pointer;

  @media (max-width: 600px), (max-height: 650px) {
    height: 5.6rem;
  }

  &:hover {
    background: #f7f8fa;
    border-top: solid 0.1rem #f7f8fa;
  }

  &:hover + & {
    border-top: solid 0.1rem #f7f8fa;
  }
`;

export const BrandProfileCta = styled.div`
  height: 6rem;
  display: flex;
  align-items: center;

  @media (max-width: 600px), (max-height: 650px) {
    height: 5.6rem;
  }

  a {
    color: #fff;
    background: #5870f6;
    height: 4rem;
    border-radius: 6px;
    flex-grow: 1;
    text-align: center;
    margin: 0 1rem;
    display: flex;
    justify-content: center;
    font-size: 1.4rem;
    font-weight: 500;
    align-items: center;
    line-height: 1.4rem;
    text-decoration: none;
    cursor: pointer;

    @media (max-width: 430px) {
      font-size: 1.2rem;
    }

    &:hover {
      opacity: 0.9;
    }
  }
`;

export const TopBarRight = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
`;

export const ToggleNotifications = styled.div`
  height: 4rem;
  width: 4rem;
  min-width: 4rem;
  border-radius: 50%;
  margin: 0 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: #f7f8fa;

    ${NotificationDot} {
      border: solid 0.2rem #f7f8fa;
    }
  }

  &:active {
    background: #eff1f6;

    ${NotificationDot} {
      border: solid 0.2rem #eff1f6;
    }
  }

  ${NotificationDot} {
    right: 8.9rem;
    top: 2rem;
  }

  svg {
    height: 2rem;
    width: 2rem;
    fill: #676767;
  }
`;

export const UserAvatar = styled.div`
  height: 6rem;
  width: 7.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: #f7f8fa;
  }

  &:active {
    background: #eff1f6;
  }

  @media (max-width: 600px), (max-height: 650px) {
    height: 5.6rem;
  }

  img {
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    min-width: 3.2rem;
    background-size: cover;
    object-fit: cover;

    @media (max-width: 430px) {
      height: 3.2rem;
      width: 3.2rem;
      min-width: 3.2rem;
    }
  }
`;

export const SidebarMenu = styled.div<Props>`
  width: 35rem;
  height: auto;
  opacity: ${({ active }) => (active ? '1' : '0')};
  position: absolute;
  top: 5.5rem;
  right: 1rem;
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  z-index: 999999999999;
  background-color: #ffffff;
  transition: all 0.08s;
  border-radius: 0.6rem;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12),
    0px 3px 5px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  padding: 0.6rem 0.6rem 1.2rem 0.6rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-width: 85vw;

  @media (max-width: 600px), (max-height: 650px) {
    height: calc(100vh - 12rem);
    height: calc(100svh - 12rem);
  }
`;

export const SidebarTop = styled.div<Props>`
  display: ${({ showNavBar }) => (showNavBar ? 'flex' : 'none')};
  height: 16.7rem;
  flex-shrink: 0;
  margin: 0.6rem 0.6rem 0 0.6rem;

  @media (max-width: 600px), (max-height: 650px) {
    height: 14.3rem;
  }
`;

export const UserProfileContainer = styled.div`
  display: flex;
  padding: 1.2rem;
  align-items: center;
  border-radius: 0.6rem;
  cursor: pointer;

  &:hover {
    background: #f4f4f480;
  }

  &:active {
    background: #f4f4f4;
  }
`;

export const UserProfileAvatarImg = styled.img`
  height: 7.6rem;
  width: 7.6rem;
  border-radius: 50%;
  min-width: 7.6rem;
  background-size: cover;

  @media (max-width: 600px), (max-height: 650px) {
    height: 5.2rem;
    width: 5.2rem;
    min-width: 5.2rem;
  }
`;

export const UserProfileContent = styled.div`
  margin-left: 2.4rem;
  width: calc(100% - 10rem);

  @media (max-width: 600px), (max-height: 650px) {
    width: calc(100% - 7.6rem);
  }
`;

export const UserProfileName = styled.div`
  color: #292929;
  font-size: 1.8rem;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const UserProfileLinkContainer = styled.div`
  display: flex;
  margin-top: 0.6rem;
  align-items: center;

  svg {
    height: 1.2rem;
    width: 1.2rem;
    fill: #676767;
    transform: rotate(-90deg);
    margin-left: 0.4rem;
  }
`;

export const UserProfileLink = styled.div`
  color: #676767;
  font-size: 1.2rem;
  font-weight: 400;
`;

export const UserPlanContainer = styled.div`
  display: flex;
  margin: 1.2rem 1.2rem 0 1.2rem;
  padding-bottom: 2.4rem;
  border-bottom: solid 1px #ededed;
`;

export const UserPlanName = styled.div`
  flex-basis: 0;
  flex-grow: 1;
`;

export const UserPlanLabel = styled.div`
  font-size: 1.1rem;
  font-weight: 400;
  color: #bfbfbf;
`;

export const UserPlanLink = styled.a`
  font-size: 1.2rem;
  font-weight: 400;
  color: #676767;
  margin-top: 0.4rem;
  display: block;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const UserPlanDate = styled.div`
  flex-basis: 0;
  flex-grow: 1;

  @media (max-width: 360px) {
    display: none;
  }
`;

export const UserPlanUpgrade = styled.div`
  display: flex;
  align-items: flex-end;
  flex-basis: 0;
  flex-grow: 1;
`;

export const SmallBtnUpgrade = styled.div`
  width: 100%;
  background: #5870f6;
  color: #fff;
  border-radius: 0.6rem;
  font-size: 1.4rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.6rem;
  cursor: pointer;

  &:active {
    opacity: 0.8;
  }
`;

export const SidebarBottom = styled.div`
  width: 100%;
  margin-top: 0.1rem;
  overflow-y: scroll;
  flex-grow: 1;
  padding: 0 0.6rem;
  box-sizing: border-box;
`;

export const SidebarContent = styled.div`
  width: 100%;
  padding: 2.4rem 0.6rem 0 1.2rem;
  box-sizing: border-box;
`;

export const SectionLabel = styled.div`
  font-weight: 400;
  font-size: 1.1rem;
  color: #bfbfbf;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
`;

export const BrandProfileSelection = styled.div`
  margin-top: 1.8rem;
  display: flex;
  overflow: auto;
  padding-bottom: 0.9rem;
`;

export const BrandProfileSideBar = styled.div<Props>`
  background: ${({ bg }) => (bg ? `url(${bg})` : '#f7f8fa')};
  background-size: cover;
  background-position: center;
  width: 5.2rem;
  height: 5.2rem;
  border-radius: 50%;
  margin-right: 1.2rem;
  border: ${({ active }) =>
    active ? '2px solid #5870F6' : '2px solid #F7F8FA'};
  min-width: 5.2rem;

  ${({ active }) =>
    !active &&
    css`
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }

      &:active {
        opacity: 0.7;
      }
    `}
`;

export const BrandProfileAddNew = styled(BrandProfileSideBar)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0;
  border: 2px solid #f7f8fa;

  svg {
    fill: #adbcd2;
    width: 1.6rem;
    height: 1.6rem;
  }
`;

export const SectionMenuContainer = styled.div`
  padding: 0.9rem 0 1.8rem 0;
  border-bottom: solid 1px #ededed;
`;

export const SectionMenuItem = styled.div`
  display: flex;
  height: 4.2rem;
  width: 100%;
  align-items: center;
  border-radius: 0.6rem;
  margin-top: 0.4rem;
  position: relative;
  cursor: pointer;

  &:first-of-type {
    margin-top: 0;
  }

  &:hover {
    background: #f4f4f480;
  }

  &:active {
    background: #f4f4f4;
  }

  svg {
    height: 2.4rem;
    width: 2.4rem;
    min-width: 2.4rem;
    fill: #676767;
    margin: 0 1.8rem 0 1.6rem;
  }

  .external-link-icon {
    position: absolute;
    right: 0;
    fill: #bfbfbf;
    height: 1.8rem;
    width: 1.8rem;
    min-width: 2.4rem;
    margin: 0 1.8rem 0 1.6rem;

    @media (max-width: 360px) {
      display: none;
    }
  }
`;

export const SectionMenuItemText = styled.div`
  color: #676767;
  font-weight: 400;
  font-size: 1.4rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 1.6rem;
`;

export const UserOptionsContainer = styled.div`
  padding-top: 2.4rem;

  ${SectionMenuContainer} {
    padding: 1.8rem 0;
  }
`;

export const UserMenuNotification = styled.div`
  margin-left: auto;
  align-items: center;
  display: flex;
  margin-right: 1.6rem;
  background: #feb038;
  font-weight: 500;
  border-radius: 1.1rem;
  padding: 0.4rem 1rem;
  font-size: 1.4rem;
  color: #fff;
  height: 2.2rem;
  box-sizing: border-box;
`;

export const LegalInformationContainer = styled.div`
  padding-top: 2.4rem;

  ${SectionMenuContainer} {
    border-bottom: 0;
    padding: 1.8rem 0;
  }
`;

export const SectionMenuItemHelpMobile = styled(SectionMenuItem)`
  display: none;

  @media (max-width: 600px), (max-height: 650px) {
    display: flex;
  }
`;

export const SidebarNotifications = styled.div<Props>`
  right: 0;
  top: 6rem;
  bottom: 0;
  width: 39rem;
  position: fixed;
  z-index: 800;
  background: #fff;
  display: flex;
  flex-direction: column;
  transition: all 0.2s;
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  opacity: ${({ active }) => (active ? '1' : '0')};
  transform: ${({ active }) =>
    active ? 'translateX(0)' : 'translateX(39rem)'};
  border-left: 1px solid #e0e5ea;
  max-width: 85vw;

  @media (max-width: 600px), (max-height: 650px) {
    top: 5.6rem;
  }
`;

export const NotificationsTop = styled.div`
  height: 5.6rem;
  display: flex;
  align-items: center;
`;

export const NotificationsFiltersContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  max-width: calc(100% - 6.4rem);
  margin: 1.2rem;
  overflow: hidden;
`;

export const SmallBtnNotifications = styled.div<Props>`
  height: 2.8rem;
  padding: 0 1rem;
  border-radius: 0.6rem;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: #676767;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  ${({ active }) =>
    active &&
    css`
      background: #f4f4f4;
    `}

  &:not(:first-of-type) {
    margin-left: 1.7rem;
  }

  &:not(:first-of-type)::before {
    content: '';
    width: 0.1rem;
    height: 2.2rem;
    background: #dbdbdb;
    position: absolute;
    left: -0.9rem;
    opacity: 0.5;
  }

  &:hover {
    background: #f4f4f4a8;
  }

  &:active {
    background: #f4f4f4;
  }
`;

export const NotificationsCircleBtn = styled.div`
  height: 4rem;
  width: 4rem;
  min-width: 4rem;
  border-radius: 50%;
  margin: 0 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-shrink: 0;
  cursor: pointer;

  &:hover {
    background: #f7f8fa;
  }

  &:active {
    background: #eff1f6;
  }

  svg {
    height: 2rem;
    width: 2rem;
    fill: #676767;
  }
`;

export const NotificationsContent = styled.div`
  flex-grow: 1;
  overflow-y: overlay;
  margin-right: 0.6rem;
  overflow-x: hidden;
`;

export const NotificationCardContent = styled.div`
  flex-grow: 1;
  margin: 1.8rem 0;

  @media (max-width: 430px) {
    margin: 1.2rem 0;
  }
`;

export const NotificationActions = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  opacity: 0;
  visibility: hidden;

  @media (max-width: 430px) {
    flex-direction: column;
  }
`;

export const NotificationCard = styled.div`
  background: #f4f4f48f;
  border-radius: 0.6rem;
  width: calc(100% - 2.4rem);
  margin: 1.2rem;
  margin-right: 0.6rem;
  position: relative;

  &:first-of-type {
    margin-top: 0;
  }

  &:hover {
    background: #fff;

    ${NotificationCardContent} {
      opacity: 0.1;
    }

    ${NotificationActions} {
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const NotificationCardContainer = styled.div`
  display: flex;
`;

export const NotificationCardAvatar = styled.div`
  height: 4rem;
  width: 4rem;
  margin: 1.8rem;
  background: #fff;
  border-radius: 50%;
  background-size: cover;
  min-width: 4rem;

  @media (max-width: 430px) {
    height: 3.2rem;
    width: 3.2rem;
    margin: 1.2rem;
    min-width: 3.2rem;
  }
`;

export const NotificationCardContentTitle = styled.div`
  color: #292929;
  font-size: 1.4rem;
  font-weight: 700;
  margin-right: 3.2rem;
`;

export const NotificationCardContentText = styled.div`
  color: #676767;
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 400;
  margin: 0.8rem 0 1.2rem 0;
  margin-right: 2.4rem;
`;

export const NotificationCardContentDate = styled.div`
  color: #8492a6;
  font-size: 1.1rem;
  font-weight: 400;
`;

export const NotificationCardContentDot = styled(NotificationDot)`
  right: 1.2rem;
  top: 1.2rem;
  border: none;
  width: 0.5rem;
  height: 0.5rem;
`;

export const NotificationActionsBtnCta = styled.div`
  background: #292929;
  width: 16rem;
  margin: 0.4rem;
  height: 4rem;
  border-radius: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.4rem;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  @media (max-width: 430px) {
    font-size: 1.2rem;
  }
`;

export const NotificationActionsBtnSecondary = styled(
  NotificationActionsBtnCta,
)`
  background: #f4f4f4;
  color: #676767;
  line-height: 1.6rem;

  &:hover {
    background: #ececed;
  }
`;

export const NotificationsEmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10vh;

  svg {
    width: 10.6rem;
    height: 10.6rem;
    fill: #bfbfbf;
  }
`;

export const NotificationsEmptyStateTitle = styled.div`
  color: #292929;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.8rem;
  margin-top: 2.4rem;
  padding: 0 2.4rem;
  text-align: center;
`;

export const NotificationsEmptyStateText = styled.div`
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: #676767;
  margin-top: 1.2rem;
  padding: 0 2.4rem;
  text-align: center;
`;

export const NotificationsBottom = styled.div`
  height: 5.6rem;
  background: #fff;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SmallBtnAllNotifications = styled.div<Props>`
  height: 2.8rem;
  padding: 0 1rem;
  margin-right: 1.2rem;
  border-radius: 0.6rem;
  display: flex;
  font-size: 1.2rem;
  color: #676767;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: #f4f4f4a8;
  }

  &:active {
    background: #f4f4f4;
  }

  svg {
    fill: #676767a8;
    transform: rotate(-90deg);
    height: 1.4rem;
    width: 1.4rem;
    margin-left: 0.8rem;
  }
`;

export const MobileNav = styled.div<Props>`
  display: none;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  height: 5.6rem;
  z-index: 8;
  background: #fff;
  border-top: 1px solid #e0e5ea;
  justify-content: center;
  align-items: start;

  @media (max-width: 600px), (max-height: 650px) {
    display: ${({ showNavBar }) => (showNavBar ? 'flex' : 'none')};
    transition: all 0.3s ease;

    ${({ active }) =>
      active &&
      css`
        height: 25rem;
        max-height: calc(100svh - 6.6rem);
        flex-direction: column;
        align-items: center;

        ${WorkspaceSubmenu} {
          width: 100%;
          flex-grow: 1;
          box-sizing: border-box;
          max-height: calc(100% - 8.2rem);
          overflow: overlay;
          padding-left: 1.6rem;
          margin-top: 1rem;
          padding-top: 0;
          padding-right: 1.3rem;
          margin-right: 1.3rem;
          margin-bottom: 1.6rem;
        }

        ${SubmenuItem} {
          height: 4.4rem;
          border-radius: 0.6rem;
          margin-bottom: 0.8rem;
          color: #676767;
          display: flex;
          font-size: 1.6rem;
          align-items: center;
          padding-left: 1.8rem;
          padding-right: 3.6rem;
          position: relative;

          &:hover {
            background: #f7f8fa;
          }

          &:active {
            background: #eff1f6;
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        ${MobileNavArrowContainer} {
          position: absolute;
          right: 1.2rem;
          height: 1.8rem;
          width: 1.8rem;

          svg {
            fill: #8492a6;
            opacity: 0.5;
            height: 1.8rem;
            width: 1.8rem;
            transform: rotate(-90deg);
          }
        }
      `}
  }
`;

export const MobileWebappNav = styled.nav<Props>`
  display: ${({ showNavBar }) => (showNavBar ? 'flex' : 'none')};
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 1rem);
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  a {
    margin: 0.4rem;
    min-width: 4.4rem;
    height: 4.8rem;
    display: flex;
    align-items: center;
    flex-grow: 1;
    cursor: pointer;
  }
`;

export const MobileNavItem = styled.div<Props>`
  height: 4.4rem;
  width: 100%;
  border-radius: 0.6rem;
  flex-shrink: 1;
  position: relative;

  ${({ active }) =>
    active &&
    css`
      background-color: #5870f6 !important;

      ${MobileNotificationDot} {
        border: solid 0.2rem #5870f6 !important;
      }

      ${MobileNavIcon} {
        svg {
          fill: #fff !important;
        }
      }
    `}

  &:hover {
    background: #f7f8fa;

    ${({ active }) =>
      !active &&
      css`
        ${MobileNotificationDot} {
          border: solid 0.2rem #f7f8fa;
        }
      `}
  }

  &:active {
    background: #eff1f6;

    ${({ active }) =>
      !active &&
      css`
        ${MobileNotificationDot} {
          border: solid 0.2rem #eff1f6;
        }
      `}
  }
`;

export const MobileNotificationDot = styled.div<Props>`
  position: absolute;
  left: 1.8rem;
  right: 0;
  top: 0.8rem;
  margin-left: auto;
  margin-right: auto;
  width: 0.9rem;
  height: 0.9rem;
  background: #f5a623;
  z-index: 9999;
  border-radius: 50%;
  border: solid 0.2rem #fff;
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
`;

export const MobileNavIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.4rem;

  svg {
    height: 2.4rem;
    width: 2.4rem;
    fill: #8492a6;
    vertical-align: middle;
  }
`;

export const SubmenuWorkspace = styled.div<Props>`
  height: 4.4rem;
  width: 100%;
  border-radius: 0.6rem;
  flex-shrink: 1;
  position: relative;

  ${({ selected }) =>
    selected &&
    css`
      background-color: #5870f6 !important;

      ${MobileNotificationDot} {
        border: solid 0.2rem #5870f6 !important;
      }

      ${MobileNavIcon} {
        svg {
          fill: #fff !important;
        }
      }
    `}

  &:hover {
    background: #f7f8fa;

    ${({ active }) =>
      !active &&
      css`
        ${MobileNotificationDot} {
          border: solid 0.2rem #f7f8fa;
        }
      `}
  }

  &:active {
    background: #eff1f6;

    ${({ active }) =>
      !active &&
      css`
        ${MobileNotificationDot} {
          border: solid 0.2rem #eff1f6;
        }
      `}
  }
`;

export const WorkspaceSubmenu = styled.div``;

export const SubmenuItem = styled.div`
  cursor: pointer;

  @media (max-width: 600px), (max-height: 650px) {
    display: none;
  }
`;

export const MobileNavArrowContainer = styled.div``;

export const MainContainer = styled.div<MainContainerProps>`
  background-color: rgb(244, 247, 253);
  padding: ${({ showNavbar, showSideBar }) =>
    showNavbar ? `6.08rem 0 0 ${showSideBar ? '6.08rem' : '0'}` : 'unset'};
  overflow: hidden;
  width: 100vw;
  height: 100vh;

  ${props => {
    if (props.showNavbar) {
      return css`
        @media (max-width: 600px) {
          padding-top: 5.7rem;
          padding-left: 0;
        }
      `;
    }
  }}

  @media (max-height: 650px) {
    padding-left: 0;
  }
`;

export const MainContainerInner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: all 0.08s ease;
  overflow-x: auto;
  overflow-y: scrollbar;
  box-sizing: content-box;
  scrollbar-gutter: stable;
  background-color: rgb(244, 247, 253);

  &::-webkit-scrollbar,
  & *::-webkit-scrollbar {
    width: 0.7rem;
    height: 0.7rem;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track,
  & *::-webkit-scrollbar-track {
    border-radius: 0.4rem;
    background-color: ${props =>
      props.theme.container['scrollbar-track-background-color']};
  }

  &::-webkit-scrollbar-thumb,
  & *::-webkit-scrollbar-thumb {
    background-color: ${props =>
      props.theme.container['scrollbar-thumb-background-color']};
    border-radius: 0.4rem;
  }

  &::-webkit-scrollbar-thumb:hover,
  & *::-webkit-scrollbar-thumb:hover {
    background-color: ${props =>
      props.theme.container['scrollbar-thumb-hover-background-color']};
  }

  &,
  & * {
    scrollbar-color: ${props =>
      props.theme.container['scrollbar-thumb-background-color']};
  }
`;

export const SocialAccountsInnerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 16rem;
  overflow-y: overlay;

  @media (max-width: 750px) {
    justify-content: center;
  }
`;

export const SocialAccountContainer = styled.div`
  height: 7rem;
  width: 23rem;
  display: flex;
  margin-right: 2rem;
  border: 1px solid rgb(238, 238, 238);
  border-radius: 1.5rem;
  padding: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;

  &:hover {
    border-color: rgb(180, 180, 180);
  }

  @media (max-width: 750px) {
    width: 80%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const SocialAccountLeftContainer = styled.div`
  height: 6rem;
  width: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const SocialAccountImage = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 50%;
`;

export const SocialAccountNetworkIconContainer = styled.div`
  padding: 0.2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  position: absolute;
  top: 60%;
  left: 60%;
  background-color: white;
`;

export const SocialAccountRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  padding-left: 0.8rem;
`;

export const SocialAccountUsername = styled.div`
  padding-bottom: 0.9rem;
  color: ${props => props.theme.text['color-26']};
  font-size: 1.5rem;
  font-weight: 600;
  width: 13rem;
  overflow-x: overlay;
`;

export const SocialAccountNetworkName = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1rem;
  color: ${props => props.theme.text['color-27']};
  position: relative;
  display: inline-block;
  margin-top: 0.9rem;
`;

export const SocialAccountsEmpty = styled.div`
  color: ${props => props.theme.text['color-26']};
  font-size: 1.6rem;
  font-weight: 400;
  padding-top: 2rem;
  padding-left: 2rem;
  margin-bottom: 1rem;
`;

export const SocialAccountsEmptySecondaryMessage = styled.div`
  display: flex;
  padding-top: 0.5rem;
  white-space: pre;
`;

export const SocialAccountsEmptyLink = styled.div`
  color: #5870f6;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
