import styled from 'styled-components';

export const ToolBarButton = styled.div<{ disable?: boolean }>`
  width: 4rem;
  height: 4rem;
  display: flex;
  min-width: 4rem;
  margin: 0 1.2rem;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: ${({ disable }) => (disable ? 'not-allowed' : 'pointer')};

  svg {
    width: 2rem;
    height: 2rem;
    fill: #adbcd2;
    vertical-align: middle;
  }

  &:hover {
    background: ${({ disable }) => (disable ? 'undefined' : '#f7f8fa')};
  }

  &:active {
    background: ${({ disable }) => (disable ? 'undefined' : '#eff1f6')};
  }
`;
