/* eslint-disable import/extensions */
/* eslint-disable default-param-last */
import { useMutation, useQuery } from 'react-query';

import SkorrApi from '../../Services/SkorrApi/new';
import { ListTypes } from '../../Services/SkorrApi/new/User/types';
import { IErrorObject } from '../../Services/Utils/types';
import {
  isAuthenticated,
  logApiRequestError,
} from '../../Services/Utils/Utils';

const { user } = SkorrApi;

export type UseGetListsByPartnerAndBusinessProfileInput = {
  enabled: boolean;
  type?: ListTypes;
  memberCount?: boolean;
  page?: number;
  limit?: number;
};

export const useGetListsByPartnerAndBusinessProfile = ({
  enabled,
  type,
  memberCount,
  page,
  limit,
}: UseGetListsByPartnerAndBusinessProfileInput) => {
  return useQuery(
    [
      'get-lists-by-partner-and-business-profile',
      isAuthenticated()?.partnerId,
      isAuthenticated()?.businessProfileId,
    ],
    async () => {
      try {
        const data = await user.getListsByPartnerAndBusinessProfile({
          type: type ?? ListTypes.PUBLIC,
          memberCount: memberCount ?? false,
          page: page ?? 0,
          limit: limit ?? 50,
        });

        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      staleTime: 0,
      retry: false,
    },
  );
};

export const useDeleteListById = () => {
  return useMutation(
    async (listId: string) => {
      try {
        await user.deleteListById(listId);
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
    },
  );
};
