/* eslint-disable no-plusplus */
import Button from 'components/shared/Button/Button';
import ComboBox from 'components/shared/ComboBox/ComboBox';
import GenericModal from 'components/shared/GenericModal/GenericModal';
import {
  useAddUsersToList,
  useGetListsByPartnerAndBusinessProfile,
} from 'features/Discover/api';
import { useDiscoverContext } from 'features/Discover/hooks/contexts/use-discover-context';
import { useCallback, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ListTypes } from 'Services/SkorrApi/new/User/types';

const DiscoverSaveUsersList = () => {
  const navigate = useNavigate();

  const [success, setSuccess] = useState<boolean | undefined>();
  const [listsAvailable, setListsAvailable] = useState<any[]>([]);
  const [listSelectedId, setListSelectedId] = useState<string | undefined>();

  const { showSaveList, selectedUserIds, handleCloseSaveList } =
    useDiscoverContext();

  const { data: lists, isSuccess: isSuccessGetLists } =
    useGetListsByPartnerAndBusinessProfile({
      type: ListTypes.PUBLIC,
      memberCount: true,
    });

  useEffect(() => {
    const availableLists = [];

    if (lists) {
      for (let i = 0; i < lists.length; i++) {
        availableLists.push({
          label: lists[i].name,
          value: lists[i].id,
          data: lists[i],
        });
      }
      setListsAvailable(availableLists);
    }
  }, [lists, isSuccessGetLists]);

  const navigateToList = useCallback(() => {
    navigate(`/list/members/${listSelectedId}`);
    setSuccess(undefined);
    setListSelectedId(undefined);
  }, [navigate, listSelectedId]);

  const closeButtonClicked = useCallback(() => {
    setSuccess(undefined);
    setListSelectedId(undefined);
    handleCloseSaveList();
  }, []);

  const {
    mutateAsync: addUsersToList,
    isError: isErrorAddUsersToList,
    isSuccess: isSuccessAddUsersToList,
  } = useAddUsersToList();

  const handleAddUsersToList = useCallback(() => {
    if (
      success === undefined &&
      listSelectedId !== undefined &&
      selectedUserIds !== undefined
    ) {
      addUsersToList({
        listId: listSelectedId,
        publicUserId: selectedUserIds,
      });
    }
    if (success) {
      navigateToList();
    }
    if (!success) {
      setSuccess(undefined);
    }
  }, [
    success,
    addUsersToList,
    listSelectedId,
    selectedUserIds,
    navigateToList,
    closeButtonClicked,
  ]);

  const primaryButtonText = useCallback(() => {
    if (success === undefined && listsAvailable.length > 0) {
      return 'Submit';
    }
    if (success === undefined && listsAvailable.length === 0) {
      return undefined;
    }
    if (success) {
      return 'Navigate to list';
    }
    if (!success) {
      return 'Get back';
    }
    return undefined;
  }, [success, listsAvailable]);

  useEffect(() => {
    if (isErrorAddUsersToList) {
      setSuccess(false);
    }
    if (isSuccessAddUsersToList) {
      setSuccess(true);
    }
  }, [isErrorAddUsersToList, isSuccessAddUsersToList]);

  return (
    <GenericModal
      show={showSaveList}
      title={'Save in a list'}
      subtitle={'You can add entities to an existing list'}
      body={
        <div
          style={{
            display: 'grid',
            height:
              listsAvailable.length > 0 && success === undefined
                ? '12rem'
                : 'auto',
          }}
        >
          {success === undefined && listsAvailable.length === 0 && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Button
                textAlign="center"
                onClick={() => window.open('/lists', '_blank')}
              >
                Navigate to create a new list
              </Button>
            </div>
          )}

          {success === undefined && listsAvailable.length > 0 && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label style={{ marginBottom: '1rem' }}>My lists</label>

              <ComboBox
                type="single"
                height="4.4rem"
                innerOptionsContainerMaxHeight="10rem"
                isClearable={true}
                isFilterable={false}
                value={listSelectedId}
                valueKey={'listSelectedId'}
                placeholder={'Select List'}
                valuesAvailable={listsAvailable}
                onChange={(obj: any) => {
                  setListSelectedId(obj.listSelectedId?.value);
                }}
              />
            </div>
          )}

          {success === true && (
            <div
              style={{
                display: 'flex',
                justifySelf: 'center',
              }}
            >
              <div
                style={{
                  textAlign: 'center',
                }}
              >
                <span
                  style={{
                    borderRadius: '50%',
                    display: 'inline-block',
                    backgroundColor: '#35C631',
                  }}
                >
                  <i
                    style={{
                      margin: 0,
                      color: 'white',
                      fontSize: '70px',
                    }}
                    className="lar la-check-circle"
                  ></i>
                </span>
                <h1>Success</h1>
                <p>
                  The list has been successfully edited! <br></br> Check on your
                  lists
                </p>
              </div>
            </div>
          )}

          {success === false && (
            <div
              style={{
                display: 'flex',
                justifySelf: 'center',
              }}
            >
              <div
                style={{
                  textAlign: 'center',
                }}
              >
                <span
                  style={{
                    borderRadius: '50%',
                    display: 'inline-block',
                    backgroundColor: '#D13515',
                  }}
                >
                  <i
                    style={{
                      margin: 0,
                      color: 'white',
                      fontSize: '70px',
                    }}
                    className="lar la-times-circle"
                  ></i>
                </span>
                <h1>Something went wrong</h1>
                <p>
                  It was not possible to update the list, try again. Please
                  contact our <strong>support team</strong> if your difficulties
                  persist
                </p>
              </div>
            </div>
          )}
        </div>
      }
      modalWidth={'40%'}
      isCancelable={true}
      primaryButtonLeftMargin="1rem"
      primaryButtonText={primaryButtonText()}
      primaryButtonAction={handleAddUsersToList}
      primaryButtonDisabled={
        success === undefined && listSelectedId === undefined
      }
      cancelAction={closeButtonClicked}
    />
  );
};

export default withTranslation()(DiscoverSaveUsersList);
