import styled from 'styled-components';

export const ToolBar = styled.div`
  display: flex;
  height: 5.4rem;
  background: #fff;
  align-items: center;
  box-sizing: content-box;
  transition: all 0.08s ease;
  justify-content: space-between;
  border-bottom: 1px solid #e0e5ea;
`;

export const ToolBarContainer = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
`;

export const ToolBarTitle = styled.h2`
  color: #292929;
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.3;
  margin-left: 2.4rem;
`;

export const ToolBarText1 = styled.div`
  display: flex;
  color: #676767;
  white-space: pre;
  font-weight: 400;
  line-height: 1.8;
  font-size: 1.2rem;
`;
export const ToolBarText2 = styled.div`
  display: flex;
  color: #292929;
  white-space: pre;
  font-weight: 500;
  line-height: 1.8;
  font-size: 1.2rem;
`;

export const ToolBarDivider = styled.div`
  opacity: 50%;
  width: 0.1rem;
  height: 3.4rem;
  margin: 0 2rem;
  background-color: #dbdbdb;
`;
