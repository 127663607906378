/* eslint-disable eqeqeq */
/* eslint-disable import/extensions */
import { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import SVG from './Styles';

interface IYoutubeIconProps {
  containerWidth?: string;
  containerHeight?: string;
  containerPaddingTop?: string;
  containerPaddingBottom?: string;
  containerPaddingLeft?: string;
  containerPaddingRight?: string;
  containerMarginTop?: string;
  containerMarginBottom?: string;
  containerMarginLeft?: string;
  containerMarginRight?: string;
  useCase?: 'additionalDataStep';
  disabledColored?: boolean;
  noAssignColor?: boolean;
  svgWidth?: string;
  svgHeight?: string;
  rotate?: string;
  cursor?: string;
}

interface IContainerProps {
  width?: string;
  height?: string;
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
}

const Container = styled.div<IContainerProps>`
  border-width: 0;
  outline: 0;
  width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '0')};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : '0')};
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '0')};
  padding-right: ${props => (props.paddingRight ? props.paddingRight : '0')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '0')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0')};
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const YoutubeIcon = (props: IYoutubeIconProps) => {
  const themeGlobal = useTheme();
  const [svgColor, setSvgColor] = useState<string>(() => {
    if (props.noAssignColor) {
      return undefined;
    }
    if (props.disabledColored) {
      return (themeGlobal as any)['social-networks'].youtube.disabled.color;
    }
    if (props.useCase == 'additionalDataStep') {
      return (themeGlobal as any)['additional-data-step']['youtube-icon-color'];
    }

    return (themeGlobal as any)['social-networks'].youtube.color;
  });

  useEffect(() => {
    if (props.noAssignColor != true) {
      if (props.disabledColored) {
        setSvgColor(
          (themeGlobal as any)['social-networks'].youtube.disabled.color,
        );
      } else if (props.useCase == 'additionalDataStep') {
        setSvgColor(
          (themeGlobal as any)['additional-data-step']['youtube-icon-color'],
        );
      } else {
        setSvgColor((themeGlobal as any)['social-networks'].youtube.color);
      }
    }
  }, [(themeGlobal as any).darkMode]);

  return (
    <Container
      width={props.containerWidth}
      height={props.containerHeight}
      paddingTop={props.containerPaddingTop}
      paddingBottom={props.containerPaddingBottom}
      paddingLeft={props.containerPaddingLeft}
      paddingRight={props.containerPaddingRight}
      marginTop={props.containerMarginTop}
      marginBottom={props.containerMarginBottom}
      marginLeft={props.containerMarginLeft}
      marginRight={props.containerMarginRight}
    >
      <SVG
        viewBox="0 0 32 32"
        width={props.svgWidth ? props.svgWidth : '100%'}
        height={props.svgHeight ? props.svgHeight : '100%'}
        rotateNotNative={props.rotate}
        cursor={props.cursor}
      >
        <path
          fill={svgColor}
          d="M27.065 6.655c-2.208-0.591-11.065-0.591-11.065-0.591s-8.852 0-11.064 0.592c-1.217 0.324-2.176 1.287-2.505 2.511-0.591 2.213-0.591 6.833-0.591 6.833s0 4.62 0.589 6.833c0.325 1.219 1.284 2.181 2.505 2.511 2.213 0.592 11.065 0.592 11.065 0.592s8.857 0 11.064-0.592c1.217-0.325 2.176-1.287 2.505-2.511 0.591-2.213 0.591-6.833 0.591-6.833s0-4.62-0.589-6.835c-0.325-1.219-1.284-2.181-2.505-2.511zM20.528 16l-7.357 4.259v-8.517l7.357 4.259z"
        />
      </SVG>
    </Container>
  );
};

export default YoutubeIcon;
