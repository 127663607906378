/* eslint-disable no-use-before-define */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable default-case */
/* eslint-disable import/extensions */
import { useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import * as S from './Styles';
import {
  useDeleteListById,
  useGetListsByPartnerAndBusinessProfile,
} from './RQCustomHooks';
import Loader from '../../components/shared/LoaderFixed/LoaderFixed';
import GenericModal from '../../components/shared/GenericModal/GenericModal';
import { ListsProps } from './types';
import BackArrowIcon from '../../assets/iconComponents/BackArrowIcon';
import { getShortenedMonthResourceKey } from '../../Services/Utils/dateUtils';
import EmptyInfo from '../../components/shared/EmptyInfo/EmptyInfo';
import ExclamationPointIcon from '../../assets/iconComponents/ExclamationPointIcon';
import GearIconV1 from '../../assets/iconComponents/GearIconV1';
import CheckMarkIcon from '../../assets/iconComponents/CheckMarkIcon';
import TrashCanIconV1 from '../../assets/iconComponents/TrashCanIconV1';
import { ListTypes } from '../../Services/SkorrApi/new/User/types';
import NotifyCustom from '../../components/shared/NotifyCustom/NotifyCustom';
import InputSpinnerIcon from '../../assets/iconComponents/InputSpinnerIcon/InputSpinnerIcon';
import UsersIconV1 from '../../assets/iconComponents/UsersIconV1';
import Button from '../../components/shared/Button/Button';
import { useIsOperatorOrPartnerAdmin } from '../SignIn/RQCustomHooks';

const PublicUserLists = (props: ListsProps) => {
  const navigate = useNavigate();
  const [isGetListsEnabled, setIsGetListsEnabled] = useState(true);
  const [isOperatorOrPartnerAdminEnabled, setIsOperatorOrPartnerAdminEnabled] =
    useState(true);
  const [hasError, setHasError] = useState(false);
  const [msgError, setMsgError] = useState('');
  const {
    data: lists,
    isFetching: isFetchingGetLists,
    isSuccess: isSuccessGetLists,
    isError: isErrorGetLists,
    refetch: refetchGetLists,
  } = useGetListsByPartnerAndBusinessProfile({
    enabled: isGetListsEnabled,
    type: ListTypes.PUBLIC,
    memberCount: true,
  });
  const showNoListsWarning = useMemo(() => {
    if (isSuccessGetLists && !isFetchingGetLists && !lists.length) return true;
    return false;
  }, [isSuccessGetLists, isFetchingGetLists, lists?.length]);
  const displayLists = useMemo(() => {
    if (isSuccessGetLists && !isFetchingGetLists && lists.length) return true;
    return false;
  }, [isSuccessGetLists, isFetchingGetLists, lists?.length]);
  const {
    isLoading: isLoadingDeleteList,
    isSuccess: isSuccessDeleteList,
    isError: isErrorDeleteList,
    mutate: deleteList,
  } = useDeleteListById();
  const {
    data: isOperatorOrPartnerAdmin,
    isFetching: isFetchingIsOperatorOrPartnerAdmin,
    isSuccess: isSuccessIsOperatorOrPartnerAdmin,
    isError: isErrorIsOperatorOrPartnerAdmin,
  } = useIsOperatorOrPartnerAdmin(isOperatorOrPartnerAdminEnabled);
  const showFooter = useMemo(() => {
    if (
      isSuccessIsOperatorOrPartnerAdmin &&
      !isFetchingIsOperatorOrPartnerAdmin &&
      isOperatorOrPartnerAdmin
    ) {
      return true;
    }
    return false;
  }, [
    isSuccessIsOperatorOrPartnerAdmin,
    isFetchingIsOperatorOrPartnerAdmin,
    isOperatorOrPartnerAdmin,
  ]);

  const [
    showSuccessfulActionNotification,
    setShowSuccessfulActionNotification,
  ] = useState<boolean>(false);
  const [
    successfulActionNotificationMessage,
    setSuccessfulActionNotificationMessage,
  ] = useState('');
  const [
    loadingActionNotificationMessage,
    setLoadingActionNotificationMessage,
  ] = useState('');
  const themeGlobal = useTheme();

  useEffect(() => {
    if (isSuccessGetLists) {
      setIsGetListsEnabled(false);
    }

    if (isSuccessIsOperatorOrPartnerAdmin) {
      setIsOperatorOrPartnerAdminEnabled(false);
    }

    if (isSuccessDeleteList) {
      setSuccessfulActionNotificationMessage(props.t('lists.listDeleted'));
      setShowSuccessfulActionNotification(true);
      setTimeout(() => {
        setShowSuccessfulActionNotification(false);
      }, 2000);
      refetchGetLists();
    }
  }, [
    isSuccessGetLists,
    isSuccessIsOperatorOrPartnerAdmin,
    isSuccessDeleteList,
  ]);

  useEffect(() => {
    if (isErrorGetLists) {
      setIsGetListsEnabled(false);
      setHasError(true);
      setMsgError(props.t('generic.couldNotGetData'));
    }

    if (isErrorIsOperatorOrPartnerAdmin) {
      setIsOperatorOrPartnerAdminEnabled(false);
      setHasError(true);
      setMsgError(props.t('generic.couldNotGetData'));
    }

    if (isErrorDeleteList) {
      setHasError(true);
      setMsgError(props.t('lists.couldNotDeleteList'));
    }
  }, [isErrorGetLists, isErrorIsOperatorOrPartnerAdmin, isErrorDeleteList]);

  const errorModalCloseButtonClicked = () => {
    setHasError(false);
    if (isErrorGetLists) {
      setIsGetListsEnabled(true);
    }

    if (isErrorIsOperatorOrPartnerAdmin) {
      setIsOperatorOrPartnerAdminEnabled(true);
    }
  };

  const getCreatedAtLabel = (date: Date) => {
    const createdAtDate = new Date(Date.parse(date as unknown as string));

    const getCreatedAtDateLabel = `${props.t(
      getShortenedMonthResourceKey(createdAtDate),
    )} ${createdAtDate.getDate()}${`, ${createdAtDate.getFullYear()}`}`;

    return `${getCreatedAtDateLabel}`;
  };

  return (
    <S.Container>
      <Loader
        show={isFetchingGetLists || isFetchingIsOperatorOrPartnerAdmin}
        message={props.t('generic.gettingData')}
      />

      <S.InnerContainer>
        <S.Card>
          <S.HeaderOuterContainer>
            <S.HeaderContainer>
              <S.HeaderBackArrowContainer
                onClick={() => {
                  navigate(-1);
                }}
              >
                <BackArrowIcon width={'4rem'} height={'4rem'} />
              </S.HeaderBackArrowContainer>
              <S.HeaderTitleContainer>
                {props.t('generic.lists')}
              </S.HeaderTitleContainer>
              <S.HiddenHeaderElement />
            </S.HeaderContainer>
          </S.HeaderOuterContainer>

          <S.CardInnerContainer footerVisible={showFooter}>
            {displayLists && (
              <S.ListsContainer>
                {lists!.map(list => (
                  <S.ListCard>
                    <S.ListIconContainer>
                      <i className={list.icon} />
                    </S.ListIconContainer>

                    <S.ListNameContainer>
                      <S.ListItemContainer>
                        <S.ListItemTitle>
                          {props.t('generic.name')}
                        </S.ListItemTitle>
                        <S.ListItemLegend>{list.name}</S.ListItemLegend>
                      </S.ListItemContainer>
                    </S.ListNameContainer>

                    <S.ListDescriptionContainer>
                      <S.ListItemContainer>
                        <S.ListItemTitle>
                          {props.t('generic.description')}
                        </S.ListItemTitle>
                        <S.ListItemLegend>{list.description}</S.ListItemLegend>
                      </S.ListItemContainer>
                    </S.ListDescriptionContainer>

                    <S.ListMembersCountContainer>
                      <S.ListItemContainer>
                        <S.ListItemTitle>
                          {props.t('generic.members')}
                        </S.ListItemTitle>
                        <S.ListItemLegend>{list.memberCount}</S.ListItemLegend>
                      </S.ListItemContainer>
                    </S.ListMembersCountContainer>

                    <S.ListCreatedAtContainer>
                      <S.ListItemContainer>
                        <S.ListItemTitle>
                          {props.t('generic.createdAt')}
                        </S.ListItemTitle>
                        <S.ListItemLegend>
                          {getCreatedAtLabel(list.createdAt!)}
                        </S.ListItemLegend>
                      </S.ListItemContainer>
                    </S.ListCreatedAtContainer>

                    <S.ActionsContainer>
                      <S.ListItemContainer>
                        <S.ListItemTitle>
                          {props.t('generic.actions')}
                        </S.ListItemTitle>

                        <S.ActionsInnerContainer>
                          <S.ActionOuterContainer>
                            <S.ActionInnerContainer>
                              <S.ActionIconOuterContainer
                                id={`edit-list-${list.id}`}
                              >
                                <S.ActionIconContainer
                                  onClick={() => {
                                    navigate(`/list/edit/${list.id}`);
                                  }}
                                >
                                  <GearIconV1 />
                                </S.ActionIconContainer>
                              </S.ActionIconOuterContainer>

                              <S.ActionTooltip
                                placement="top"
                                target={`edit-list-${list.id}`}
                              >
                                {props.t('generic.settings')}
                              </S.ActionTooltip>
                            </S.ActionInnerContainer>
                          </S.ActionOuterContainer>

                          <S.ActionOuterContainer>
                            <S.ActionInnerContainer>
                              <S.ActionIconOuterContainer
                                id={`view-list-members-${list.id}`}
                              >
                                <S.ActionIconContainer
                                  onClick={() => {
                                    navigate(`/list/members/${list.id}`);
                                  }}
                                >
                                  <UsersIconV1
                                    containerWidth="2.4rem"
                                    containerHeight="2.4rem"
                                  />
                                </S.ActionIconContainer>
                              </S.ActionIconOuterContainer>

                              <S.ActionTooltip
                                placement="top"
                                target={`view-list-members-${list.id}`}
                              >
                                {props.t('lists.viewMembers')}
                              </S.ActionTooltip>
                            </S.ActionInnerContainer>
                          </S.ActionOuterContainer>

                          <S.ActionOuterContainer>
                            <S.ActionInnerContainer>
                              <S.ActionIconOuterContainer
                                id={`delete-list-${list.id}`}
                              >
                                <S.ActionIconContainer
                                  onClick={() => {
                                    setLoadingActionNotificationMessage(
                                      props.t('lists.deletingList'),
                                    );
                                    deleteList(list.id);
                                  }}
                                >
                                  <TrashCanIconV1
                                    containerWidth="2.4rem"
                                    containerHeight="2.4rem"
                                  />
                                </S.ActionIconContainer>
                              </S.ActionIconOuterContainer>

                              <S.ActionTooltip
                                placement="top"
                                target={`delete-list-${list.id}`}
                              >
                                {props.t('generic.delete')}
                              </S.ActionTooltip>
                            </S.ActionInnerContainer>
                          </S.ActionOuterContainer>
                        </S.ActionsInnerContainer>
                      </S.ListItemContainer>
                    </S.ActionsContainer>
                  </S.ListCard>
                ))}
              </S.ListsContainer>
            )}

            {showNoListsWarning && (
              <S.EmptyInfoContainer>
                <EmptyInfo
                  width="auto"
                  icon={
                    <ExclamationPointIcon
                      containerWidth="4rem"
                      containerHeight="5rem"
                    />
                  }
                  header={props.t('lists.noListsAvailableInfoTitle')}
                  legend={props.t('lists.noListsAvailableInfoSubtitle')}
                />
              </S.EmptyInfoContainer>
            )}
          </S.CardInnerContainer>

          {showFooter && (
            <S.FooterContainer>
              <Button
                borderRadius={'0.6rem'}
                borderWidth={'0rem'}
                height={'4rem'}
                fontSize={'1.6rem'}
                textAlign={'center'}
                fontWeight={'500'}
                paddingRight={'1rem'}
                onClick={() => {
                  navigate('/list/create');
                }}
              >
                {props.t('generic.create')}
              </Button>
            </S.FooterContainer>
          )}
        </S.Card>
      </S.InnerContainer>

      <NotifyCustom
        location={'top'}
        show={isLoadingDeleteList}
        themeStyles={(themeGlobal as any).signin['regular-notify']}
      >
        <S.NotifyInnerContainer>
          <InputSpinnerIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
          />
          {loadingActionNotificationMessage}
        </S.NotifyInnerContainer>
      </NotifyCustom>

      <NotifyCustom
        location={'top'}
        show={showSuccessfulActionNotification}
        themeStyles={(themeGlobal as any).signin['success-notify']}
        topInitialOffset={'5rem'}
      >
        <S.NotifyInnerContainer>
          <CheckMarkIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
            useCase={'success-notify'}
          />
          {successfulActionNotificationMessage}
        </S.NotifyInnerContainer>
      </NotifyCustom>

      <GenericModal
        show={hasError}
        title={props.t('generic.uhoh')}
        body={msgError}
        primaryButtonText={props.t('generic.close')}
        primaryButtonAction={() => errorModalCloseButtonClicked()}
      />
    </S.Container>
  );
};

export default withTranslation()(PublicUserLists);
