/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { UncontrolledTooltip } from 'reactstrap';

import { ActionItemTooltipProps, CardInnerContainerProps } from './types';

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: start;
  padding-left: 0.8rem;
`;

export const InnerContainer = styled.div`
  width: 80%;
  border-radius: 2.4rem;
  height: calc(100vh - 10.5rem);
  background-color: #ffffff;
  box-shadow: ${props =>
    `0rem 1.6rem 2.4rem rgba(0, 0, 0, 0.14), 0rem 0.6rem 3rem rgba(0, 0, 0, 0.12), 0rem 0.8rem 1rem rgba(0, 0, 0, 0.2)`};
  margin: 2rem 0;

  @media (max-width: 1325px) {
    width: 95%;
  }
`;

export const Card = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const HeaderOuterContainer = styled.div`
  min-height: 7.7rem;
  margin-bottom: 2rem;
  border-bottom: 0.1rem solid rgba(219, 219, 219, 0.5);
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  padding: 0 3.2rem;
  font-size: 2rem;
  font-weight: 600;
  color: #292929;

  @media (max-width: 600px) {
    padding: 0 1.6rem;
  }
`;

export const HeaderBackArrowContainer = styled.div`
  height: 4.2rem;
  width: 4.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem;
  transition: all 0.08s;
  opacity: 1;
  border-radius: 0.8rem;
  cursor: pointer;
  & path {
    fill: #000000;
  }

  &:hover {
    background-color: #f4f4f480;
  }
`;

export const HeaderTitleContainer = styled.div`
  font-size: 2rem;
  font-weight: 600;
  color: #292929;
  margin: 0 auto;
  text-align: center;
`;

export const HiddenHeaderElement = styled.div`
  height: 4rem;
  width: 4rem;
  visibility: hidden;
`;

export const ListCard = styled.div`
  padding: 0;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0.3rem 0 rgb(239 244 255 / 0%);
  transition: all 0.3s ease;
  background-color: #fff;
  margin-left: 0.8rem;
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
  flex-wrap: wrap;
  row-gap: 1rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0.1rem solid rgba(219, 219, 219, 0.5);
`;

export const CardInnerContainer = styled.div<CardInnerContainerProps>`
  padding: 0 3.2rem;
  display: flex;
  flex-direction: column;
  height: calc(100% - ${props => (props.footerVisible ? '17.5rem' : '9.7rem')});
`;

export const ListIconContainer = styled.div`
  width: 7rem;
  height: 7rem;
  font-size: 5rem;
  color: #8494a9;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1120px) {
    width: 15rem;
  }

  @media (max-width: 725px) {
    width: 50%;
    justify-content: start;
  }
`;

export const ListNameContainer = styled.div`
  width: 24rem;
  padding: 0 1rem;
  height: 7rem;
  display: flex;
  align-items: center;

  @media (max-width: 1120px) {
    width: calc(50% - 7.5rem);
  }

  @media (max-width: 725px) {
    width: 50%;
  }
`;

export const ListDescriptionContainer = styled.div`
  width: 24rem;
  padding: 0 1rem;
  height: 7rem;
  display: flex;
  align-items: center;

  @media (max-width: 1120px) {
    width: calc(50% - 7.5rem);
  }

  @media (max-width: 725px) {
    width: 50%;
  }
`;

export const ListMembersCountContainer = styled.div`
  width: 12rem;
  padding: 0 1rem;
  height: 7rem;
  display: flex;
  align-items: center;

  @media (max-width: 1120px) and (min-width: 726px) {
    width: 15rem;
    & * {
      display: flex;
      justify-content: center;
    }
  }

  @media (max-width: 725px) {
    width: 50%;
  }
`;

export const ListCreatedAtContainer = styled.div`
  width: 12rem;
  padding: 0 1rem;
  height: 7rem;
  display: flex;
  align-items: center;

  @media (max-width: 1120px) {
    width: calc(50% - 7.5rem);
  }

  @media (max-width: 725px) {
    width: 50%;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 7rem;
  width: 10rem;

  @media (max-width: 1120px) {
    width: calc(50% - 7.5rem);
  }

  @media (max-width: 725px) {
    width: 50%;
  }
`;

export const ActionsInnerContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  width: 100%;
  height: auto;
`;

export const CopyConfigurationUrlContainer = styled.div`
  width: 3rem;
  margin-right: 1rem;
`;

export const ActionOuterContainer = styled.div`
  width: auto;
  height: auto;
  margin-right: 0.5rem;
`;

export const ActiveContainer = styled.div`
  width: 6rem;
  padding: 0 1rem;
  height: 7rem;
  display: flex;
  align-items: center;

  @media (max-width: 1030px) {
    width: 30%;
  }

  @media (max-width: 820px) {
    width: 50%;
  }
`;

export const ListItemContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  height: auto;
  align-items: center;
  flex-wrap: wrap;
`;

export const ListItemTitle = styled.h2`
  font-size: 1.28rem;
  padding-top: 0;
  color: #8492a6;
  font-weight: 300;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 1rem;
`;

export const ListItemLegend = styled.p`
  font-weight: 400;
  font-size: 1.6rem;
  text-overflow: unset;
  width: 100%;
  overflow-x: auto;
  margin-bottom: 0;
  margin-top: 0;
  white-space: nowrap;
  padding-bottom: 0.2rem;
  height: 2.6rem;

  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
`;

export const ActionInnerContainer = styled.div`
  width: auto;
`;

export const ActionIconOuterContainer = styled.div`
  & svg {
    cursor: pointer;
  }
`;

export const EmptyInfoContainer = styled.div`
  background-color: #fff;
  padding: 2rem;
  border-radius: 0.6rem;
  margin-bottom: 2rem;
  margin-top: 0;
  display: flex;
  justify-content: center;
`;

export const ActionTooltip = styled(
  UncontrolledTooltip,
)<ActionItemTooltipProps>`
  & > *:first-child {
    z-index: 1000;
  }
  opacity: 1 !important;
  pointer-events: ${props => (props.autohide === false ? 'all' : 'none')};

  .tooltip-inner {
    display: inline-block;
    width: auto !important;
    position: relative;
    color: #333;
    background-color: #fff;
    border: 0.1rem solid #eeeeee;
    border-radius: 0.5rem;
    padding: 1rem 1.5rem;
    margin: 0 0 1rem 0;
    filter: drop-shadow(rgba(0, 0, 0, 0.6) 0 0.2rem 0.3rem);
    top: 0rem;
  }

  .arrow {
    display: none !important;
  }
`;

export const ListsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: calc(100% - 2.2rem);
  margin: 0 -3.2rem;
  padding: 0 3.2rem;
  position: relative;

  & > div:first-child {
    padding-top: 0;
  }

  & > div:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
`;

export const ActionIconContainer = styled.div`
  width: 2.4rem;
  height: 2.4rem;

  & svg path {
    fill: #8492a6;
  }
`;

export const NotifyInnerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: end;
  padding: 2rem 3.2rem;
  border-top: 0.1rem solid rgba(219, 219, 219, 0.5);
`;
