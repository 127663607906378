/* eslint-disable eqeqeq */
/* eslint-disable import/extensions */

import styled from 'styled-components';

import SVG from './Styles';

interface ITiktokIconProps {
  containerWidth?: string;
  containerHeight?: string;
  containerPaddingTop?: string;
  containerPaddingBottom?: string;
  containerPaddingLeft?: string;
  containerPaddingRight?: string;
  containerMarginTop?: string;
  containerMarginBottom?: string;
  containerMarginLeft?: string;
  containerMarginRight?: string;
  useCase?: 'additionalDataStep';
  disabledColored?: boolean;
  noAssignColor?: boolean;
  svgWidth?: string;
  svgHeight?: string;
  rotate?: string;
  cursor?: string;
}

interface IContainerProps {
  width?: string;
  height?: string;
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
}

const Container = styled.div<IContainerProps>`
  border-width: 0;
  outline: 0;
  width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '0')};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : '0')};
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '0')};
  padding-right: ${props => (props.paddingRight ? props.paddingRight : '0')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '0')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0')};
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const TiktokIcon = (props: ITiktokIconProps) => {
  return (
    <Container
      width={props.containerWidth}
      height={props.containerHeight}
      paddingTop={props.containerPaddingTop}
      paddingBottom={props.containerPaddingBottom}
      paddingLeft={props.containerPaddingLeft}
      paddingRight={props.containerPaddingRight}
      marginTop={props.containerMarginTop}
      marginBottom={props.containerMarginBottom}
      marginLeft={props.containerMarginLeft}
      marginRight={props.containerMarginRight}
    >
      <SVG
        viewBox="2 2 28 28"
        cursor={props.cursor}
        rotateNotNative={props.rotate}
        width={props.svgWidth ? props.svgWidth : '100%'}
        height={props.svgHeight ? props.svgHeight : '100%'}
      >
        <path
          fill={props.disabledColored ? '#bfbfbf' : '#0c0c0c'}
          d="M4 12.051c0-2.848 0-4.271 0.563-5.355 0.475-0.913 1.219-1.657 2.132-2.132 1.084-0.564 2.508-0.564 5.356-0.564h7.9c2.848 0 4.271 0 5.355 0.563 0.913 0.475 1.657 1.219 2.132 2.132 0.563 1.084 0.563 2.508 0.563 5.356v7.9c0 2.848 0 4.271-0.563 5.355-0.475 0.913-1.219 1.657-2.132 2.132-1.084 0.563-2.508 0.563-5.355 0.563h-7.9c-2.848 0-4.271 0-5.355-0.563-0.913-0.475-1.657-1.219-2.132-2.132-0.564-1.084-0.564-2.508-0.564-5.355v-7.9z"
        />
        <path
          fill={props.disabledColored ? '#f4f4f4' : '#ee1d52'}
          d="M19.212 13.937c0.981 0.697 2.156 1.071 3.36 1.065v-2.387c-0.237 0.001-0.473-0.025-0.704-0.076v1.901c-1.207 0.003-2.384-0.375-3.364-1.077v4.908c-0.003 0.805-0.224 1.595-0.639 2.285-0.415 0.689-1.009 1.255-1.72 1.635s-1.509 0.56-2.315 0.521c-0.804-0.039-1.584-0.293-2.255-0.739 0.621 0.628 1.415 1.057 2.279 1.235 0.865 0.177 1.764 0.093 2.581-0.24s1.517-0.903 2.012-1.635c0.495-0.732 0.76-1.595 0.764-2.477v-4.92zM20.083 11.504c-0.499-0.541-0.805-1.232-0.871-1.964v-0.311h-0.668c0.083 0.465 0.263 0.909 0.528 1.301 0.264 0.391 0.609 0.723 1.011 0.973zM13.123 20.085c-0.232-0.303-0.373-0.664-0.409-1.044s0.035-0.761 0.205-1.103c0.171-0.341 0.433-0.628 0.757-0.827 0.325-0.199 0.7-0.303 1.081-0.3 0.211 0 0.42 0.032 0.62 0.096v-2.465c-0.235-0.031-0.471-0.044-0.708-0.040v1.917c-0.489-0.155-1.017-0.119-1.481 0.099s-0.829 0.603-1.023 1.077c-0.193 0.475-0.201 1.005-0.021 1.485s0.533 0.875 0.991 1.107h-0.012z"
        />
        <path
          fill={props.disabledColored ? '#f4f4f4' : '#ffffff'}
          d="M18.503 13.349c0.981 0.703 2.157 1.080 3.364 1.077v-1.901c-0.688-0.145-1.312-0.503-1.785-1.023-0.401-0.251-0.747-0.581-1.012-0.973s-0.445-0.835-0.528-1.301h-1.757v9.628c-0.001 0.427-0.137 0.841-0.387 1.187s-0.601 0.604-1.005 0.74c-0.404 0.136-0.84 0.141-1.248 0.016s-0.765-0.375-1.024-0.713c-0.409-0.207-0.737-0.545-0.931-0.961s-0.241-0.884-0.136-1.331c0.105-0.447 0.359-0.844 0.717-1.129 0.36-0.285 0.804-0.44 1.263-0.441 0.211 0.001 0.42 0.033 0.62 0.096v-1.917c-0.868 0.021-1.711 0.295-2.425 0.788s-1.269 1.184-1.597 1.988c-0.327 0.804-0.413 1.687-0.245 2.539 0.167 0.852 0.579 1.637 1.185 2.257 0.671 0.448 1.452 0.705 2.257 0.745 0.807 0.040 1.608-0.14 2.32-0.52s1.308-0.945 1.724-1.637c0.416-0.692 0.636-1.484 0.637-2.291l-0.007-4.921z"
        />
        <path
          fill={props.disabledColored ? '#f4f4f4' : '#69c9d0'}
          d="M21.868 12.525v-0.513c-0.632 0.003-1.251-0.173-1.785-0.509 0.472 0.521 1.097 0.88 1.785 1.023zM18.544 9.229c0-0.092-0.028-0.187-0.040-0.279v-0.311h-2.425v9.632c-0.003 0.54-0.219 1.056-0.6 1.436-0.383 0.38-0.9 0.593-1.44 0.592-0.317 0.001-0.631-0.072-0.915-0.215 0.259 0.339 0.616 0.588 1.024 0.713s0.844 0.12 1.248-0.016c0.404-0.136 0.756-0.395 1.005-0.74s0.384-0.76 0.387-1.187v-9.627h1.756zM14.657 14.399v-0.545c-0.999-0.136-2.013 0.071-2.879 0.585s-1.532 1.308-1.889 2.249c-0.357 0.943-0.385 1.979-0.079 2.939s0.928 1.788 1.765 2.348c-0.603-0.623-1.009-1.407-1.173-2.257s-0.076-1.731 0.252-2.532c0.328-0.801 0.883-1.489 1.596-1.981s1.553-0.764 2.42-0.787l-0.013-0.019z"
        />
      </SVG>
    </Container>
  );
};

export default TiktokIcon;
