import { SortByFields, SortOrder } from 'Services/SkorrApi/new/User/types';
import { SocialNetworks } from 'Services/Utils/types';
import { z } from 'zod';

export const discoverFormSchema = z.object({
  filters: z
    .object({
      handler: z.array(z.string()).optional(),
      gender: z.array(z.string()).optional(),
      topicsInterest: z.array(z.string()).optional(),
      category: z.string().optional(),
      publicUserIds: z.array(z.string()).optional(),
      network: z.array(z.nativeEnum(SocialNetworks)).optional(),
      countryCode: z.array(z.string()).optional(),
      keywords: z.array(z.string()).optional(),
      audienceValueHigh: z.number().optional(),
      audienceValueLow: z.number().optional(),
      engValueHigh: z.number().optional(),
      engValueLow: z.number().optional(),
      postsValueHigh: z.number().optional(),
      postsValueLow: z.number().optional(),
      likesValueHigh: z.number().optional(),
      likesValueLow: z.number().optional(),
      sharesValueHigh: z.number().optional(),
      sharesValueLow: z.number().optional(),
      commentsValueHigh: z.number().optional(),
      commentsValueLow: z.number().optional(),
      startDate: z.number().optional(),
      endDate: z.number().optional(),
    })
    .superRefine((data, ctx) => {
      if (
        data.keywords?.[0] !== undefined &&
        data.keywords?.[0].trim() !== '' &&
        data.countryCode?.[0] === undefined
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Country field is required when keywords are filled',
          path: ['countryCode'],
        });
      }

      if (
        data.keywords?.[0] !== undefined &&
        data.keywords?.[0].trim() === ''
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Keywords cannot be empty',
          path: ['keywords'],
        });
      }
    }),
  page: z.coerce.number(),
  limit: z.coerce.number(),
  sort: z.nativeEnum(SortByFields),
  sortOrder: z.nativeEnum(SortOrder),
  selectedUserIds: z.array(z.string()).optional(),
});

export type DiscoverFormData = z.infer<typeof discoverFormSchema>;
