/* eslint-disable import/extensions */
import styled, { useTheme } from 'styled-components';
import { useState, useEffect } from 'react';

import SVG from './Styles';

interface IUsersIconV1Props {
  containerWidth?: string;
  containerHeight?: string;
  containerPaddingTop?: string;
  containerPaddingBottom?: string;
  containerPaddingLeft?: string;
  containerPaddingRight?: string;
  containerMarginTop?: string;
  containerMarginBottom?: string;
  containerMarginLeft?: string;
  containerMarginRight?: string;
  svgWidth?: string;
  svgHeight?: string;
  rotate?: string;
  cursor?: string;
}

interface IContainerProps {
  width?: string;
  height?: string;
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
}

const Container = styled.div<IContainerProps>`
  border-width: 0;
  outline: 0;
  width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '0')};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : '0')};
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '0')};
  padding-right: ${props => (props.paddingRight ? props.paddingRight : '0')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '0')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0')};
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const UsersIconV1 = (props: IUsersIconV1Props) => {
  const themeGlobal = useTheme();
  const [svgColor, setSvgColor] = useState<string>(() => {
    return (themeGlobal as any).icons.color1;
  });

  useEffect(() => {
    setSvgColor((themeGlobal as any).icons.color1);
  }, [(themeGlobal as any).darkMode]);

  return (
    <Container
      width={props.containerWidth}
      height={props.containerHeight}
      paddingTop={props.containerPaddingTop}
      paddingBottom={props.containerPaddingBottom}
      paddingLeft={props.containerPaddingLeft}
      paddingRight={props.containerPaddingRight}
      marginTop={props.containerMarginTop}
      marginBottom={props.containerMarginBottom}
      marginLeft={props.containerMarginLeft}
      marginRight={props.containerMarginRight}
    >
      <SVG
        viewBox="0 0 32 32"
        width={props.svgWidth ? props.svgWidth : '100%'}
        height={props.svgHeight ? props.svgHeight : '100%'}
        rotateNotNative={props.rotate}
        cursor={props.cursor}
      >
        <path
          fill={svgColor}
          d="M22.667 29.333c-0.737 0-1.333-0.596-1.333-1.333v-2.667c0-1.052-0.427-2.083-1.172-2.828s-1.776-1.172-2.828-1.172h-10.667c-1.068 0-2.072 0.416-2.829 1.172-0.744 0.745-1.171 1.775-1.171 2.828v2.667c0 0.737-0.597 1.333-1.333 1.333s-1.333-0.596-1.333-1.333v-2.667c0-1.781 0.693-3.455 1.952-4.713 1.261-1.259 2.935-1.953 4.715-1.953h10.667c1.78 0 3.455 0.695 4.713 1.953s1.953 2.933 1.953 4.713v2.667c0 0.737-0.596 1.333-1.333 1.333zM18.667 9.333c0-3.676-2.991-6.667-6.667-6.667s-6.667 2.991-6.667 6.667 2.991 6.667 6.667 6.667 6.667-2.991 6.667-6.667zM16 9.333c0 2.205-1.795 4-4 4s-4-1.795-4-4 1.795-4 4-4 4 1.795 4 4zM32 27.999v-2.668c-0.001-1.469-0.497-2.917-1.399-4.081-0.9-1.16-2.179-2.001-3.601-2.368-0.711-0.191-1.44 0.244-1.624 0.957-0.185 0.713 0.244 1.44 0.957 1.624 0.855 0.221 1.621 0.727 2.16 1.421 0.54 0.697 0.839 1.568 0.84 2.448v2.667c0 0.737 0.596 1.333 1.333 1.333s1.333-0.596 1.333-1.333zM21.664 15.797c1.425-0.365 2.709-1.207 3.613-2.371 0.903-1.164 1.4-2.616 1.4-4.087s-0.497-2.923-1.4-4.088c-0.904-1.164-2.188-2.005-3.613-2.371-0.712-0.185-1.44 0.248-1.623 0.961s0.248 1.44 0.961 1.623c0.856 0.22 1.627 0.724 2.168 1.421 0.541 0.699 0.84 1.571 0.84 2.453s-0.299 1.755-0.84 2.452-1.312 1.203-2.168 1.423c-0.713 0.183-1.143 0.909-0.961 1.623 0.153 0.603 0.696 1.003 1.291 1.003 0.109-0.001 0.221-0.015 0.332-0.043z"
        ></path>
      </SVG>
    </Container>
  );
};

export default UsersIconV1;
