/* eslint-disable import/extensions */
/* eslint-disable no-restricted-globals */
import { useTheme } from 'styled-components';
import { withTranslation } from 'react-i18next';
import Loader from 'react-loader-advanced';
import { useMemo } from 'react';

import { IGenericModalProps } from './types';
import * as S from './Styles';
import StyledButton from '../Button/Button';
import { getThemeStyles } from '../../../css/ThemeGlobal';
import { isNavbarDisplayed } from '../../../Services/Utils/Utils';
import CrossIcon_V2 from '../../../assets/iconComponents/CrossIcon_V2';

const GenericModal = (props: IGenericModalProps) => {
  const themeGlobal = useTheme();
  const isNavbarDisplayedLocal: boolean = useMemo(
    () => isNavbarDisplayed(location.pathname),
    [],
  );

  return (
    <S.ModalContainer
      show={props.show}
      ref={props.refNotNative}
      menuNavbarVisible={isNavbarDisplayedLocal}
      tabIndex={-1}
    >
      <S.Modal show={props.show} width={props.modalWidth}>
        <S.ModalInnerContainer>
          <Loader
            show={props.loading || false}
            message={props.t('generic.loading')}
          >
            <S.ModalTitleContainer>
              <S.ModalTitle>{props.title}</S.ModalTitle>
              {props.subtitle && (
                <S.ModalSubTitle>{props.subtitle}</S.ModalSubTitle>
              )}
              {props.isCancelable && props.cancelAction && (
                <S.CrossContainer onClick={() => props.cancelAction!!()}>
                  <CrossIcon_V2
                    containerHeight="2.5rem"
                    containerWidth="2.5rem"
                  />
                </S.CrossContainer>
              )}
            </S.ModalTitleContainer>

            <S.ModalBody>{props.body}</S.ModalBody>

            <S.ModalFooter>
              {props.extraButtonText && props.extraButtonAction && (
                <StyledButton
                  width={'auto'}
                  borderRadius={'0.96rem'}
                  onClick={() => props.extraButtonAction!!()}
                  pointerEvents={props.show ? 'all' : 'none'}
                >
                  {props.extraButtonText}
                </StyledButton>
              )}

              <S.MainButtonsContainer>
                {props.isCancelable && props.cancelAction && (
                  <StyledButton
                    width={'auto'}
                    borderRadius={'0.96rem'}
                    themeStyles={getThemeStyles(
                      ['button', 'secondary-1'],
                      themeGlobal,
                    )}
                    onClick={() => props.cancelAction!!()}
                  >
                    {props.t('generic.cancel')}
                  </StyledButton>
                )}

                {props.primaryButtonText && props.primaryButtonAction && (
                  <StyledButton
                    id={'generic-modal-close-button-id'}
                    width={'auto'}
                    borderRadius={'0.96rem'}
                    onClick={() => props.primaryButtonAction!!()}
                    pointerEvents={props.show ? 'all' : 'none'}
                    disabled={props.primaryButtonDisabled}
                    marginLeft={props.primaryButtonLeftMargin}
                  >
                    {props.primaryButtonText}
                  </StyledButton>
                )}
              </S.MainButtonsContainer>
            </S.ModalFooter>
          </Loader>
        </S.ModalInnerContainer>
      </S.Modal>
    </S.ModalContainer>
  );
};

export default withTranslation()(GenericModal);
