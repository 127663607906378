/* eslint-disable no-param-reassign */
/* eslint-disable import/extensions */
import { useMutation, useQuery } from 'react-query';

import { IErrorObject } from '../../Services/Utils/types';
import SkorrApi from '../../Services/SkorrApi/new';
import { logApiRequestError } from '../../Services/Utils/Utils';
import {
  CreateListInput,
  UpdateListByIdInput,
} from '../../Services/SkorrApi/new/User/types';

const { user } = SkorrApi;

export const useCreateList = () => {
  return useMutation(
    async (params: CreateListInput) => {
      try {
        const { data: result } = await user.createList(params);
        return result;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
    },
  );
};

export const useUpdateListById = () => {
  return useMutation(
    async (params: UpdateListByIdInput) => {
      try {
        const { data: result } = await user.updateListById(params);
        return result;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
    },
  );
};

type UseGetListByIdInput = {
  id: string;
  memberCount?: boolean;
  enabled?: boolean;
};

export const useGetListById = ({
  id,
  memberCount,
  enabled,
}: UseGetListByIdInput) => {
  return useQuery(
    ['get-list-by-id', id],
    async () => {
      try {
        const data = await user.getListById({
          id,
          memberCount: memberCount ?? false,
        });

        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      staleTime: 0,
      retry: false,
    },
  );
};
