import { DiscoverProvider } from 'features/Discover/hooks/contexts/use-discover-context';
import { Outlet } from 'react-router-dom';

export function DiscoverStack() {
  return (
    <DiscoverProvider>
      <Outlet />
    </DiscoverProvider>
  );
}
