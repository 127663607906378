/* eslint-disable no-use-before-define */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable default-case */
/* eslint-disable import/extensions */
import { useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { CircleFlag } from 'react-circle-flags';

import * as S from './Styles';
import {
  useDeletePublicUserFromList,
  useGetListByIdWithPublicUsers,
} from './RQCustomHooks';
import Loader from '../../components/shared/LoaderFixed/LoaderFixed';
import GenericModal from '../../components/shared/GenericModal/GenericModal';
import { ListsProps } from './types';
import BackArrowIcon from '../../assets/iconComponents/BackArrowIcon';
import EmptyInfo from '../../components/shared/EmptyInfo/EmptyInfo';
import ExclamationPointIcon from '../../assets/iconComponents/ExclamationPointIcon';
import CheckMarkIcon from '../../assets/iconComponents/CheckMarkIcon';
import NotifyCustom from '../../components/shared/NotifyCustom/NotifyCustom';
import InputSpinnerIcon from '../../assets/iconComponents/InputSpinnerIcon/InputSpinnerIcon';
import { useIsOperatorOrPartnerAdmin } from '../SignIn/RQCustomHooks';
import EyeIcon from '../../assets/iconComponents/EyeIcon';
import {
  convertNumberToHumanStringfiedFormat,
  getSocialNetworkIcon,
} from '../../Services/Utils/Utils';
import GlobeIcon from '../../assets/iconComponents/Globe';
import { StyledButton } from '../../components/shared/Button/Styles';
import { getThemeStyles } from '../../css/ThemeGlobal';
import CheckBox from '../../components/shared/Checkbox/CheckBox';

const PublicUserListMembers = (props: ListsProps) => {
  const navigate = useNavigate();
  const [isGetListWithPublicUsersEnabled, setIsGetListWithPublicUsersEnabled] =
    useState(true);
  const [isOperatorOrPartnerAdminEnabled, setIsOperatorOrPartnerAdminEnabled] =
    useState(true);
  const [publicUserIdsToDelete, setPublicUserIdsToDelete] = useState<
    Set<string>
  >(new Set());
  const [deletedPublicUserIds, setDeletedPublicUserIds] = useState<Set<string>>(
    new Set(),
  );
  const [hasError, setHasError] = useState(false);
  const [msgError, setMsgError] = useState('');
  const { id: listId } = useParams();
  const {
    data: listWithPublicUsers,
    isFetching: isFetchingGetListWithPublicUsers,
    isSuccess: isSuccessGetListWithPublicUsers,
    isError: isErrorGetListWithPublicUsers,
  } = useGetListByIdWithPublicUsers({
    enabled: isGetListWithPublicUsersEnabled,
    id: listId!,
  });
  const showNoPublicUsersWarning = useMemo(() => {
    if (
      isSuccessGetListWithPublicUsers &&
      !isFetchingGetListWithPublicUsers &&
      !listWithPublicUsers.publicUsers.length &&
      listWithPublicUsers.publicUsers.filter(
        publicUser => !deletedPublicUserIds.has(publicUser._id!),
      ).length == 0
    ) {
      return true;
    }
  }, [
    isSuccessGetListWithPublicUsers,
    isFetchingGetListWithPublicUsers,
    listWithPublicUsers?.publicUsers.length,
    deletedPublicUserIds.size,
  ]);
  const displayPublicUsers = useMemo(() => {
    if (
      isSuccessGetListWithPublicUsers &&
      !isFetchingGetListWithPublicUsers &&
      listWithPublicUsers.publicUsers.length &&
      listWithPublicUsers.publicUsers.some(
        publicUser => !deletedPublicUserIds.has(publicUser._id!),
      )
    ) {
      return true;
    }
  }, [
    isSuccessGetListWithPublicUsers,
    isFetchingGetListWithPublicUsers,
    listWithPublicUsers?.publicUsers.length,
    deletedPublicUserIds.size,
  ]);
  const {
    isLoading: isLoadingDeletePublicUserFromList,
    isSuccess: isSuccessDeletePublicUserFromList,
    isError: isErrorDeletePublicUserFromList,
    mutate: deletePublicUserFromList,
  } = useDeletePublicUserFromList();
  const {
    data: isOperatorOrPartnerAdmin,
    isFetching: isFetchingIsOperatorOrPartnerAdmin,
    isSuccess: isSuccessIsOperatorOrPartnerAdmin,
    isError: isErrorIsOperatorOrPartnerAdmin,
  } = useIsOperatorOrPartnerAdmin(isOperatorOrPartnerAdminEnabled);
  const showDeleteFromListButton = useMemo(() => {
    if (
      isSuccessIsOperatorOrPartnerAdmin &&
      !isFetchingIsOperatorOrPartnerAdmin &&
      isOperatorOrPartnerAdmin
    ) {
      return true;
    }
    return false;
  }, [
    isSuccessIsOperatorOrPartnerAdmin,
    isFetchingIsOperatorOrPartnerAdmin,
    isOperatorOrPartnerAdmin,
  ]);

  const [
    showSuccessfulActionNotification,
    setShowSuccessfulActionNotification,
  ] = useState<boolean>(false);
  const [
    successfulActionNotificationMessage,
    setSuccessfulActionNotificationMessage,
  ] = useState('');
  const [
    loadingActionNotificationMessage,
    setLoadingActionNotificationMessage,
  ] = useState('');
  const themeGlobal = useTheme();

  useEffect(() => {
    if (isSuccessGetListWithPublicUsers) {
      setIsGetListWithPublicUsersEnabled(false);
    }

    if (isSuccessIsOperatorOrPartnerAdmin) {
      setIsOperatorOrPartnerAdminEnabled(false);
    }

    if (isSuccessDeletePublicUserFromList) {
      setSuccessfulActionNotificationMessage(
        publicUserIdsToDelete.size > 1
          ? props.t('lists.membersDeletedFromList')
          : props.t('lists.memberDeletedFromList'),
      );
      setShowSuccessfulActionNotification(true);
      setTimeout(() => {
        setShowSuccessfulActionNotification(false);
      }, 2000);

      setDeletedPublicUserIds(prevState => {
        return new Set([
          ...Array.from(prevState.values()),
          ...Array.from(publicUserIdsToDelete.values()),
        ]);
      });

      setPublicUserIdsToDelete(new Set());
    }
  }, [
    isSuccessGetListWithPublicUsers,
    isSuccessIsOperatorOrPartnerAdmin,
    isSuccessDeletePublicUserFromList,
  ]);

  useEffect(() => {
    if (isErrorGetListWithPublicUsers) {
      setIsGetListWithPublicUsersEnabled(false);
      setHasError(true);
      setMsgError(props.t('generic.couldNotGetData'));
    }

    if (isErrorIsOperatorOrPartnerAdmin) {
      setIsOperatorOrPartnerAdminEnabled(false);
      setHasError(true);
      setMsgError(props.t('generic.couldNotGetData'));
    }

    if (isErrorDeletePublicUserFromList) {
      setHasError(true);
      setMsgError(props.t('lists.couldNotDeleteMemberFromList'));
    }
  }, [
    isErrorGetListWithPublicUsers,
    isErrorIsOperatorOrPartnerAdmin,
    isErrorDeletePublicUserFromList,
  ]);

  const errorModalCloseButtonClicked = () => {
    setHasError(false);
    if (isErrorGetListWithPublicUsers) {
      setIsGetListWithPublicUsersEnabled(true);
    }

    if (isErrorIsOperatorOrPartnerAdmin) {
      setIsOperatorOrPartnerAdminEnabled(true);
    }
  };

  return (
    <S.Container>
      <Loader
        show={
          isFetchingGetListWithPublicUsers || isFetchingIsOperatorOrPartnerAdmin
        }
        message={props.t('generic.gettingData')}
      />

      <S.InnerContainer>
        <S.Card>
          <S.HeaderOuterContainer>
            <S.HeaderContainer>
              <S.HeaderBackArrowContainer
                onClick={() => {
                  navigate(-1);
                }}
              >
                <BackArrowIcon width={'4rem'} height={'4rem'} />
              </S.HeaderBackArrowContainer>
              <S.HeaderTitleContainer>
                {props.t('lists.listMembers')}
              </S.HeaderTitleContainer>
              <S.HiddenHeaderElement />
            </S.HeaderContainer>
          </S.HeaderOuterContainer>

          <S.CardInnerContainer>
            <S.FirstRowContainer>
              <S.ListNameContainer>
                <S.ListNameLabel>{props.t('generic.name')}: </S.ListNameLabel>

                <S.ListName>{listWithPublicUsers?.name}</S.ListName>
              </S.ListNameContainer>

              {showDeleteFromListButton && (
                <StyledButton
                  onClick={() => {
                    setLoadingActionNotificationMessage(
                      publicUserIdsToDelete.size > 1
                        ? props.t('lists.deletingListMembers')
                        : props.t('lists.deletingListMember'),
                    );
                    deletePublicUserFromList({
                      listId: listId!,
                      publicUserId: Array.from(publicUserIdsToDelete),
                    });
                  }}
                  themeStyles={getThemeStyles(
                    ['button', 'danger'],
                    themeGlobal,
                  )}
                  disabled={
                    isLoadingDeletePublicUserFromList ||
                    !publicUserIdsToDelete.size
                  }
                >
                  {props.t('generic.delete')}
                </StyledButton>
              )}
            </S.FirstRowContainer>

            {displayPublicUsers && (
              <S.MembersContainer>
                {listWithPublicUsers
                  ?.publicUsers!.filter(
                    publicUser => !deletedPublicUserIds.has(publicUser._id!),
                  )
                  .map(publicUser => (
                    <S.PublicUserCardOuterContainer>
                      <S.PublicUserCardInnerContainer
                        selected={publicUserIdsToDelete.has(publicUser._id!)}
                      >
                        <S.PublicUserCardPictureContainer>
                          <S.PublicUserCardPictureOverlay>
                            <S.PublicUserCardPictureOverlayActions>
                              <S.PublicUserCardPictureOverlayIcon
                                onClick={() =>
                                  navigate(`/user-media-kit/${publicUser._id!}`)
                                }
                              >
                                <EyeIcon
                                  fill={'white'}
                                  width={'6rem'}
                                  height={'6rem'}
                                />
                              </S.PublicUserCardPictureOverlayIcon>
                            </S.PublicUserCardPictureOverlayActions>
                          </S.PublicUserCardPictureOverlay>

                          <S.PublicUserCardUserInfoContainer>
                            <div>
                              <S.PublicUserCardUsernameText>
                                {publicUser.name}
                              </S.PublicUserCardUsernameText>
                              <S.PublicUserCardHandlerText>{`@${publicUser.socialNetwork?.username}`}</S.PublicUserCardHandlerText>
                            </div>

                            {getSocialNetworkIcon({
                              socialNetwork:
                                publicUser.socialNetwork?.network ?? '',
                              width: '2rem',
                              height: '2rem',
                              gradientColored: true,
                            })}
                          </S.PublicUserCardUserInfoContainer>

                          <S.PublicUserCardPicture src={publicUser.image} />
                        </S.PublicUserCardPictureContainer>

                        <S.PublicUserCardDescriptionContainer>
                          <S.PublicUserCardDescriptionTextContainer>
                            <S.PublicUserCardDescriptionText>
                              <p>
                                {publicUser.biography ??
                                  props.t(
                                    'lists.influencerDoesNotHaveBiography',
                                  )}
                              </p>
                            </S.PublicUserCardDescriptionText>
                          </S.PublicUserCardDescriptionTextContainer>

                          <S.PublicUserCardCountryContainer>
                            {publicUser.countryCode ? (
                              <CircleFlag
                                height="20"
                                countryCode={publicUser.countryCode.toLowerCase()}
                              />
                            ) : (
                              <S.PublicUserCardGlobeIconContainer>
                                <GlobeIcon containerHeight="2rem" />
                              </S.PublicUserCardGlobeIconContainer>
                            )}
                          </S.PublicUserCardCountryContainer>
                        </S.PublicUserCardDescriptionContainer>

                        <S.PublicUserCardKpisContainer>
                          <S.PublicUserCardKpiContainer>
                            <svg viewBox="0 0 23 15">
                              <path
                                opacity="0.5"
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M10.7446 3.82396C10.7446 5.44707 9.44412 6.75729 7.82101 6.75729C6.1979 6.75729 4.88767 5.44707 4.88767 3.82396C4.88767 2.20085 6.1979 0.890625 7.82101 0.890625C9.44412 0.890625 10.7446 2.20085 10.7446 3.82396ZM18.5668 3.82396C18.5668 5.44707 17.2663 6.75729 15.6432 6.75729C14.0201 6.75729 12.7099 5.44707 12.7099 3.82396C12.7099 2.20085 14.0201 0.890625 15.6432 0.890625C17.2663 0.890625 18.5668 2.20085 18.5668 3.82396ZM7.82101 8.71285C5.54278 8.71285 0.976562 9.85685 0.976562 12.1351V14.5795H14.6655V12.1351C14.6655 9.85685 10.0992 8.71285 7.82101 8.71285ZM14.6948 8.76174C15.037 8.7324 15.3597 8.71285 15.6432 8.71285C17.9215 8.71285 22.4877 9.85685 22.4877 12.1351V14.5795H16.621V12.1351C16.621 10.688 15.829 9.58307 14.6948 8.76174Z"
                                fill="#8492A6"
                              />
                            </svg>

                            <S.PublicUserCardKpiInnerContainer>
                              <S.PublicUserKpiValue>
                                {convertNumberToHumanStringfiedFormat(
                                  publicUser.kpi?.audience ?? 0,
                                )}
                              </S.PublicUserKpiValue>
                              <S.PublicUserKpiLabel>
                                {props.t('kpis.audience')}
                              </S.PublicUserKpiLabel>
                            </S.PublicUserCardKpiInnerContainer>
                          </S.PublicUserCardKpiContainer>

                          <S.PublicUserCardKpiContainer>
                            <svg viewBox="0 0 23 15">
                              <path
                                opacity="0.5"
                                d="M2.295 13L10.421 1.1H13.294L5.168 13H2.295ZM3.434 7.56C2.83333 7.56 2.295 7.43533 1.819 7.186C1.343 6.92533 0.963333 6.54567 0.68 6.047C0.408 5.54833 0.272 4.94767 0.272 4.245C0.272 3.54233 0.408 2.94733 0.68 2.46C0.963333 1.96133 1.343 1.58167 1.819 1.321C2.295 1.06033 2.83333 0.929999 3.434 0.929999C4.03467 0.929999 4.573 1.06033 5.049 1.321C5.525 1.58167 5.899 1.96133 6.171 2.46C6.45433 2.94733 6.596 3.54233 6.596 4.245C6.596 4.94767 6.45433 5.54833 6.171 6.047C5.899 6.54567 5.525 6.92533 5.049 7.186C4.573 7.43533 4.03467 7.56 3.434 7.56ZM3.434 5.724C3.62667 5.724 3.79667 5.622 3.944 5.418C4.10267 5.20267 4.182 4.81167 4.182 4.245C4.182 3.67833 4.10267 3.293 3.944 3.089C3.79667 2.87367 3.62667 2.766 3.434 2.766C3.25267 2.766 3.08267 2.87367 2.924 3.089C2.76533 3.293 2.686 3.67833 2.686 4.245C2.686 4.81167 2.76533 5.20267 2.924 5.418C3.08267 5.622 3.25267 5.724 3.434 5.724ZM12.155 13.17C11.5543 13.17 11.016 13.0453 10.54 12.796C10.064 12.5353 9.68433 12.1557 9.401 11.657C9.129 11.1583 8.993 10.5577 8.993 9.855C8.993 9.15233 9.129 8.55733 9.401 8.07C9.68433 7.57133 10.064 7.19167 10.54 6.931C11.016 6.67033 11.5543 6.54 12.155 6.54C12.7557 6.54 13.294 6.67033 13.77 6.931C14.246 7.19167 14.62 7.57133 14.892 8.07C15.1753 8.55733 15.317 9.15233 15.317 9.855C15.317 10.5577 15.1753 11.1583 14.892 11.657C14.62 12.1557 14.246 12.5353 13.77 12.796C13.294 13.0453 12.7557 13.17 12.155 13.17ZM12.155 11.334C12.3477 11.334 12.5177 11.232 12.665 11.028C12.8237 10.8127 12.903 10.4217 12.903 9.855C12.903 9.28833 12.8237 8.903 12.665 8.699C12.5177 8.48367 12.3477 8.376 12.155 8.376C11.9737 8.376 11.8037 8.48367 11.645 8.699C11.4863 8.903 11.407 9.28833 11.407 9.855C11.407 10.4217 11.4863 10.8127 11.645 11.028C11.8037 11.232 11.9737 11.334 12.155 11.334Z"
                                fill="#8492A6"
                              />
                            </svg>

                            <S.PublicUserCardKpiInnerContainer>
                              <S.PublicUserKpiValue>
                                {publicUser.kpi?.engagement_rate !== 0
                                  ? (
                                      publicUser.kpi?.engagement_rate || 0 * 100
                                    ).toFixed(3)
                                  : 0}
                              </S.PublicUserKpiValue>
                              <S.PublicUserKpiLabel>
                                {props.t('kpis.engRate')}
                              </S.PublicUserKpiLabel>
                            </S.PublicUserCardKpiInnerContainer>
                          </S.PublicUserCardKpiContainer>

                          <S.PublicUserCardKpiContainer>
                            <svg viewBox="0 0 23 15">
                              <path
                                opacity="0.5"
                                d="M16 4.21857C16 4.43516 16 4.65175 16 4.86834C15.9919 4.91315 15.9798 4.95765 15.9762 5.00281C15.9157 5.76051 15.6728 6.46085 15.2997 7.12035C14.8297 7.95105 14.209 8.66553 13.5301 9.32976C11.8843 10.9399 10.0736 12.3659 8.33398 13.8713C8.12761 14.0498 7.88054 14.0388 7.66609 13.8619C7.48616 13.7135 7.30931 13.5614 7.13303 13.4088C5.73459 12.1977 4.32348 11.0006 2.94333 9.76939C2.08744 9.00586 1.30477 8.166 0.738639 7.15919C-0.212401 5.46785 -0.263347 3.75071 0.692323 2.04903C1.22196 1.10596 2.03957 0.473117 3.09444 0.177368C4.2197 -0.138118 5.29831 -0.0243649 6.3015 0.599388C6.93997 0.99637 7.42645 1.54086 7.8247 2.16812C7.88353 2.26077 7.94104 2.35424 7.98345 2.42218C8.28576 2.03587 8.55038 1.62908 8.88218 1.28612C10.0514 0.0776427 11.4721 -0.291586 13.0727 0.231024C14.5793 0.722929 15.4528 1.82663 15.8421 3.32388C15.9181 3.61614 15.9486 3.92004 16 4.21857Z"
                                fill="#8492A6"
                              />
                            </svg>

                            <S.PublicUserCardKpiInnerContainer>
                              <S.PublicUserKpiValue>
                                {convertNumberToHumanStringfiedFormat(
                                  publicUser.kpi?.likes ?? 0,
                                )}
                              </S.PublicUserKpiValue>
                              <S.PublicUserKpiLabel>
                                {props.t('kpis.likes')}
                              </S.PublicUserKpiLabel>
                            </S.PublicUserCardKpiInnerContainer>
                          </S.PublicUserCardKpiContainer>

                          <S.PublicUserCardKpiContainer>
                            <svg viewBox="0 0 23 15">
                              <path
                                opacity="0.5"
                                d="M2.9206 13.0233C2.45058 12.9166 2.01815 12.7489 1.63212 12.4805C0.585015 11.7527 0.0278075 10.7451 0.0145711 9.47199C-0.00568744 7.52415 -0.00346776 5.57584 0.0129007 3.62793C0.0283159 1.79303 1.32375 0.321407 3.14037 0.0403115C3.33014 0.0109492 3.5248 0.00271122 3.71721 0.00255673C6.57723 0.000253003 9.43725 -0.0014782 12.2973 0.00186152C14.0898 0.00395624 15.5472 1.17395 15.9158 2.90957C15.9708 3.16851 15.9936 3.43883 15.9945 3.704C16.001 5.60091 16.0016 7.49787 15.997 9.39479C15.9925 11.2148 14.7918 12.6813 13.0212 13.0265C12.7727 13.075 12.514 13.0857 12.2598 13.0868C10.9717 13.0926 9.68356 13.0872 8.39546 13.0936C8.29065 13.0942 8.17125 13.1312 8.08371 13.1891C6.76443 14.0628 5.44884 14.9421 4.13393 15.8223C3.96412 15.936 3.78786 16.0189 3.57894 15.9963C3.18873 15.9539 2.92553 15.6636 2.92243 15.2582C2.91723 14.5767 2.92075 13.8951 2.9206 13.2136C2.92058 13.152 2.9206 13.0903 2.9206 13.0233ZM8.00329 5.82092C9.05896 5.82092 10.1146 5.82097 11.1703 5.8209C11.5733 5.82087 11.9763 5.82571 12.3792 5.81882C12.7314 5.81279 13.0308 5.54618 13.0776 5.21251C13.1285 4.84985 12.9335 4.51335 12.5951 4.40925C12.4785 4.37336 12.3495 4.36568 12.2261 4.36557C9.41094 4.36327 6.59583 4.36378 3.78071 4.36389C3.72397 4.36389 3.66682 4.36124 3.61055 4.36676C3.3011 4.39716 3.07578 4.54874 2.96442 4.84352C2.78109 5.32874 3.13902 5.8192 3.67847 5.82008C5.12007 5.82243 6.56168 5.82088 8.00329 5.82092ZM7.9854 8.72663C8.55844 8.72663 9.13147 8.72692 9.70451 8.72653C10.1187 8.72626 10.5331 8.73436 10.9469 8.72182C11.3929 8.7083 11.7037 8.30168 11.6195 7.86385C11.5486 7.4953 11.2622 7.2786 10.838 7.27842C8.94867 7.2776 7.05936 7.27803 5.17005 7.27821C5.12469 7.27822 5.0792 7.27869 5.03399 7.28194C4.72664 7.30398 4.46256 7.52967 4.39308 7.8287C4.28222 8.30589 4.61765 8.7228 5.12591 8.72518C6.07906 8.72963 7.03224 8.72646 7.9854 8.72663Z"
                                fill="#8492A6"
                              />
                            </svg>

                            <S.PublicUserCardKpiInnerContainer>
                              <S.PublicUserKpiValue>
                                {convertNumberToHumanStringfiedFormat(
                                  publicUser.kpi?.comments ?? 0,
                                )}
                              </S.PublicUserKpiValue>
                              <S.PublicUserKpiLabel>
                                {props.t('kpis.comments')}
                              </S.PublicUserKpiLabel>
                            </S.PublicUserCardKpiInnerContainer>
                          </S.PublicUserCardKpiContainer>

                          <S.PublicUserCardKpiContainer>
                            <svg viewBox="0 0 23 15">
                              <path
                                opacity="0.5"
                                d="M9.03724 0C9.36661 0.276045 9.69294 0.555826 10.0259 0.827537C12.3164 2.69706 14.6083 4.56488 16.8996 6.4335C16.9289 6.45733 16.9555 6.48437 17 6.52528C14.342 8.70085 11.6925 10.8696 9.01549 13.0607C9.01549 11.704 9.01549 10.3791 9.01549 9.01479C8.62193 9.06411 8.24782 9.08871 7.88289 9.16024C4.92425 9.74018 2.70792 11.3472 1.31302 14.0285C0.859787 14.8997 0.523778 15.832 0.13271 16.7357C0.0936141 16.826 0.0444777 16.912 0 17C0 15.6804 0 14.3607 0 13.0411C0.00944835 12.9926 0.0245673 12.9445 0.0275174 12.8956C0.0960656 11.7609 0.350103 10.6674 0.838649 9.64325C2.28679 6.60733 4.66604 4.76884 7.97587 4.15484C8.31167 4.09254 8.6542 4.06665 9.00401 4.02256C9.00401 2.68147 9.00401 1.34074 9.00401 0C9.01509 0 9.02616 0 9.03724 0Z"
                                fill="#8492A6"
                              />
                            </svg>

                            <S.PublicUserCardKpiInnerContainer>
                              <S.PublicUserKpiValue>
                                {convertNumberToHumanStringfiedFormat(
                                  publicUser.kpi?.shares ?? 0,
                                )}
                              </S.PublicUserKpiValue>
                              <S.PublicUserKpiLabel>
                                {props.t('kpis.shares')}
                              </S.PublicUserKpiLabel>
                            </S.PublicUserCardKpiInnerContainer>
                          </S.PublicUserCardKpiContainer>

                          <S.PublicUserCardKpiContainer>
                            <svg viewBox="0 0 23 15">
                              <g opacity="0.5">
                                <path
                                  d="M9.05259 0C10.7323 0 12.4119 0 14.0916 0C14.3446 0.0795139 14.5957 0.175662 14.7464 0.404829C14.8535 0.567534 14.9169 0.758911 14.9998 0.937491C14.9998 3.23239 14.9998 5.52729 14.9998 7.82219C14.9945 7.83031 14.9868 7.83775 14.9843 7.84666C14.8032 8.47624 14.436 8.75227 13.778 8.75232C12.2939 8.75244 10.8099 8.75328 9.32581 8.7507C9.19999 8.75048 9.07032 8.74217 8.94908 8.71142C8.437 8.58158 8.12361 8.1545 8.12297 7.59225C8.1214 6.22047 8.12242 4.84868 8.12242 3.4769C8.12241 2.70069 8.12073 1.92448 8.12305 1.14828C8.12458 0.638961 8.3871 0.243634 8.83198 0.0722982C8.9041 0.0445251 8.97898 0.0239207 9.05259 0Z"
                                  fill="#8492A6"
                                />
                                <path
                                  d="M0 14.0906C0 11.7859 0 9.48124 0 7.17658C0.00481636 7.16809 0.0114658 7.16015 0.0141816 7.15104C0.203033 6.51705 0.563918 6.24649 1.22214 6.24644C2.71596 6.24633 4.20977 6.24554 5.70358 6.24679C6.41451 6.24738 6.87524 6.70937 6.87705 7.42119C6.87853 8.00211 6.87738 8.58304 6.87738 9.16397C6.87738 10.7213 6.87747 12.2785 6.87731 13.8358C6.87727 14.3126 6.67895 14.6734 6.24767 14.891C6.15339 14.9386 6.04766 14.9634 5.94721 14.9988C4.2773 14.9988 2.6074 14.9988 0.937491 14.9988C0.591859 14.9163 0.305475 14.7481 0.134864 14.4244C0.0791856 14.3188 0.0443609 14.2022 0 14.0906Z"
                                  fill="#8492A6"
                                />
                                <path
                                  d="M5.94721 0C6.12915 0.0884259 6.33017 0.151517 6.4888 0.270915C6.76207 0.476589 6.87542 0.779444 6.87633 1.11875C6.87883 2.04129 6.88313 2.9639 6.87506 3.88639C6.86943 4.52933 6.40496 4.99694 5.76182 4.99989C4.2145 5.007 2.66712 5.00529 1.11978 5.00059C0.569133 4.99892 0.1629 4.66946 0.0265858 4.13959C0.0206181 4.11639 0.00899112 4.09465 0 4.07223C0 3.01756 0 1.96288 0 0.908201C0.0179852 0.858968 0.0371546 0.81013 0.0537599 0.760434C0.153108 0.463076 0.341753 0.243223 0.62576 0.109581C0.716911 0.0666904 0.813886 0.0361817 0.908194 6.25843e-06C2.58786 3.99109e-07 4.26754 0 5.94721 0Z"
                                  fill="#8492A6"
                                />
                                <path
                                  d="M9.0526 14.9997C8.94796 14.9617 8.83819 14.9339 8.7396 14.884C8.34232 14.6828 8.13081 14.3508 8.12624 13.9099C8.11645 12.9679 8.11337 12.0255 8.12687 11.0835C8.13592 10.4517 8.61242 10.0017 9.25299 9.99879C10.3758 9.99374 11.4986 9.99734 12.6214 9.99732C13.0217 9.99731 13.422 9.99657 13.8223 9.9975C14.4323 9.99891 14.8223 10.3003 14.9791 10.8894C14.9827 10.9029 14.9927 10.9148 14.9998 10.9275C14.9998 11.9724 14.9998 13.0173 14.9998 14.0622C14.9232 14.2327 14.8663 14.4159 14.7656 14.5706C14.6039 14.8191 14.342 14.9303 14.0623 14.9997C12.3924 14.9997 10.7225 14.9997 9.0526 14.9997Z"
                                  fill="#8492A6"
                                />
                              </g>
                            </svg>

                            <S.PublicUserCardKpiInnerContainer>
                              <S.PublicUserKpiValue>
                                {convertNumberToHumanStringfiedFormat(
                                  publicUser.kpi?.posts ?? 0,
                                )}
                              </S.PublicUserKpiValue>
                              <S.PublicUserKpiLabel>posts</S.PublicUserKpiLabel>
                            </S.PublicUserCardKpiInnerContainer>
                          </S.PublicUserCardKpiContainer>
                        </S.PublicUserCardKpisContainer>

                        <S.PublicUserCheckboxContainer
                          selectable={!isLoadingDeletePublicUserFromList}
                        >
                          <CheckBox
                            checked={publicUserIdsToDelete.has(publicUser._id!)}
                            onChange={() => {
                              if (publicUserIdsToDelete.has(publicUser._id!)) {
                                setPublicUserIdsToDelete(prevState => {
                                  return new Set(
                                    Array.from(prevState.values()).filter(
                                      publicUserId =>
                                        publicUserId !== publicUser._id!,
                                    ),
                                  );
                                });
                              } else {
                                setPublicUserIdsToDelete(prevState => {
                                  return new Set([
                                    ...Array.from(prevState.values()),
                                    publicUser._id!,
                                  ]);
                                });
                              }
                            }}
                            checkBoxContainerWidth={'2rem'}
                            checkBoxContainerHeight={'2rem'}
                            borderWidth={'0.25rem'}
                          />
                        </S.PublicUserCheckboxContainer>
                      </S.PublicUserCardInnerContainer>
                    </S.PublicUserCardOuterContainer>
                  ))}
              </S.MembersContainer>
            )}

            {showNoPublicUsersWarning && (
              <S.EmptyInfoContainer>
                <EmptyInfo
                  width="auto"
                  icon={
                    <ExclamationPointIcon
                      containerWidth="4rem"
                      containerHeight="5rem"
                    />
                  }
                  header={props.t('lists.noListMembersAvailableInfoTitle')}
                  legend={props.t('lists.noListMembersAvailableInfoSubtitle')}
                />
              </S.EmptyInfoContainer>
            )}
          </S.CardInnerContainer>
        </S.Card>
      </S.InnerContainer>

      <NotifyCustom
        location={'top'}
        show={isLoadingDeletePublicUserFromList}
        themeStyles={(themeGlobal as any).signin['regular-notify']}
      >
        <S.NotifyInnerContainer>
          <InputSpinnerIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
          />
          {loadingActionNotificationMessage}
        </S.NotifyInnerContainer>
      </NotifyCustom>

      <NotifyCustom
        location={'top'}
        show={showSuccessfulActionNotification}
        themeStyles={(themeGlobal as any).signin['success-notify']}
        topInitialOffset={'5rem'}
      >
        <S.NotifyInnerContainer>
          <CheckMarkIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
            useCase={'success-notify'}
          />
          {successfulActionNotificationMessage}
        </S.NotifyInnerContainer>
      </NotifyCustom>

      <GenericModal
        show={hasError}
        title={props.t('generic.uhoh')}
        body={msgError}
        primaryButtonText={props.t('generic.close')}
        primaryButtonAction={() => errorModalCloseButtonClicked()}
      />
    </S.Container>
  );
};

export default withTranslation()(PublicUserListMembers);
