/* eslint-disable no-undef */
/* eslint-disable prefer-destructuring */
/* eslint-disable valid-typeof */
/* eslint-disable eqeqeq */
/* eslint-disable consistent-return */
/* eslint-disable default-case */
/* eslint-disable no-plusplus */
/* eslint-disable import/extensions */
import { ReactChild, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { FaTimes } from 'react-icons/fa';
import { GiMagnifyingGlass } from 'react-icons/gi';
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';
import Loader from 'react-loader-advanced';

import CrossIcon_V2 from '../../../assets/iconComponents/CrossIcon_V2';
import MagnifyingGlassIcon from '../../../assets/iconComponents/MagnifyingGlassIcon';
import * as S from './Styles';
import { IComboBoxProps } from './types';

const ClickOutHandler = require('react-onclickout');

const isStringOrNumber = (value: any) => {
  return typeof value === 'string' || typeof value === 'number';
};

const getLabelText = (entryLabel: any): string => {
  if (typeof entryLabel === 'string' || typeof entryLabel === 'number') {
    return String(entryLabel);
  }
  let text = '';
  if (!Array.isArray(entryLabel?.props?.children)) {
    text += String(entryLabel?.props?.children);
    return text;
  }
  if (Array.isArray(entryLabel?.props?.children)) {
    for (let i = 0; i < entryLabel.props.children.length; i++) {
      if (
        typeof entryLabel.props.children[i] === 'string' ||
        typeof entryLabel.props.children[i] === 'number'
      )
        text += String(entryLabel.props.children[i]);
      else {
        text += getLabelText(entryLabel?.props?.children?.[i]?.props?.children);
      }
    }
  }
  return text;
};

const getPlaceholderText = (props: IComboBoxProps) => {
  switch (props.type) {
    case 'single':
      return props.t('comboBox.selectOption');
    case 'multi':
      return props.t('comboBox.selectOneorMoreOpts');
    case 'createSingle':
      return props.t('comboBox.typeInOption');
    case 'createMulti':
      return props.t('comboBox.typeInOneorMoreOpts');
  }
};

const getInputPlaceholderText = (props: IComboBoxProps) => {
  switch (props.type) {
    case 'single':
    case 'multi':
      return props.t('comboBox.search');
    case 'createSingle':
    case 'createMulti':
      return props.t('comboBox.type');
  }
};

const ComboBox = (props: IComboBoxProps) => {
  const [valuesAvailableMap, setValuesAvailableMap] = useState(new Map());
  const [valuesSelected, setValuesSelected] = useState<Array<string | number>>(
    [],
  );
  const [showDropdown, setShowDropdown] = useState(false);
  const [filterText, setFilterText] = useState('');
  const placeholderText = useMemo(
    () => getPlaceholderText(props || 'single'),
    [props],
  );
  const inputPlaceholderText = useMemo(
    () => getInputPlaceholderText(props || 'single'),
    [props],
  );
  const [loading, setLoading] = useState(false);
  let optionsToDisplayLength = 0;
  const emptyArray = useMemo(() => [], []);
  const [stateVariablesInitialized, setStateVariablesInitialized] = useState({
    valuesAvailableMap: false,
    valuesSelected: false,
  });

  useEffect(() => {
    if (props.type !== 'createSingle' && props.type !== 'createMulti') {
      const map = new Map<
        string | number,
        { label: string | object; labelText: string; data?: any }
      >();
      for (let i = 0; i < props.valuesAvailable.length; i++) {
        const entry = props.valuesAvailable[i];
        const mapValue: {
          label: string | object;
          labelText: string;
          data?: any;
        } = {
          label: entry.label,
          labelText: getLabelText(entry.label),
        };
        if (entry.data) mapValue.data = entry.data;
        map.set(entry.value, mapValue);
      }
      setValuesAvailableMap(map);
    }
    setStateVariablesInitialized(prevState => ({
      ...prevState,
      valuesAvailableMap: true,
    }));
  }, [props.valuesAvailable]);

  useEffect(() => {
    if (props.value !== undefined) {
      if (
        (valuesSelected.length == 0 ||
          (props.type != 'multi' &&
            typeof props.value === ('string' || 'number') &&
            valuesSelected.length == 1 &&
            valuesSelected[0] != props.value) ||
          (props.type != 'multi' &&
            !(typeof props.value === ('string' || 'number')) &&
            valuesSelected.length == 1 &&
            valuesSelected[0] != (props.value as any).value)) &&
        ((Array.isArray(props.value) && props.value.length > 0) ||
          (!Array.isArray(props.value) && props.value))
      ) {
        const array = [];
        if (Array.isArray(props.value)) {
          for (let i = 0; i < props.value.length; i++) {
            const entry = props.value[i];
            if (typeof entry === 'string' || typeof entry === 'number')
              array.push(entry);
            else array.push(entry.value);
          }
        } else if (
          typeof props.value === 'string' ||
          typeof props.value === 'number'
        )
          array.push(props.value);
        else array.push(props.value.value);
        setValuesSelected(array);
      }
    }
    setStateVariablesInitialized(prevState => ({
      ...prevState,
      valuesSelected: true,
    }));
  }, [props.value]);

  useEffect(() => {
    if (
      stateVariablesInitialized.valuesAvailableMap &&
      stateVariablesInitialized.valuesSelected
    ) {
      let toReturn:
        | string
        | number
        | { value: string | number; data: any }
        | Array<string | number | { value: string | number; data: any }>
        | undefined;
      if (
        props.type === 'single' ||
        props.type === 'createSingle' ||
        props.type === undefined
      ) {
        if (valuesSelected[0] !== undefined) {
          if (valuesAvailableMap.get(valuesSelected[0])) {
            if (valuesAvailableMap.get(valuesSelected[0]).data)
              toReturn = {
                value: valuesSelected[0],
                data: valuesAvailableMap.get(valuesSelected[0]).data,
              };
            else toReturn = valuesSelected[0];
          } else toReturn = valuesSelected[0];
        }
      } else {
        toReturn = [];
        for (let i = 0; i < valuesSelected.length; i++) {
          if (valuesAvailableMap.get(valuesSelected[i])) {
            if (valuesAvailableMap.get(valuesSelected[i]).data)
              toReturn.push({
                value: valuesSelected[i],
                data: valuesAvailableMap.get(valuesSelected[i]).data,
              });
            else toReturn.push(valuesSelected[i]);
          } else toReturn.push(valuesSelected[i]);
        }
      }
      props.onChange({ [props.valueKey]: toReturn });
    }
  }, [valuesSelected]);

  useEffect(() => {
    if (
      (Array.isArray(props.value) && props.value.length == 0) ||
      (Array.isArray(props.value) != true && !props.value)
    ) {
      if (valuesSelected.length > 0) {
        setValuesSelected(emptyArray);
      }
    }
  }, [props.value]);

  useEffect(() => {
    if (props.onChangeShowDropdown) {
      props.onChangeShowDropdown(showDropdown);
    }
  }, [showDropdown]);

  const isSelected = (value: string | number): boolean => {
    for (let i = 0; i < valuesSelected.length; i++) {
      if (isStringOrNumber(valuesSelected[i])) {
        if (valuesSelected[i] === value) return true;
      } else if ((valuesSelected[i] as any)?.value === value) return true;
    }
    return false;
  };

  const getNotSelectedOptions = () => {
    const toReturn: Array<{
      value: string | number;
      label: string | number | ReactChild /* | object */;
      data?: any;
    }> = [];
    valuesAvailableMap.forEach(
      (
        value: {
          label: string | ReactChild /* | object */;
          labelText: string;
          data?: any;
        },
        key: string | number,
      ) => {
        if (
          isSelected(key) === false &&
          value.labelText.toLowerCase().includes(filterText.toLowerCase())
        ) {
          const option: {
            value: string | number;
            label: string | number | ReactChild /* | object */;
            data?: any;
          } = { value: key, label: value.label };
          if (value.data) option.data = value.data;
          toReturn.push(option);
        }
      },
    );
    optionsToDisplayLength = toReturn.length;
    return toReturn;
  };

  const selectOption = (
    key: string | number,
    event?: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    const type = props.type === undefined ? 'single' : props.type;
    if (valuesSelected.filter(value => value === key).length === 0) {
      switch (type) {
        case 'single':
        case 'createSingle': {
          setValuesSelected([key]);
          if (type === 'single') setShowDropdown(false);
          event?.stopPropagation();
          break;
        }
        case 'multi':
        case 'createMulti': {
          setValuesSelected(prevState => [...prevState, key]);
          event?.stopPropagation();
        }
      }
    }
  };

  const getOptionsSelected = () => {
    const toReturn = [];
    for (let i = 0; i < valuesSelected.length; i++) {
      let option: {
        value: string | number;
        label: string | number | object;
      };
      if (valuesAvailableMap.get(valuesSelected[i])) {
        option = {
          value: valuesSelected[i],
          label: valuesAvailableMap.get(valuesSelected[i]).label,
        };
      } else option = { value: valuesSelected[i], label: valuesSelected[i] };
      toReturn.push(option);
    }
    return toReturn;
  };

  const removeSelectedOption = (
    key: string | number,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    const selectedOptions = valuesSelected.filter(value => value !== key);
    event.stopPropagation();
    setValuesSelected(selectedOptions);
  };

  const enterKeyPressed = () => {
    const type = props.type === undefined ? 'single' : props.type;
    if (filterText !== '') {
      switch (type) {
        case 'single':
        case 'multi': {
          const option = getNotSelectedOptions()[0];
          if (option !== undefined) {
            selectOption(option.value);
            setFilterText('');
          }
          break;
        }
        case 'createSingle':
        case 'createMulti': {
          selectOption(filterText);
          setFilterText('');
        }
      }
    }
  };

  return (
    <ClickOutHandler onClickOut={() => setShowDropdown(false)}>
      <div>
        <Loader
          show={loading || (props.loading ? props.loading : false)}
          message={props.t('generic.loading')}
        >
          <S.ComboBoxContainer
            type={props.type || 'single'}
            display={props.display}
            width={props.width}
            height={props.height}
            disabled={props.disabled}
            marginTop={props.marginTop || '0px'}
            marginBottom={props.marginBottom || '0px'}
            marginLeft={props.marginLeft || '0px'}
            marginRight={props.marginRight || '0px'}
            paddingTop={props.paddingTop || '0px'}
            paddingBottom={props.paddingBottom || '0px'}
            paddingLeft={props.paddingLeft || '0px'}
            paddingRight={props.paddingRight || '0px'}
            borderRadius={props.borderRadius}
            themeStyles={props.themeStyles}
            menuOpen={
              showDropdown &&
              props.type !== 'createSingle' &&
              props.type !== 'createMulti'
            }
            headerAndOptionsSameColorWhenOpen={
              props.headerAndOptionsSameColorWhenOpen
            }
            noBorder={props.noBorder}
            onClick={() => setShowDropdown(prevState => !prevState)}
            changeBackgroundColorOnHover={props.changeBackgroundColorOnHover}
            growWithoutScroll={props.growWithoutScroll}
            changeBorderColorOnHover={props.changeContainerBorderColorOnHover}
          >
            <S.OptionsSelectedContainer
              type={props.type || 'single'}
              disabled={props.disabled}
            >
              <S.OptionsSelectedInnerContainer height={props.height}>
                {(valuesSelected.length === 0 ||
                  props.valuesAvailable.length == 0) && (
                  <S.PlaceholderContainer
                    disabled={props.disabled || false}
                    themeStyles={props.themeStyles}
                    fontSize={props.placeholderFontSize}
                    fontWeight={props.placeholderFontWeight}
                  >
                    {props.placeholder || placeholderText}
                  </S.PlaceholderContainer>
                )}

                {props.valuesAvailable.length > 0 &&
                  getOptionsSelected().map(option => (
                    <S.OptionSelectedOuterContainer
                      paddingTop={props.optionSelectedOuterContainerPaddingTop}
                      paddingBottom={
                        props.optionSelectedOuterContainerPaddingBottom
                      }
                      paddingLeft={
                        props.optionSelectedOuterContainerPaddingLeft
                      }
                      paddingRight={
                        props.optionSelectedOuterContainerPaddingRight
                      }
                      themeStyles={props.themeStyles}
                    >
                      <S.OptionSelectedContainer
                        type={props.type || 'single'}
                        paddingTop={props.optionSelectedContainerPaddingTop}
                        paddingBottom={
                          props.optionSelectedContainerPaddingBottom
                        }
                        paddingLeft={props.optionSelectedContainerPaddingLeft}
                        paddingRight={props.optionSelectedContainerPaddingRight}
                        borderRadius={props.optionSelectedContainerBorderRadius}
                        themeStyles={props.themeStyles}
                      >
                        {/* <> */}
                        <S.OptionSelectedInnerContainer>
                          {option.label || props.placeholder}
                          {(props.type === 'multi' ||
                            props.type === 'createMulti') && (
                            <S.OptionSelectedCrossContainer
                              onClick={event =>
                                removeSelectedOption(option.value, event)
                              }
                              marginLeft={props.optionSelectedCrossMarginLeft}
                              marginRight={props.optionSelectedCrossMarginRight}
                              themeStyles={props.themeStyles}
                            >
                              <S.OptionSelectedCrossInnerContainer>
                                {props.crossIconV2 ? (
                                  <CrossIcon_V2
                                    svgWidth="1.8rem"
                                    svgHeight="1.8rem"
                                    useCase="topicsOfInterestComboBox"
                                  />
                                ) : (
                                  <FaTimes />
                                )}
                              </S.OptionSelectedCrossInnerContainer>
                            </S.OptionSelectedCrossContainer>
                          )}
                        </S.OptionSelectedInnerContainer>
                        {/* </> */}
                      </S.OptionSelectedContainer>
                    </S.OptionSelectedOuterContainer>
                  ))}
              </S.OptionsSelectedInnerContainer>

              <S.IconsContainer>
                {props.isClearable !== false && valuesSelected.length > 0 && (
                  <S.CrossContainer
                    themeStyles={props.themeStyles}
                    disabled={props.disabled}
                  >
                    <FaTimes
                      onClick={event => {
                        event.stopPropagation();
                        setValuesSelected([]);
                      }}
                    />
                  </S.CrossContainer>
                )}

                {props.type !== 'createSingle' &&
                  props.type !== 'createMulti' && (
                    <S.ArrowContainer
                      themeStyles={props.themeStyles}
                      disabled={props.disabled}
                      fontSize={props.arrowFontSize}
                      className={
                        'comboBoxDropdownArrowContainerIdentifierClass'
                      }
                    >
                      {showDropdown === false ? (
                        <RiArrowDownSLine
                          size={props.arrowFontSize ?? '2.1rem'}
                        />
                      ) : (
                        <RiArrowUpSLine
                          size={props.arrowFontSize ?? '2.1rem'}
                        />
                      )}
                    </S.ArrowContainer>
                  )}
              </S.IconsContainer>
            </S.OptionsSelectedContainer>

            {showDropdown &&
              props.type !== 'createSingle' &&
              props.type !== 'createMulti' && (
                <S.OptionsContainer
                  borderRadius={props.availableOptionsContainerBorderRadius}
                  noBorder={props.noBorder}
                  themeStyles={props.themeStyles}
                >
                  {props.isFilterable !== false && (
                    <S.FilterInputContainer
                      themeStyles={props.themeStyles}
                      borderTopLeftRadius={
                        props.filterInputOuterContainerBorderTopLeftRadius
                      }
                      borderTopRightRadius={
                        props.filterInputOuterContainerBorderTopRightRadius
                      }
                      onClick={(event: any) => {
                        event.preventDefault();
                        event.stopPropagation();
                      }}
                    >
                      <S.FilterInput
                        value={filterText}
                        placeholder={props.t('comboBox.search')}
                        onChange={event => setFilterText(event.target.value)}
                        autoFocus
                        onKeyPress={event =>
                          event.key === 'Enter' && enterKeyPressed()
                        }
                        themeStyles={props.themeStyles}
                        borderTopLeftRadius={
                          props.filterInputBorderTopLeftRadius
                        }
                        borderTopRightRadius={
                          props.filterInputBorderTopRightRadius
                        }
                        borderBottomLeftRadius={
                          props.filterInputBorderBottomLeftRadius
                        }
                        borderBottomRightRadius={
                          props.filterInputBorderBottomRightRadius
                        }
                      />
                      <S.MagnifyingGlassContainer>
                        <MagnifyingGlassIcon
                          svgWidth="1.7rem"
                          svgHeight="1.7rem"
                          containerMarginLeft="0.5rem"
                        />
                      </S.MagnifyingGlassContainer>
                    </S.FilterInputContainer>
                  )}

                  <S.OptionsInnerContainer
                    borderRadius={props.availableOptionsContainerBorderRadius}
                    paddingTop={props.innerOptionsContainerPaddingTop}
                    paddingBottom={props.innerOptionsContainerPaddingBottom}
                    paddingLeft={props.innerOptionsContainerPaddingLeft}
                    paddingRight={props.innerOptionsContainerPaddingRight}
                    marginTop={props.innerOptionsContainerMarginTop}
                    marginBottom={props.innerOptionsContainerMarginBottom}
                    marginLeft={props.innerOptionsContainerMarginLeft}
                    marginRight={props.innerOptionsContainerMarginRight}
                    maxHeight={props.innerOptionsContainerMaxHeight}
                    textAlign={props.innerOptionsContainerTextAlign}
                    themeStyles={props.themeStyles}
                  >
                    {getNotSelectedOptions().map((option, index) => (
                      <S.OptionContainer
                        first={props.firstOptionSpecialTreatment && index == 0}
                        last={
                          props.lastOptionSpecialTreatment &&
                          index == getNotSelectedOptions().length - 1
                        }
                        borderRadius={props.optionContainerBorderRadius}
                        paddingTop={props.optionContainerPaddingTop}
                        paddingBottom={props.optionContainerPaddingBottom}
                        paddingLeft={props.optionContainerPaddingLeft}
                        paddingRight={props.optionContainerPaddingRight}
                        preventOptionSelection={props.preventOptionSelection}
                        themeStyles={props.themeStyles}
                        onClick={event => {
                          if (!props.preventOptionSelection) {
                            selectOption(option.value, event);
                          } else {
                            event.stopPropagation();
                          }
                        }}
                      >
                        {option.label}
                      </S.OptionContainer>
                    ))}
                    {!(optionsToDisplayLength > 0) && (
                      <S.EmptyOptionsContainer>
                        {props.t('comboBox.noOptionsAvailable')}
                      </S.EmptyOptionsContainer>
                    )}
                  </S.OptionsInnerContainer>
                </S.OptionsContainer>
              )}

            {(props.type === 'createSingle' ||
              props.type === 'createMulti') && (
              <S.FilterInputContainerCreate>
                <S.FilterInput
                  value={filterText}
                  placeholder={inputPlaceholderText}
                  autoFocus
                  onChange={event => setFilterText(event.target.value)}
                  onKeyPress={event =>
                    event.key === 'Enter' && enterKeyPressed()
                  }
                />
                <S.MagnifyingGlassContainer>
                  <GiMagnifyingGlass size={'1.2rem'} />
                </S.MagnifyingGlassContainer>
              </S.FilterInputContainerCreate>
            )}
          </S.ComboBoxContainer>
        </Loader>
      </div>
    </ClickOutHandler>
  );
};

export default withTranslation()(ComboBox);
