/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable import/extensions */
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useState, useEffect, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { withTranslation } from 'react-i18next';

import * as S from './Styles';
import GenericModal from '../../components/shared/GenericModal/GenericModal';
import StyledButton from '../../components/shared/Button/Button';
import { getThemeStyles } from '../../css/ThemeGlobal';
import Loader from '../../components/shared/LoaderFixed/LoaderFixed';
import { valueEmpty } from '../../validations/validationFunctions';
import { ListProps } from './types';
import NotifyCustom from '../../components/shared/NotifyCustom/NotifyCustom';
import CheckMarkIcon from '../../assets/iconComponents/CheckMarkIcon';
import BackArrowIcon from '../../assets/iconComponents/BackArrowIcon';
import InputControlV2 from '../../components/shared/InputControlV2/InputControlV2';
import InputSpinnerIcon from '../../assets/iconComponents/InputSpinnerIcon/InputSpinnerIcon';
import { useIsOperatorOrPartnerAdmin } from '../SignIn/RQCustomHooks';
import NoPermissionToAccessPageWarning from '../../components/shared/NoPermissionToAccessPageWarning/NoPermissionToAccessPageWarning';
import {
  useCreateList,
  useGetListById,
  useUpdateListById,
} from './RQCustomHooks';
import { ListTypes } from '../../Services/SkorrApi/new/User/types';
import { SocialMediaActivityRelatedLineAwesomeIconClasses } from '../../Services/Utils/types';
import EmptyPictureIcon from '../../assets/iconComponents/EmptyPictureIcon';

const PublicUserList = (props: ListProps) => {
  const [name, setName] = useState('');
  const [isNameInputFocused, setIsNameInputFocused] = useState<boolean>(false);
  const [hasNameBeenChanged, setHasNameBeenChanged] = useState<boolean>(false);
  const [forceNameErrorDisplay, setForceNameErrorDisplay] = useState<
    string | undefined
  >();
  const [description, setDescription] = useState('');
  const [icon, setIcon] = useState('');
  const [isGetListEnabled, setIsGetListEnabled] = useState<boolean>(false);
  const [isOperatorOrPartnerAdminEnabled, setIsOperatorOrPartnerAdminEnabled] =
    useState(true);
  const [hasSaveButtonBeenClicked, setHasSaveButtonBeenClicked] =
    useState<boolean>(false);
  const [hasError, setHasError] = useState(false);
  const [msgError, setMsgError] = useState('');
  const { id: listId } = useParams();
  const {
    isLoading: isLoadingCreateList,
    isSuccess: isSuccessCreateList,
    isError: isErrorCreateList,
    mutate: createList,
  } = useCreateList();
  const {
    isLoading: isLoadingUpdateList,
    isSuccess: isSuccessUpdateList,
    isError: isErrorUpdateList,
    mutate: updateList,
  } = useUpdateListById();
  const {
    data: isOperatorOrPartnerAdmin,
    isFetching: isFetchingIsOperatorOrPartnerAdmin,
    isSuccess: isSuccessIsOperatorOrPartnerAdmin,
    isError: isErrorIsOperatorOrPartnerAdmin,
  } = useIsOperatorOrPartnerAdmin(isOperatorOrPartnerAdminEnabled);
  const noPermissionToAccessPage = useMemo(() => {
    if (
      isSuccessIsOperatorOrPartnerAdmin &&
      !isFetchingIsOperatorOrPartnerAdmin &&
      !isOperatorOrPartnerAdmin
    )
      return true;
    return false;
  }, [
    isSuccessIsOperatorOrPartnerAdmin,
    isFetchingIsOperatorOrPartnerAdmin,
    isOperatorOrPartnerAdmin,
  ]);
  const {
    data: list,
    isFetching: isFetchinigGetListById,
    isSuccess: isSuccessGetListById,
    isError: isErrorGetListById,
  } = useGetListById({ enabled: isGetListEnabled, id: listId ?? '' });
  const [
    showSuccessfulActionNotification,
    setShowSuccessfulActionNotification,
  ] = useState<boolean>(false);
  const [
    successfulActionNotificationMessage,
    setSuccessfulActionNotificationMessage,
  ] = useState('');
  const [
    loadingActionNotificationMessage,
    setLoadingActionNotificationMessage,
  ] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const themeGlobal = useTheme();

  useEffect(() => {
    if (listId) {
      setIsGetListEnabled(true);
    }
  }, [listId]);

  useEffect(() => {
    if (isSuccessGetListById && listId && list) {
      setName(list.name);
      setDescription(list.description ?? '');
      setIcon(list.icon ?? '');
    }
  }, [isSuccessGetListById, listId, list]);

  useEffect(() => {
    if (isNameInputFocused) {
      setForceNameErrorDisplay('');
    }
  }, [isNameInputFocused]);

  useEffect(() => {
    if (isSuccessCreateList) {
      setSuccessfulActionNotificationMessage(props.t('lists.listCreated'));
      setShowSuccessfulActionNotification(true);
      setTimeout(() => {
        setShowSuccessfulActionNotification(false);
        navigate(`/lists`);
      }, 2000);
    }
  }, [isSuccessCreateList]);

  useEffect(() => {
    if (isSuccessUpdateList) {
      setSuccessfulActionNotificationMessage(props.t('lists.listUpdated'));
      setShowSuccessfulActionNotification(true);
      setTimeout(() => {
        setShowSuccessfulActionNotification(false);
      }, 2000);
      setTimeout(() => navigate('/lists'), 2000);
    }
  }, [isSuccessUpdateList]);

  useEffect(() => {
    if (isErrorGetListById) {
      setHasError(true);
      setMsgError(props.t('generic.couldNotGetData'));
      setIsGetListEnabled(false);
    }

    if (isErrorCreateList) {
      setHasError(true);
      setMsgError(props.t('lists.couldNotCreateList'));
    }

    if (isErrorUpdateList) {
      setHasError(true);
      setMsgError(props.t('lists.couldNotUpdateList'));
    }

    if (isErrorIsOperatorOrPartnerAdmin) {
      setHasError(true);
      setMsgError(props.t('generic.couldNotGetData'));
      setIsOperatorOrPartnerAdminEnabled(false);
    }
  }, [
    isErrorGetListById,
    isErrorCreateList,
    isErrorUpdateList,
    isErrorIsOperatorOrPartnerAdmin,
  ]);

  const saveButtonClicked = () => {
    setLoadingActionNotificationMessage(
      !listId ? props.t('lists.creatingList') : props.t('lists.savingChanges'),
    );

    if (!isSaveButtonEnabled()) {
      setForceNameErrorDisplay(props.t('lists.listNameIsRequired'));
    } else if (listId) {
      updateList({ id: listId, name, description, icon });
    } else {
      createList({
        name,
        description,
        icon,
        type: ListTypes.PUBLIC,
      });
    }

    setHasSaveButtonBeenClicked(true);
  };

  const isSaveButtonEnabled = () => {
    if (!valueEmpty(name)) return true;
    return false;
  };

  const errorModalCloseButtonClicked = () => {
    if (isErrorGetListById) {
      setIsGetListEnabled(true);
    }
    if (isErrorIsOperatorOrPartnerAdmin) {
      setIsOperatorOrPartnerAdminEnabled(true);
    }
    setHasError(false);
  };

  const getPageTitle = () => {
    if (location.pathname.includes('list/edit')) {
      return props.t('lists.editList');
    }

    return props.t('lists.createList');
  };

  if (noPermissionToAccessPage) {
    return (
      <S.OuterContainer>
        <S.Container>
          <S.InnerContainer>
            <S.Card>
              <NoPermissionToAccessPageWarning containerHeight="auto" />
            </S.Card>
          </S.InnerContainer>
        </S.Container>
      </S.OuterContainer>
    );
  }

  return (
    <S.OuterContainer>
      <Loader
        show={isFetchinigGetListById || isFetchingIsOperatorOrPartnerAdmin}
        message={props.t('generic.gettingData')}
      />

      <S.Container>
        <S.InnerContainer>
          <S.Card>
            <S.HeaderOuterContainer>
              <S.HeaderContainer>
                <S.HeaderBackArrowContainer
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <BackArrowIcon width={'4rem'} height={'4rem'} />
                </S.HeaderBackArrowContainer>
                <S.HeaderTitleContainer>
                  {getPageTitle()}
                </S.HeaderTitleContainer>
                <S.HiddenHeaderElement />
              </S.HeaderContainer>
              <S.HeaderDivider />
            </S.HeaderOuterContainer>

            <S.CardInnerContainer>
              <S.ListDetailsContainer>
                <S.ListDetailsInnerContainer>
                  <S.ListDetailsInnerFirstRowContainer>
                    <S.ListNameAndDescriptionContainer>
                      <S.ListNameAndDescriptionInnerContainer>
                        <InputControlV2
                          value={name}
                          onChange={(inputValue: string) => {
                            setName(inputValue);
                            setHasNameBeenChanged(true);
                          }}
                          title={props.t('generic.name')}
                          inputFieldContainerWithTitleWidth={'100%'}
                          placeholder={props.t('lists.insertListName')}
                          setFocused={focused => setIsNameInputFocused(focused)}
                          setBlurred={blurred => {
                            setIsNameInputFocused(!blurred);
                            if (
                              !name.length &&
                              (hasNameBeenChanged || hasSaveButtonBeenClicked)
                            ) {
                              setForceNameErrorDisplay(
                                props.t('lists.listNameIsRequired'),
                              );
                            }
                          }}
                          inputTitleMarginBottom={'1.2rem'}
                          forceErrorDisplay={forceNameErrorDisplay}
                          forceBorderColorRed={Boolean(forceNameErrorDisplay)}
                        />

                        <InputControlV2
                          value={description}
                          onChange={(inputValue: string) =>
                            setDescription(inputValue)
                          }
                          title={props.t('generic.description')}
                          inputFieldContainerWithTitleWidth={'100%'}
                          placeholder={props.t('lists.insertListDescription')}
                          type={'textarea'}
                          inputFieldWidth={'100%'}
                          inputFieldHeight={'15rem'}
                          inputFieldLineHeight={'2.5rem'}
                          inputTitleMarginBottom={'1.2rem'}
                          inputFieldContainerMarginBottom={'2.7rem'}
                        />
                      </S.ListNameAndDescriptionInnerContainer>
                    </S.ListNameAndDescriptionContainer>

                    <S.ListIconPickerOuterContainer>
                      <S.ListIconPickerTitle>
                        {props.t('generic.icon')}
                      </S.ListIconPickerTitle>

                      <S.ListIconPickerInnerContainer>
                        <S.ListIconContainer>
                          {icon ? (
                            <S.ListIcon className={icon} />
                          ) : (
                            <S.EmptyListIconContainer>
                              <EmptyPictureIcon
                                width={'5.8rem'}
                                height={'5.8rem'}
                              />
                            </S.EmptyListIconContainer>
                          )}
                        </S.ListIconContainer>

                        <S.ListIconsToPickOuterContainer>
                          <S.ListIconsToPickContainer>
                            {SocialMediaActivityRelatedLineAwesomeIconClasses.map(
                              iconClass => (
                                <S.ListIconToPick
                                  className={iconClass}
                                  selected={icon === iconClass}
                                  onClick={() => setIcon(iconClass)}
                                />
                              ),
                            )}
                          </S.ListIconsToPickContainer>
                        </S.ListIconsToPickOuterContainer>
                      </S.ListIconPickerInnerContainer>
                    </S.ListIconPickerOuterContainer>
                  </S.ListDetailsInnerFirstRowContainer>
                </S.ListDetailsInnerContainer>
              </S.ListDetailsContainer>
            </S.CardInnerContainer>

            {isSuccessIsOperatorOrPartnerAdmin && isOperatorOrPartnerAdmin && (
              <S.Footer>
                <StyledButton
                  themeStyles={getThemeStyles(
                    ['button', 'secondary-1'],
                    themeGlobal,
                  )}
                  onClick={() => navigate('/lists')}
                >
                  {props.t('generic.cancel')}
                </StyledButton>

                <StyledButton
                  onClick={() => saveButtonClicked()}
                  cursorOnDisable={'default'}
                  blockHoverStylesOnDisable={true}
                  pointerEventsOnDisable={'all'}
                  disabledNotNative={!isSaveButtonEnabled()}
                >
                  {listId ? props.t('generic.save') : props.t('generic.create')}
                </StyledButton>
              </S.Footer>
            )}
          </S.Card>
        </S.InnerContainer>
      </S.Container>

      <GenericModal
        show={hasError}
        title={props.t('generic.uhoh')}
        body={msgError}
        primaryButtonText={props.t('generic.close')}
        primaryButtonAction={() => {
          errorModalCloseButtonClicked();
        }}
      />

      <NotifyCustom
        location={'top'}
        show={isLoadingCreateList || isLoadingUpdateList}
        themeStyles={(themeGlobal as any).signin['regular-notify']}
      >
        <S.NotifyInnerContainer>
          <InputSpinnerIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
          />
          {loadingActionNotificationMessage}
        </S.NotifyInnerContainer>
      </NotifyCustom>

      <NotifyCustom
        location={'top'}
        show={showSuccessfulActionNotification}
        themeStyles={(themeGlobal as any).signin['success-notify']}
        topInitialOffset={'5rem'}
      >
        <S.NotifyInnerContainer>
          <CheckMarkIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
            useCase={'success-notify'}
          />
          {successfulActionNotificationMessage}
        </S.NotifyInnerContainer>
      </NotifyCustom>
    </S.OuterContainer>
  );
};

export default withTranslation()(PublicUserList);
