import { useMutation, useQuery } from 'react-query';
import SkorrApi from 'Services/SkorrApi/new';
import {
  AddUsersToListRequest,
  GetPublicUsersByFilterRequest,
  ListTypes,
} from 'Services/SkorrApi/new/User/types';
import { IErrorObject } from 'Services/Utils/types';
import { isAuthenticated, logApiRequestError } from 'Services/Utils/Utils';

import { UseGetListsByPartnerAndBusinessProfileInput } from './types';

const { user } = SkorrApi;

export const useGetPublicUsersByFilter = () => {
  return useMutation(
    async (params: GetPublicUsersByFilterRequest) => {
      try {
        const response = await user.getPublicUsersByFilter(params);
        return response;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
    },
  );
};

export const useGetListsByPartnerAndBusinessProfile = ({
  type,
  memberCount,
  page,
  limit,
}: UseGetListsByPartnerAndBusinessProfileInput) => {
  return useQuery(
    [
      'get-lists-by-partner-and-business-profile',
      isAuthenticated()?.partnerId,
      isAuthenticated()?.businessProfileId,
    ],
    async () => {
      try {
        const data = await user.getListsByPartnerAndBusinessProfile({
          type: type ?? ListTypes.PUBLIC,
          memberCount: memberCount ?? false,
          page: page ?? 0,
          limit: limit ?? 50,
        });

        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      refetchOnWindowFocus: true,
      staleTime: 0,
      retry: false,
    },
  );
};

export const useAddUsersToList = () => {
  return useMutation(
    async (params: AddUsersToListRequest) => {
      try {
        const response = await user.addUsersToList(params);
        return response;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
    },
  );
};
