import styled from 'styled-components';

export const ToolBarFilterButton = styled.div`
  height: 4rem;
  display: flex;
  color: #676767;
  padding: 1.2rem;
  font-weight: 400;
  line-height: 1.8;
  font-size: 1.4rem;
  margin-right: 2rem;
  align-items: center;
  border-radius: 0.6rem;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
  }

  &:hover {
    background: #f7f8fa;
  }

  &:active {
    background: #eff1f6;
  }
`;

export const ToolBarFilterSideBarBackground = styled.div<{ active: boolean }>`
  top: 11.4rem;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  width: 100%;
  display: flex;
  position: absolute;
  transition: all 0.08s;
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0.5);
  opacity: ${({ active }) => (active ? '1' : '0')};
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
`;

export const ToolBarFilterSideBarContainer = styled.div`
  width: 35rem;
  height: 100%;
  padding: 2rem;
  display: flex;
  background: #fff;
  overflow-y: scroll;
  flex-direction: column;
`;

export const ToolBarFilterSideBarFooterContainer = styled.div`
  right: 0;
  bottom: 0;
  gap: 1rem;
  width: 35rem;
  height: 8rem;
  padding: 2rem;
  display: flex;
  position: fixed;
  overflow-y: scroll;
  align-items: center;
  background-color: #fff;
`;

export const ToolBarFilterSideBarFooterButton = styled.div<{
  secondary?: boolean;
}>`
  flex: 1;
  height: 4rem;
  display: flex;
  align-items: center;
  border-radius: 0.6rem;
  justify-content: center;
  background-color: ${({ secondary }) => (secondary ? '#fff' : '#5870f6')};
  cursor: pointer;
`;

export const ToolBarFilterSideBarFooterButtonText = styled.div<{
  secondary?: boolean;
}>`
  color: ${({ secondary }) => (secondary ? '#676767' : '#fff')};
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.85;
`;

export const ToolBarFilterSideBarDivider = styled.div`
  width: 100%;
  opacity: 0.5;
  margin-top: 3rem;
  padding: 0.05rem 0;
  margin-bottom: 1rem;
  background: #dbdbdb;
`;
