/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/extensions */
import styled, { css } from 'styled-components';

import {
  IArrowContainerProps,
  IComboBoxContainerProps,
  ICrossContainerProps,
  IFilterInputContainerProps,
  IFilterInputProps,
  IOptionContainerProps,
  IOptionsContainerProps,
  IOptionSelectedContainerProps,
  IOptionSelectedCrossContainerProps,
  IOptionSelectedOuterContainerProps,
  IPlaceholderContainerProps,
} from './types';

export const MagnifyingGlassContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  padding-right: 0.5rem;
`;

interface EmptyOptionsContainerProps {}

export const EmptyOptionsContainer = styled.div<EmptyOptionsContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: ${props => props.theme.comboBox['warning-font-size']};
  font-weight: ${props => props.theme.comboBox['warning-font-weight']};
  font-family: ${props => props.theme.comboBox['warning-font-family']};
  color: ${props => props.theme.comboBox['warning-color']};
`;

export const PlaceholderContainer = styled.span<IPlaceholderContainerProps>`
  padding-left: 0.7rem;
  user-select: none;
  font-size: ${props => {
    if (props.fontSize) return props.fontSize;
    if (
      props.themeStyles &&
      props.themeStyles['options-selected-placeholder-font-size']
    ) {
      return props.themeStyles['options-selected-placeholder-font-size'];
    }
    return props.theme.comboBox['options-selected-placeholder-font-size'];
  }};
  font-weight: ${props => {
    if (
      props.themeStyles &&
      props.themeStyles['options-selected-placeholder-font-weight']
    ) {
      return props.themeStyles['options-selected-placeholder-font-weight'];
    }
    return props.theme.comboBox['options-selected-placeholder-font-weight'];
  }};
  font-family: ${props =>
    props.theme.comboBox['options-selected-placeholder-font-family']};
  color: ${props => {
    if (props.disabled) {
      if (
        props.themeStyles &&
        props.themeStyles['disabled-options-selected-placeholder-color']
      ) {
        return props.themeStyles['disabled-options-selected-placeholder-color'];
      }
      return props.theme.comboBox[
        'disabled-options-selected-placeholder-color'
      ];
    }
    if (
      props.themeStyles &&
      props.themeStyles['options-selected-placeholder-color']
    ) {
      return props.themeStyles['options-selected-placeholder-color'];
    }
    return props.theme.comboBox['options-selected-placeholder-color'];
  }};
`;

export const FilterInputContainer = styled.div<IFilterInputContainerProps>`
  display: grid;
  grid-template-columns: 1fr auto;
  border-bottom: ${props =>
    `${props.theme.comboBox['border-width']} ${props.theme.comboBox['border-style']} ${props.theme.comboBox['border-color']}`};
  background-color: 'inherit';
  border-top-left-radius: ${props => {
    if (props.borderTopLeftRadius) return props.borderTopLeftRadius;
    if (props.themeStyles) {
      if (
        props.themeStyles['filter-input-outer-container-border-top-left-radius']
      ) {
        return props.themeStyles[
          'filter-input-outer-container-border-top-left-radius'
        ];
      }
      if (props.themeStyles['options-container-border-radius']) {
        return props.themeStyles['options-container-border-radius'];
      }
    }
    return props.theme.comboBox['options-container-border-radius'];
  }};
  border-top-right-radius: ${props => {
    if (props.borderTopRightRadius) return props.borderTopRightRadius;
    if (props.themeStyles) {
      if (
        props.themeStyles[
          'filter-input-outer-container-border-top-right-radius'
        ]
      ) {
        return props.themeStyles[
          'filter-input-outer-container-border-top-right-radius'
        ];
      }
      if (props.themeStyles['options-container-border-radius']) {
        return props.themeStyles['options-container-border-radius'];
      }
    }
    return props.theme.comboBox['options-container-border-radius'];
  }};
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

export const FilterInputContainerCreate = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  position: absolute;
  top: calc(100% + 0.7rem);
  width: 100%;
  background-color: 'inherit';
  border-radius: ${props =>
    props.theme.comboBox['creatable-mode-input-border-radius']};
  border: ${props =>
    `${props.theme.comboBox['border-width']} ${props.theme.comboBox['border-style']} ${props.theme.comboBox['border-color']}`};
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-right: 0.5rem;
`;

export const FilterInput = styled.input<IFilterInputProps>`
    font-size: ${props => props.theme.comboBox['input-filter-font-size']};
    font-weight: ${props => props.theme.comboBox['input-filter-font-weight']};
    font-family: ${props => props.theme.comboBox['input-filter-font-family']};
    color: ${props => props.theme.comboBox['input-filter-color']};
    width: 100%;
    height: 3.2rem;
    border: none;
    outline: 0;
    padding-left: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: 'inherit';
    border-top-left-radius: ${props => {
      if (props.borderTopLeftRadius) return props.borderTopLeftRadius;
      if (props.themeStyles) {
        if (props.themeStyles['filter-input--border-top-left-radius']) {
          return props.themeStyles['filter-input-border-top-left-radius'];
        }
        if (props.themeStyles['options-container-border-radius']) {
          return props.themeStyles['options-container-border-radius'];
        }
      }
      return props.theme.comboBox['options-container-border-radius'];
    }};
    border-top-right-radius: ${props => {
      if (props.borderTopRightRadius) return props.borderTopRightRadius;
      if (props.themeStyles) {
        if (props.themeStyles['filter-input-border-top-right-radius']) {
          return props.themeStyles['filter-input-border-top-right-radius'];
        }
        if (props.themeStyles['options-container-border-radius']) {
          return props.themeStyles['options-container-border-radius'];
        }
      }
      return props.theme.comboBox['options-container-border-radius'];
    }};
    border-bottom-left-radius: ${props => {
      if (props.borderBottomLeftRadius) return props.borderBottomLeftRadius;
      if (props.themeStyles) {
        if (props.themeStyles['filter-input-border-bottom-left-radius']) {
          return props.themeStyles['filter-input-border-bottom-left-radius'];
        }
        if (props.themeStyles['options-container-border-radius']) {
          return props.themeStyles['options-container-border-radius'];
        }
      }
      return props.theme.comboBox['options-container-border-radius'];
    }};
    border-bottom-right-radius: ${props => {
      if (props.borderBottomRightRadius) return props.borderBottomRightRadius;
      if (props.themeStyles) {
        if (props.themeStyles['filter-input-border-bottom-right-radius']) {
          return props.themeStyles['filter-input-border-bottom-right-radius'];
        }
        if (props.themeStyles['options-container-border-radius']) {
          return props.themeStyles['options-container-border-radius'];
        }
      }
      return props.theme.comboBox['options-container-border-radius'];
    }};

    &::placeholder {
        font-size: ${props => props.theme.comboBox['input-filter-font-size']};
        font-weight: ${props =>
          props.theme.comboBox['input-filter-font-weight']};
        font-family: ${props => props.theme.comboBox['font-family']};
        color: ${props => props.theme.comboBox['input-filter-color']};
    };
}
`;

export const OptionSelectedOuterContainer = styled.div<IOptionSelectedOuterContainerProps>`
  display: inline-flex;
  align-items: center;
  padding-top: ${props => {
    if (props.paddingTop) return props.paddingTop;
    if (
      props.themeStyles &&
      props.themeStyles['option-selected-outer-container-padding-top']
    ) {
      return props.themeStyles['option-selected-outer-container-padding-top'];
    }
    return '0.5rem';
  }};
  padding-bottom: ${props => {
    if (props.paddingBottom) return props.paddingBottom;
    if (
      props.themeStyles &&
      props.themeStyles['option-selected-outer-container-padding-bottom']
    ) {
      return props.themeStyles[
        'option-selected-outer-container-padding-bottom'
      ];
    }
    return '0.5rem';
  }};
  padding-left: ${props => {
    if (props.paddingLeft) return props.paddingLeft;
    if (
      props.themeStyles &&
      props.themeStyles['option-selected-outer-container-padding-left']
    ) {
      return props.themeStyles['option-selected-outer-container-padding-left'];
    }
    return '0.5rem';
  }};
  padding-right: ${props => {
    if (props.paddingRight) return props.paddingRight;
    if (
      props.themeStyles &&
      props.themeStyles['option-selected-outer-container-padding-right']
    ) {
      return props.themeStyles['option-selected-outer-container-padding-right'];
    }
    return '0.5rem';
  }};
`;

// -webkit-line-clamp: 1;
// -webkit-box-orient: vertical;
// display: -webkit-box;
export const OptionSelectedContainer = styled.div<IOptionSelectedContainerProps>`
  font-size: ${props => {
    if (props.themeStyles && props.themeStyles['option-selected-font-size']) {
      return props.themeStyles['option-selected-font-size'];
    }
    return props.theme.comboBox['option-selected-font-size'];
  }};
  font-weight: ${props => {
    if (props.themeStyles && props.themeStyles['option-selected-font-weight']) {
      return props.themeStyles['option-selected-font-weight'];
    }
    return props.theme.comboBox['option-selected-font-weight'];
  }};
  color: ${props => {
    if (props.themeStyles && props.themeStyles['option-selected-color']) {
      return props.themeStyles['option-selected-color'];
    }
    return props.theme.comboBox['option-selected-color'];
  }};
  background-color: ${props =>
    props.type !== 'single' && props.type !== 'createSingle'
      ? props.themeStyles &&
        props.themeStyles['option-selected-background-color']
        ? props.themeStyles['option-selected-background-color']
        : props.theme.comboBox['option-selected-background-color']
      : 'none'};
  width: ${props =>
    props.type === 'single' || props.type === 'createSingle' ? '100%' : 'auto'};
  padding-top: ${props => {
    if (props.type !== 'single' && props.type !== 'createSingle') {
      if (props.paddingTop) return props.paddingTop;
      if (
        props.themeStyles &&
        props.themeStyles['option-selected-container-padding-top']
      ) {
        return props.themeStyles['option-selected-container-padding-top'];
      }
      return '0.3rem';
    }
    return '0rem';
  }};
  padding-bottom: ${props => {
    if (props.type !== 'single' && props.type !== 'createSingle') {
      if (props.paddingBottom) return props.paddingBottom;
      if (
        props.themeStyles &&
        props.themeStyles['option-selected-container-padding-bottom']
      ) {
        return props.themeStyles['option-selected-container-padding-bottom'];
      }
      return '0.3rem';
    }
    return '0rem';
  }};
  padding-left: ${props => {
    if (props.paddingLeft) return props.paddingLeft;
    if (
      props.themeStyles &&
      props.themeStyles['option-selected-container-padding-left']
    ) {
      return props.themeStyles['option-selected-container-padding-left'];
    }
    return '0.8rem';
  }};
  padding-right: ${props => {
    if (props.paddingRight) return props.paddingRight;
    if (
      props.themeStyles &&
      props.themeStyles['option-selected-container-padding-right']
    ) {
      return props.themeStyles['option-selected-container-padding-right'];
    }
    return '0.8rem';
  }};
  border-radius: ${props => {
    if (props.borderRadius) return props.borderRadius;
    if (
      props.themeStyles &&
      props.themeStyles['option-selected-container-border-radius']
    ) {
      return props.themeStyles['option-selected-container-border-radius'];
    }
    return '2rem';
  }};
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

export const OptionSelectedInnerContainer = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const OptionSelectedCrossContainer = styled.div<IOptionSelectedCrossContainerProps>`
  display: inline-flex;
  align-items: center;
  margin-left: ${props => {
    if (props.marginLeft) return props.marginLeft;
    if (
      props.themeStyles &&
      props.themeStyles['option-selected-cross-margin-left']
    ) {
      return props.themeStyles['option-selected-cross-margin-left'];
    }
    return '0.7rem';
  }};
  margin-right: ${props => {
    if (props.marginRight) return props.marginRight;
    if (
      props.themeStyles &&
      props.themeStyles['option-selected-cross-margin-right']
    ) {
      return props.themeStyles['option-selected-cross-margin-right'];
    }
    return '0rem';
  }};
  height: 100%;
  position: static;
  padding: 0;
  font-size: ${props =>
    props.theme.comboBox['option-selected-cross-font-size']};
  &:hover {
    background-color: ${props => {
      if (
        props.themeStyles &&
        props.themeStyles[
          'option-selected-cross-container-background-color-hover'
        ]
      ) {
        return props.themeStyles[
          'option-selected-cross-container-background-color-hover'
        ];
      }
      return props.theme.comboBox[
        'option-selected-cross-container-background-color-hover'
      ];
    }};
  }
  &:hover > * > * {
    color: ${props =>
      props.theme.comboBox['option-selected-cross-color-hover']};
  }
  & > * {
    cursor: pointer;
  }
`;

export const OptionSelectedCrossInnerContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const OptionContainer = styled.div<IOptionContainerProps>`
  font-size: ${props => {
    if (props.themeStyles && props.themeStyles['option-container-font-size']) {
      return props.themeStyles['option-container-font-size'];
    }
    return props.theme.comboBox['option-container-font-size'];
  }};
  font-weight: ${props => {
    if (
      props.themeStyles &&
      props.themeStyles['option-container-font-weight']
    ) {
      return props.themeStyles['option-container-font-weight'];
    }
    return props.theme.comboBox['option-container-font-weight'];
  }};
  font-family: ${props => props.theme.comboBox['font-family']};
  color: ${props => {
    if (props.themeStyles && props.themeStyles['option-container-color']) {
      return props.themeStyles['option-container-color'];
    }
    return props.theme.comboBox['option-container-color'];
  }};
  display: block;
  cursor: ${props =>
    props.preventOptionSelection ? 'not-allowed' : 'pointer'};
  padding-top: ${props => {
    if (props.paddingTop) return props.paddingTop;
    if (
      props.themeStyles &&
      props.themeStyles['option-container-padding-top']
    ) {
      return props.themeStyles['option-container-padding-top'];
    }
    return '0.7rem';
  }};
  padding-bottom: ${props => {
    if (props.paddingBottom) return props.paddingBottom;
    if (
      props.themeStyles &&
      props.themeStyles['option-container-padding-bottom']
    ) {
      return props.themeStyles['option-container-padding-bottom'];
    }
    return '0.7rem';
  }};
  padding-left: ${props => {
    if (props.paddingLeft) return props.paddingLeft;
    if (
      props.themeStyles &&
      props.themeStyles['option-container-padding-left']
    ) {
      return props.themeStyles['option-container-padding-left'];
    }
    return '1.5rem';
  }};
  padding-right: ${props => {
    if (props.paddingRight) return props.paddingRight;
    if (
      props.themeStyles &&
      props.themeStyles['option-container-padding-right']
    ) {
      return props.themeStyles['option-container-padding-right'];
    }
    return '1.5rem';
  }};
  user-select: none;
  background-color: ${props => {
    if (
      props.themeStyles &&
      props.themeStyles['option-container-background-color']
    ) {
      return props.themeStyles['option-container-background-color'];
    }
    return props.theme.comboBox['option-container-background-color'];
  }};
  ${props => {
    if (props.first) {
      return css`
        border-top-left-radius: ${props.borderRadius ??
        props.theme.comboBox['option-container-border-radius']};
        border-top-right-radius: ${props.borderRadius ??
        props.theme.comboBox['option-container-border-radius']};
      `;
    }
  }};
  ${props => {
    if (props.last) {
      return css`
        border-bottom-left-radius: ${props.borderRadius ??
        props.theme.comboBox['option-container-border-radius']};
        border-bottom-right-radius: ${props.borderRadius ??
        props.theme.comboBox['option-container-border-radius']};
      `;
    }
  }};
  ${props => {
    if (!props.first && !props.last) {
      return css`
        border-radius: ${() => {
          if (props.borderRadius) return props.borderRadius;
          if (
            props.themeStyles &&
            props.themeStyles['option-container-border-radius']
          ) {
            return props.themeStyles['option-container-border-radius'];
          }
          return props.theme.comboBox['option-container-border-radius'];
        }};
      `;
    }
  }};

  &:hover {
    background-color: ${props => {
      if (
        props.themeStyles &&
        props.themeStyles['option-container-background-color-hover']
      ) {
        return props.themeStyles['option-container-background-color-hover'];
      }
      return props.theme.comboBox['option-container-background-color-hover'];
    }};
    color: ${props => {
      if (
        props.themeStyles &&
        props.themeStyles['option-container-color-hover']
      ) {
        return props.themeStyles['option-container-color-hover'];
      }
      return props.theme.comboBox['option-container-color-hover'];
    }};
  }
`;

export const OptionsContainer = styled.div<IOptionsContainerProps>`
  display: inline-block;
  position: absolute;
  top: calc(100% + 0.7rem);
  width: 100%;
  background-color: ${props => props.theme.comboBox['background-color']};
  ${props => {
    if (props.noBorder != true) {
      return css`
        border: ${props =>
          `${props.theme.comboBox['border-width']} ${props.theme.comboBox['border-style']} ${props.theme.comboBox['border-color']}`};
      `;
    }
  }};
  border-radius: ${props => {
    if (props.borderRadius) return props.borderRadius;
    if (
      props.themeStyles &&
      props.themeStyles['options-container-border-radius']
    ) {
      return props.themeStyles['options-container-border-radius'];
    }
    return props.theme.comboBox['options-container-border-radius'];
  }};
  box-shadow: ${props => {
    if (
      props.themeStyles &&
      props.themeStyles['options-container-box-shadow']
    ) {
      return props.themeStyles['options-container-box-shadow'];
    }
    return props.theme.comboBox['options-container-box-shadow'];
  }};
  z-index: 100;
  cursor: default;
`;

interface OptionsInnerContainerProps {
  borderRadius?: string;
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
  maxHeight?: string;
  textAlign?: string;
  themeStyles?: any;
}

export const OptionsInnerContainer = styled.div<OptionsInnerContainerProps>`
  display: block;
  width: 100%;
  max-height: ${props => {
    if (props.maxHeight) return props.maxHeight;
    if (
      props.themeStyles &&
      props.themeStyles['inner-options-container-max-height']
    ) {
      return props.themeStyles['inner-options-container-max-height'];
    }
    return props.theme.comboBox['inner-options-container-max-height'];
  }};
  overflow-y: auto;
  border-top-left-radius: ${props => {
    if (props.borderRadius) return props.borderRadius;
    return props.theme.comboBox['options-container-border-radius'];
  }};
  border-top-right-radius: ${props => {
    if (props.borderRadius) return props.borderRadius;
    return props.theme.comboBox['options-container-border-radius'];
  }};
  border-bottom-left-radius: ${props => {
    if (props.borderRadius) return props.borderRadius;
    return props.theme.comboBox['options-container-border-radius'];
  }};
  border-bottom-right-radius: ${props => {
    if (props.borderRadius) return props.borderRadius;
    return props.theme.comboBox['options-container-border-radius'];
  }};
  padding-top: ${props => {
    if (props.paddingTop) return props.paddingTop;
    if (
      props.themeStyles &&
      props.themeStyles['inner-options-container-padding-top']
    ) {
      return props.themeStyles['inner-options-container-padding-top'];
    }
    return '0';
  }};
  padding-bottom: ${props => {
    if (props.paddingBottom) return props.paddingBottom;
    if (
      props.themeStyles &&
      props.themeStyles['inner-options-container-padding-bottom']
    ) {
      return props.themeStyles['inner-options-container-padding-bottom'];
    }
    return '0';
  }};
  padding-left: ${props => {
    if (props.paddingLeft) return props.paddingLeft;
    if (
      props.themeStyles &&
      props.themeStyles['inner-options-container-padding-left']
    ) {
      return props.themeStyles['inner-options-container-padding-left'];
    }
    return '0';
  }};
  padding-right: ${props => {
    if (props.paddingRight) return props.paddingRight;
    if (
      props.themeStyles &&
      props.themeStyles['inner-options-container-padding-right']
    ) {
      return props.themeStyles['inner-options-container-padding-right'];
    }
    return '0';
  }};
  margin-top: ${props => {
    if (props.marginTop) return props.marginTop;
    if (
      props.themeStyles &&
      props.themeStyles['inner-options-container-margin-top']
    ) {
      return props.themeStyles['inner-options-container-margin-top'];
    }
    return '0';
  }};
  margin-bottom: ${props => {
    if (props.marginBottom) return props.marginBottom;
    if (
      props.themeStyles &&
      props.themeStyles['inner-options-container-margin-bottom']
    ) {
      return props.themeStyles['inner-options-container-margin-bottom'];
    }
    return '0';
  }};
  margin-left: ${props => {
    if (props.marginLeft) return props.marginLeft;
    if (
      props.themeStyles &&
      props.themeStyles['inner-options-container-margin-left']
    ) {
      return props.themeStyles['inner-options-container-margin-left'];
    }
    return '0';
  }};
  margin-right: ${props => {
    if (props.marginRight) return props.marginRight;
    if (
      props.themeStyles &&
      props.themeStyles['inner-options-container-margin-right']
    ) {
      return props.themeStyles['inner-options-container-margin-right'];
    }
    return '0';
  }};
  text-align: ${props => {
    if (props.textAlign) return props.textAlign;
    return undefined;
  }};
`;

export const IconsContainer = styled.div`
  display: inline-block;
  display: flex;
  align-items: center;
  height: 100%;
`;

export const CrossContainer = styled.div<ICrossContainerProps>`
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  height: 2rem;
  padding-right: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
  border-right: ${props =>
    `0.1rem solid ${props.theme.comboBoxTopicsOfInterest['arrow-cross-icon-vertical-divider-color']}`};
  border-width: 0.1rem;
  color: ${props => props.theme.comboBox['cross-color']};
  font-size: ${props => props.theme.comboBox['cross-font-size']};
  & > * {
    cursor: pointer;
  }
  &:hover {
    color: ${props => {
      if (!props.disabled) {
        if (props.themeStyles && props.themeStyles['cross-color-hover']) {
          return props.themeStyles['cross-color-hover'];
        }
        return props.theme.comboBox['cross-color-hover'];
      }
    }};
  }
`;

export const ArrowContainer = styled.div<IArrowContainerProps>`
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  margin-right: 0.9rem;
  height: 100%;
  cursor: pointer;
  color: ${props => props.theme.comboBox['arrow-color']};
  font-size: ${props => {
    if (props.fontSize) {
      return props.fontSize;
    }
    return props.theme.comboBox['arrow-font-size'];
  }};
  &:hover {
    color: ${props => {
      if (!props.disabled) {
        if (props.themeStyles && props.themeStyles['arrow-color-hover']) {
          return props.themeStyles['arrow-color-hover'];
        }
        return props.theme.comboBox['arrow-color-hover'];
      }
    }};
  }
`;

interface OptionsSelectedContainerProps {
  disabled?: boolean;
  type: 'single' | 'multi' | 'createSingle' | 'createMulti';
}

export const OptionsSelectedContainer = styled.div<OptionsSelectedContainerProps>`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1;
  align-items: center;
  width: 100%;
  cursor: ${props =>
    props.type !== 'createSingle' && props.type !== 'createMulti'
      ? 'pointer'
      : 'default'};
  border-radius: ${props => props.theme.comboBox['border-radius']};
  padding-top: 0rem;
  padding-bottom: 0rem;
  padding-right: 0.6rem;
  height: 100%;
  pointer-events: ${props => (props.disabled ? 'none' : 'all')};
`;

interface OptionsSelectedInnerContainerProps {
  height?: string;
}

export const OptionsSelectedInnerContainer = styled.div<OptionsSelectedInnerContainerProps>`
  min-height: 3.7rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  height: 100%;
  overflow-y: auto;
  padding-left: 1rem;

  &::-webkit-scrollbar {
    width: 0.8rem;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0.4rem;
    background: ${props =>
      props.theme.container['scrollbar-track-background-color']};
  }

  &::-webkit-scrollbar-thumb {
    background: ${props =>
      props.theme.container['scrollbar-thumb-background-color']};
    border-radius: 0.4rem;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${props =>
      props.theme.container['scrollbar-thumb-hover-background-color']};
  }

  scrollbar-color: ${props =>
      props.theme.container['scrollbar-thumb-background-color']}
    ${props => props.theme.container['scrollbar-track-background-color']};
  scrollbar-width: thin;
`;

export const ComboBoxContainer = styled.div<IComboBoxContainerProps>`
  display: ${props => (props.display ? props.display : 'flex')};
  position: relative;
  align-items: center;

  width: ${props => (props.width ? props.width : '40rem')};
  ${props => {
    if (props.growWithoutScroll == true) {
      return css`
        min-height: ${props.height ? props.height : '5rem'};
      `;
    }
    return css`
      height: ${props.height ? props.height : '5rem'};
    `;
  }};

  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};
  margin-left: ${props => props.marginLeft};
  margin-right: ${props => props.marginRight};
  padding-top: ${props => props.paddingTop};
  padding-bottom: ${props => props.paddingBottom};
  padding-left: ${props => props.paddingLeft};
  padding-right: ${props => props.paddingRight};
  background-color: ${props => {
    if (props.disabled) {
      if (props.themeStyles && props.themeStyles['disabled-background-color']) {
        return props.themeStyles['disabled-background-color'];
      }
      return props.theme.comboBox['disabled-background-color'];
    }
    if (props.menuOpen && props.headerAndOptionsSameColorWhenOpen) {
      if (
        props.themeStyles &&
        props.themeStyles['option-container-background-color']
      ) {
        return props.themeStyles['option-container-background-color'];
      }
      return props.theme.comboBox['option-container-background-color'];
    }
    if (props.themeStyles && props.themeStyles['background-color']) {
      return props.themeStyles['background-color'];
    }
    return props.theme.comboBox['background-color'];
  }};
  ${props => {
    if (props.noBorder != true) {
      return css`
        border-width: ${() => {
          if (props.borderWidth) return props.borderWidth;
          if (props.themeStyles && props.themeStyles['border-width']) {
            return props.themeStyles['border-width'];
          }
          return props.theme.comboBox['border-width'];
        }};
        border-style: ${() => {
          if (props.borderStyle) return props.borderStyle;
          if (props.themeStyles && props.themeStyles['border-style']) {
            return props.themeStyles['border-style'];
          }
          return props.theme.comboBox['border-style'];
        }};
        border-color: ${() => {
          if (props.borderColor) return props.borderColor;
          if (props.themeStyles && props.themeStyles['border-color']) {
            return props.themeStyles['border-color'];
          }
          return props.theme.comboBox['border-color'];
        }};

        ${() => {
          if (props.changeBorderColorOnHover) {
            return css`
              &:hover {
                border-color: ${() => {
                  if (
                    props.themeStyles &&
                    props.themeStyles['border-color-hover']
                  ) {
                    return props.themeStyles['border-color-hover'];
                  }
                  return props.theme.comboBox['border-color-hover'];
                }};
              }
            `;
          }
        }}
      `;
    }
  }};
  border-radius: ${props => {
    if (props.borderRadius) return props.borderRadius;
    if (
      props.themeStyles &&
      props.themeStyles['header-container-border-radius']
    ) {
      return props.themeStyles['header-container-border-radius'];
    }
    return props.theme.comboBox['header-container-border-radius'];
  }};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    background-color: ${props => {
      if (!props.disabled && props.changeBackgroundColorOnHover != false) {
        if (props.menuOpen && props.headerAndOptionsSameColorWhenOpen) {
          if (
            props.themeStyles &&
            props.themeStyles['option-container-background-color']
          ) {
            return props.themeStyles['option-container-background-color'];
          }
          return props.theme.comboBox['option-container-background-color'];
        }
        if (props.themeStyles && props.themeStyles['background-color-hover']) {
          return props.themeStyles['background-color-hover'];
        }
        return props.theme.comboBox['background-color-hover'];
      }
    }};

    & ${ArrowContainer} svg {
      fill: ${props => {
        if (!props.disabled) {
          if (props.themeStyles && props.themeStyles['arrow-color-hover']) {
            return props.themeStyles['arrow-color-hover'];
          }
          return props.theme.comboBox['arrow-color-hover'];
        }
      }};
    }
  }
`;
