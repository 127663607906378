import styled from 'styled-components';

import { ListIconToPickProps } from './types';

export const OuterContainer = styled.div`
  height: 100%;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
`;

export const InnerContainer = styled.div`
  width: 75%;
  border-radius: 2.4rem;
  height: calc(100% - 7rem);
  background-color: #ffffff;
  box-shadow: 0rem 1.6rem 2.4rem rgba(0, 0, 0, 0.14),
    0rem 0.6rem 3rem rgba(0, 0, 0, 0.12), 0rem 0.8rem 1rem rgba(0, 0, 0, 0.2);
  margin-top: 3rem;
  margin-bottom: 3rem;

  @media (max-width: 1240px) {
    width: 95%;
  }
  @media (max-width: 630px) {
    width: 95%;
  }
`;

export const HeaderOuterContainer = styled.div`
  height: 7.7rem;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  padding: 0 3.2rem;
  font-size: 2rem;
  font-weight: 600;
  color: #292929;
`;

export const HeaderBackArrowContainer = styled.div`
  height: 4.2rem;
  width: 4.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem;
  transition: all 0.08s;
  opacity: 1;
  border-radius: 0.8rem;
  cursor: pointer;
  & path {
    fill: #000000;
  }

  &:hover {
    background-color: #f4f4f480;
  }
`;

export const HeaderTitleContainer = styled.div`
  font-size: 2rem;
  font-weight: 600;
  color: #292929;
  margin: 0 auto;
`;

export const HiddenHeaderElement = styled.div`
  height: 4rem;
  width: 4rem;
  visibility: hidden;
`;

export const HeaderDivider = styled.div`
  width: 100%;
  height: 0.1rem;
  background-color: rgba(219, 219, 219, 0.5);
`;

export const CardInnerContainer = styled.div`
  margin-top: 4rem;
  padding: 0 5.5rem;
  height: calc(100% - 20.6rem);
  overflow-y: auto;
  margin-bottom: 2rem;

  @media (max-width: 630px) {
    padding: 0 2rem;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.4rem;
`;

export const Card = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

export const ListDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  min-height: 100%;
  margin-bottom: 0;

  @media (max-width: 975px) {
    width: 100%;
  }
`;

export const ListDetailsInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0;
  height: 100%;
`;

export const ListDetailsInnerFirstRowContainer = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ListIconPickerOuterContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 37.8rem;
  margin-left: 7rem;

  @media (max-width: 1100px) {
    width: 90%;
    margin-left: 0;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const ListIconPickerTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1rem;
  margin-bottom: 1.2rem;
  color: #676767;
  display: inline-block;
  position: relative;
`;

export const EmptyListIconContainer = styled.div`
  background-color: #f4f4f4;
  border-radius: 1.5rem;
  padding: 0.35rem;
  width: 6.5rem;
  height: 6.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ListIconContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  @media (max-width: 1100px) {
    width: auto;
    align-items: center;
  }
`;

export const ListIconPickerInnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 1100px) {
    flex-direction: row;
  }
`;

export const ListIcon = styled.i`
  color: #000000;
  font-size: 6.5rem;
`;

export const ListIconsToPickOuterContainer = styled.div`
  width: 100%;
  border-radius: 0.6rem;
  height: calc(100% - 6.9rem);
  max-height: 27rem;
  overflow-y: auto;
  display: flex;
  justify-content: center;

  @media (max-width: 1100px) {
    flex-grow: 1;
    height: auto;
  }
`;

export const ListIconsToPickContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 90%;
`;

export const ListIconToPick = styled.div<ListIconToPickProps>`
  font-size: 5rem;
  border-radius: 0.6rem;
  cursor: pointer;
  color: ${props => (props.selected ? '#000000' : '#8494a9')};
  padding: 0.2rem;

  &:hover {
    color: #000000;
  }
`;

export const ListNameAndDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  flex-grow: 1;

  @media (max-width: 1100px) {
    width: 90%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const ListNameAndDescriptionInnerContainer = styled.div`
  width: 100%;

  & textarea {
    resize: vertical;
    min-height: 15rem;
  }

  & > *:first-child {
    margin-bottom: 3.5rem;
  }

  @media (max-width: 1100px) {
    & > *:first-child {
      margin-bottom: 2rem;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: end;
  padding: 0 5.5rem;
  margin-top: auto;
  border-top: 0.1rem solid #ebeef1;
  padding-top: 2rem;
  padding-bottom: 2rem;

  & > button {
    border-radius: 0.6rem;
    padding-left: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 2rem;
  }

  & > button:first-child {
    margin-right: 1rem;
  }
`;

export const SaveButtonContainer = styled.div``;

export const NotifyInnerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
