import styled, { css } from 'styled-components';

export const ToolBarFilterSideBarInputContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 1rem;
  flex-direction: column;

  input {
    color: #8492a6;

    &::placeholder {
      color: #8492a6;
    }
  }

  @media (max-width: 635px) {
    width: 100%;
  }
`;

export const ToolBarFilterSideBarInputTitle = styled.div`
  color: #676767;
  text-align: left;
  font-weight: 400;
  line-height: 1.8;
  font-size: 1.4rem;
  margin-bottom: 1rem;
`;

export const ToolBarFilterSideBarTimeFrameInnerContainer = styled.div`
  display: flex;
  padding: 0.8rem;
  align-items: center;
  border-style: solid;
  border-width: 0.2rem;
  border-radius: 0.6rem;
  flex-direction: column;
  justify-content: center;
  border-color: rgb(244, 244, 244);
`;

export const ToolBarFilterSideBarTimeFrameCalendarContainer = styled.div`
  display: flex;
  color: #bfbfbf;
  margin: 1rem 0;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: #8492a6;
  }
`;

export const ToolBarFilterSideBarTimeFramePickerContainer = styled.div<{
  visible?: boolean;
}>`
  & .rdrStaticRanges,
  & .rdrInputRanges,
  & .rdrDefinedRangesWrapper {
    display: none;
  }

  & .rdrDateDisplayWrapper {
    margin: 0 2rem;
    background-color: white;
  }

  & .rdrCalendarWrapper {
    background-color: transparent;
  }

  & .rdrDateDisplay {
    gap: 1rem;
    flex-direction: column;
  }

  & .rdrDateDisplayItem,
  .rdrDateDisplayItem + .rdrDateDisplayItem {
    margin: 0;
    width: unset;
  }

  & .rdrMonths.rdrMonthsVertical,
  & .rdrMonthAndYearWrapper {
    height: 0;
    opacity: 0;
    z-index: -9999;
    transition: height 1s ease !important;
  }

  ${({ visible }) => {
    if (visible) {
      return css`
        & .rdrMonths.rdrMonthsVertical,
        & .rdrMonthAndYearWrapper {
          opacity: 1;
          height: auto;
          z-index: 9999;
          padding-top: 1rem;
          pointer-event: all;
          background-color: #fff;
        }
      `;
    }
    return undefined;
  }}
`;
