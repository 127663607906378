/* eslint-disable default-param-last */
/* eslint-disable import/extensions */
/* eslint-disable default-case */
import { useTheme } from 'styled-components';
import { useMemo } from 'react';
import { useQuery, useMutation } from 'react-query';

import {
  User,
  IBusinessProfile,
  IErrorObject,
  IGetLocationsResponseObjectEntry,
  ILooseObject,
  TGetLocationsResponse,
  TGetLocationsResponseEntry,
  TLocations,
} from './types';
import { isAuthenticated, logApiRequestError, jwtParser } from './Utils';
import SkorrApi from '../SkorrApi/new';
import { ISigninData } from '../../pages/SignIn/types';
import { IGetPublicPostsByPublicUserIdParams } from '../SkorrApi/new/Post';
import { GetTagsByKeyInput } from '../SkorrApi/new/Generic/types';

const { user, auth, generic, post, files } = SkorrApi;

export const useGetThemeDarkModeValue = () => {
  const theme: ILooseObject = useTheme();
  return theme['dark-mode'] === true;
};

export const useGetTooltipClassName = () => {
  const theme: ILooseObject = useTheme();
  const tooltipClassName = useMemo(() => {
    if (theme['dark-mode'] === true)
      return 'tooltip-inner-container-dark-theme';
    return 'tooltip-inner-container-light-theme';
  }, [theme['dark-mode']]);
  return tooltipClassName;
};

export const useHasPermissionToAccessPage = (route: string): boolean => {
  const hasPermission = useMemo(() => {
    const userSessionData = isAuthenticated();
    if (!userSessionData) return false;
    const { partnerId } = userSessionData;
    switch (route) {
      case '/sbrand': {
        if (!partnerId) return false;
      }
    }
    return true;
  }, []);
  return hasPermission;
};

export const useGetBusinessProfile = (
  enabled: boolean,
  businessProfileId: string | undefined,
  queryKey?: string,
) => {
  const queryFinal = queryKey
    ? [queryKey]
    : ['business-profile', businessProfileId];
  return useQuery(
    queryFinal,
    async () => {
      try {
        const { data }: { data: IBusinessProfile } =
          await user.getBusinessProfile(businessProfileId!);
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      staleTime: 0,
      retry: false,
    },
  );
};

export const useIsEmailInUse = (
  email: string,
  blocked: boolean,
  verifyEmailAvailability: boolean,
) => {
  return useQuery(
    ['isEmailInUse', email],
    async () => {
      try {
        const { data }: { data: boolean } = await auth.isEmailInUse(email);
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled: !!email && !blocked && verifyEmailAvailability,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );
};

export const useIsEmailVerified = (enabled: boolean, email: string) => {
  return useQuery(
    ['isEmailVerified', email],
    async () => {
      try {
        const { data }: { data: boolean } = await auth.isEmailVerified();
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );
};

export const useIsAdditionalDataStepComplete = (enabled: boolean) => {
  const { partnerId } = isAuthenticated()!;
  return useQuery(
    ['isAdditionalDataStepComplete', partnerId],
    async () => {
      try {
        const { data }: { data: boolean } =
          await user.isAdditionalDataStepComplete();
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );
};

export const useGetAfterSignInData = (
  enabled: boolean,
  email: string,
  queryKey?: string,
) => {
  const sessionData = isAuthenticated();
  const {
    data: isEmailVerified,
    isFetching: isFetchingIsEmailVerified,
    isSuccess: isSuccessIsEmailVerified,
    isError: isErrorIsEmailVerified,
    refetch: refetchIsEmailVerified,
  } = useQuery(
    ['isEmailVerified', email],
    async () => {
      try {
        const { data }: { data: { verified: boolean } } =
          await auth.isEmailVerified();
        return data.verified;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 0,
    },
  );
  const queryKeyFinal = queryKey
    ? [queryKey]
    : ['isAdditionalDataStepComplete', enabled && sessionData!.partnerId];
  const {
    data: isAdditionalDataStepComplete,
    isFetching: isFetchingAdditionalDataStepComplete,
    isSuccess: isSuccessIsAdditionalDataStepComplete,
    isError: isErrorIsAdditionalDataStepComplete,
    refetch: refetchIsAdditionalDataStepComplete,
  } = useQuery(
    queryKeyFinal,
    async () => {
      try {
        const { data }: { data: boolean } =
          await user.isAdditionalDataStepComplete();
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 0,
    },
  );
  const {
    data: isOperatorOrPartnerAdmin,
    isFetching: isFetchingisOperatorOrPartnerAdmin,
    isSuccess: isSuccessisOperatorOrPartnerAdmin,
    isError: isErrorisOperatorOrPartnerAdmin,
    refetch: refetchIsOperatorOrPartnerAdmin,
  } = useQuery(
    ['isOperatorOrPartnerAdmin', enabled && sessionData!.userId],
    async () => {
      try {
        const { data }: { data: boolean } =
          await auth.isOperatorOrPartnerAdmin();
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 0,
    },
  );
  const {
    data: partnerWebsite,
    isFetching: isFetchingGetPartnerWebsite,
    isSuccess: isSuccessGetPartnerWebsite,
    isError: isErrorGetPartnerWebsite,
    refetch: refetchGetPartnerWebsite,
  } = useQuery(
    ['get-partner-website', enabled && sessionData!.partnerId],
    async () => {
      try {
        const { data: partnerWebsite }: { data: string } =
          await user.getPartnerWebsite();
        return partnerWebsite;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled: enabled && sessionData!.partnerType !== 'INFLUENCER',
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 0,
    },
  );
  if (sessionData && sessionData.partnerType !== 'INFLUENCER') {
    return {
      data: {
        isEmailVerified,
        isAdditionalDataStepComplete,
        isOperatorOrPartnerAdmin,
        partnerWebsite,
      },
      isFetching:
        isFetchingIsEmailVerified ||
        isFetchingAdditionalDataStepComplete ||
        isFetchingisOperatorOrPartnerAdmin ||
        isFetchingGetPartnerWebsite,
      isSuccess:
        isSuccessIsEmailVerified &&
        isSuccessIsAdditionalDataStepComplete &&
        isSuccessisOperatorOrPartnerAdmin &&
        isSuccessGetPartnerWebsite,
      isError:
        isErrorIsEmailVerified ||
        isErrorIsAdditionalDataStepComplete ||
        isErrorisOperatorOrPartnerAdmin ||
        isErrorGetPartnerWebsite,
      refetch: {
        refetchIsEmailVerified,
        refetchIsAdditionalDataStepComplete,
        refetchIsOperatorOrPartnerAdmin,
        refetchGetPartnerWebsite,
      },
    };
  }
  return {
    data: {
      isEmailVerified,
      isAdditionalDataStepComplete,
      isOperatorOrPartnerAdmin,
    },
    isFetching:
      isFetchingIsEmailVerified ||
      isFetchingAdditionalDataStepComplete ||
      isFetchingisOperatorOrPartnerAdmin,
    isSuccess:
      isSuccessIsEmailVerified &&
      isSuccessIsAdditionalDataStepComplete &&
      isSuccessisOperatorOrPartnerAdmin,
    isError:
      isErrorIsEmailVerified ||
      isErrorIsAdditionalDataStepComplete ||
      isErrorisOperatorOrPartnerAdmin,
    refetch: {
      refetchIsEmailVerified,
      refetchIsAdditionalDataStepComplete,
      refetchIsOperatorOrPartnerAdmin,
      refetchGetPartnerWebsite,
    },
  };
};

export const useGetTopicsInterest = (enabled: boolean) => {
  return useQuery(
    ['topicsInterest'],
    async () => {
      try {
        const { data }: { data: [[string, string]] } =
          await generic.getTopicsInterest();
        const topicsInterestMap: Map<string, string> = new Map(
          data
            .map((dataEntry: [string, string]) => ({
              key: dataEntry[0],
              value: dataEntry[1],
            }))
            .map((entry: { key: string; value: string }) => [
              entry.value,
              entry.key,
            ]),
        );
        return topicsInterestMap;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
    },
  );
};

export const useGetLocations = (enabled: boolean) => {
  return useQuery(
    ['locations'],
    async () => {
      try {
        const { data }: { data: TGetLocationsResponse } =
          await generic.getLocations();
        const locationsMap: TLocations = new Map(
          data
            .sort((a, b) =>
              a[1].country.toLocaleLowerCase() <
              b[1].country.toLocaleLowerCase()
                ? -1
                : 1,
            )
            .map((dataEntry: TGetLocationsResponseEntry) => ({
              key: dataEntry[0],
              value: dataEntry[1],
            }))
            .map((entry: IGetLocationsResponseObjectEntry) => [
              entry.key,
              entry.value,
            ]),
        );
        return locationsMap;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    { enabled },
  );
};

export const useGetToken = (enabled: boolean, email: string) => {
  return useQuery(
    ['token-data'],
    async () => {
      try {
        const { data }: { data: ISigninData } = await auth.getToken(email);
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
      retry: false,
      staleTime: 0,
    },
  );
};

export const useGetBusinessProfiles = () => {
  return useQuery(
    ['getPartnerBusinessProfiles', isAuthenticated()?.partnerId],
    async () => {
      try {
        const { data }: { data: IBusinessProfile[] } =
          await user.getBusinessProfiles();
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled: !!isAuthenticated()?.partnerId,
      retry: false,
      staleTime: 0,
    },
  );
};

export const useActivateBrandProfile = () => {
  return useMutation(
    async (businessProfileId: string) => {
      try {
        const { data }: { data: ISigninData } =
          await auth.selectBusinessProfile(businessProfileId);
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      onSuccess: (data: ISigninData | undefined) => {
        jwtParser(data as ISigninData);
        // setTimeout(() => window.location.reload(), 1000);
      },
      retry: false,
    },
  );
};

export const useDeactivateBrandProfile = () => {
  return useMutation(
    async () => {
      try {
        const { data }: { data: ISigninData } =
          await auth.deactivateBusinessProfile();
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      onSuccess: (data: ISigninData | undefined) => {
        jwtParser(data as ISigninData);
        setTimeout(() => window.location.reload(), 1000);
      },
      retry: false,
    },
  );
};

export const useGetAgentRoleIds = (agentId: string) => {
  return useQuery(
    ['agentRolesIds', agentId],
    async () => {
      try {
        const { data }: { data: Array<string> } = await user.getAgentRoleIds(
          agentId!,
        );
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
};

export const useGetSelfUser = (enabled: boolean = true) => {
  return useQuery(
    ['get-user', isAuthenticated()?.userId],
    async () => {
      try {
        const { data }: { data: User } = await user.getSelfUser();
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      staleTime: 0,
      retry: false,
    },
  );
};

export const useGetPublicUserById = (
  enabled: boolean = false,
  publicUserIds: string[],
) => {
  return useQuery(
    ['get-public-user-by-id', JSON.stringify(publicUserIds)],
    async () => {
      try {
        const data = await user.getPublicUserById(publicUserIds);
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      staleTime: 0,
      retry: false,
    },
  );
};

export const useGetPublicPostsByPublicUserId = (
  enabled: boolean = false,
  params: IGetPublicPostsByPublicUserIdParams,
) => {
  return useQuery(
    ['get-public-posts-by-public-user-id', JSON.stringify(params)],
    async () => {
      try {
        const data = await post.getPublicPostsByPublicUserId(params);
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      staleTime: 0,
      retry: false,
    },
  );
};

export const useGetTagsByKey = (
  enabled: boolean = false,
  params: GetTagsByKeyInput,
) => {
  return useQuery(
    ['get-tags-by-key', JSON.stringify(params)],
    async () => {
      try {
        const data = await generic.getTagsByKey(params);
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      staleTime: 0,
      retry: false,
    },
  );
};

export const useGetAllBrandPictures = (enabled: boolean = false) => {
  return useQuery(
    ['get-all-brand-pictures'],
    async () => {
      try {
        const data = await generic.getAllBrandPictures();
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      staleTime: 0,
      retry: false,
    },
  );
};

export const useUploadImage = () => {
  return useMutation(async (imageData: FormData) => {
    try {
      const { data: imageUrl }: { data: string } = await files.uploadImage(
        imageData,
      );

      return imageUrl;
    } catch (error) {
      logApiRequestError(error as IErrorObject);
      throw error;
    }
  });
};
