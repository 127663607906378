import styled from 'styled-components';

export const PageNavList = styled.ul`
  display: flex;
  margin-top: 2rem;
  align-items: center;
  margin-bottom: 3rem;
  list-style-type: none;
  justify-content: center;
`;

export const PageNavListItem = styled.li<{
  active?: boolean;
  disable?: boolean;
}>`
  width: 3.5rem;
  display: flex;
  padding: 0.8rem;
  border-radius: 50%;
  margin-right: 0.4rem;
  justify-content: center;
  background-color: ${({ active }) => (active ? '#d9dee8' : undefined)};
  cursor: ${({ disable }) => (disable ? 'not-allowed' : 'pointer')};

  &:first-child {
    a {
      color: #8492a6;
      margin-right: 3rem;
    }
  }

  &:last-child {
    a {
      color: #8492a6;
      margin-left: 2rem;
    }
  }

  a {
    text-decoration: none;
    font-weight: ${({ active }) => (active ? 700 : 400)};
    color: ${({ active }) => (active ? '#3C4858' : '#8492a6')};
    pointer-events: ${({ disable }) => (disable ? 'none' : 'all')};

    &:hover {
      color: ${({ disable }) => (disable ? undefined : '#3c4858')};
    }
  }
`;
