import styled from 'styled-components';

export const ToolBarFilterSideBarInputContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 1rem;
  flex-direction: column;

  input {
    color: #8492a6;

    &::placeholder {
      color: #8492a6;
    }
  }

  @media (max-width: 635px) {
    width: 100%;
  }
`;

export const ToolBarFilterSideBarInputTitle = styled.div`
  color: #676767;
  text-align: left;
  font-weight: 400;
  line-height: 1.8;
  font-size: 1.4rem;
  margin-bottom: 1rem;
`;

export const ToolBarFilterSideBarErrorText = styled.span`
  color: #b00020;
  font-size: 1rem;
  margin-top: 1rem;
  text-align: start;
  padding-left: 1rem;
`;
