/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

import {
  PublicUserCardInnerContainerProps,
  PublicUserCheckboxContainerProps,
} from './types';

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: start;
  padding-left: 0.8rem;
`;

export const InnerContainer = styled.div`
  width: 80%;
  border-radius: 2.4rem;
  height: calc(100vh - 10.5rem);
  background-color: #ffffff;
  box-shadow: ${props =>
    `0rem 1.6rem 2.4rem rgba(0, 0, 0, 0.14), 0rem 0.6rem 3rem rgba(0, 0, 0, 0.12), 0rem 0.8rem 1rem rgba(0, 0, 0, 0.2)`};
  margin: 2rem 0;

  @media (max-width: 1325px) {
    width: 95%;
  }
`;

export const Card = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const HeaderOuterContainer = styled.div`
  min-height: 7.7rem;
  margin-bottom: 2rem;
  border-bottom: 0.1rem solid rgba(219, 219, 219, 0.5);
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  padding: 0 3.2rem;
  font-size: 2rem;
  font-weight: 600;
  color: #292929;

  @media (max-width: 600px) {
    padding: 0 1.6rem;
  }
`;

export const HeaderBackArrowContainer = styled.div`
  height: 4.2rem;
  width: 4.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem;
  transition: all 0.08s;
  opacity: 1;
  border-radius: 0.8rem;
  cursor: pointer;
  & path {
    fill: #000000;
  }

  &:hover {
    background-color: #f4f4f480;
  }
`;

export const HeaderTitleContainer = styled.div`
  font-size: 2rem;
  font-weight: 600;
  color: #292929;
  margin: 0 auto;
  text-align: center;
`;

export const HiddenHeaderElement = styled.div`
  height: 4rem;
  width: 4rem;
  visibility: hidden;
`;

export const CardInnerContainer = styled.div`
  padding: 0 3.2rem;
  display: flex;
  flex-direction: column;
  height: calc(100% - 9.7rem);
`;

export const FirstRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  & button {
    border-radius: 0.6rem;
  }
`;

export const ListNameContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 70%;
`;

export const ListMembersContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ListNameLabel = styled.div`
  font-size: 1.6rem;
  font-weight: 400;
  color: #292929;
  white-space: pre;
`;

export const ListName = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  color: #676767;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

export const MembersContainer = styled.div`
  display: flex;
  overflow-y: auto;
  height: calc(100% - 6.28rem);
  margin: 0 -3.2rem;
  padding: 0 3.2rem;
  padding-top: 2rem;
  position: relative;
  flex-wrap: wrap;
  margin-bottom: 2rem;

  & > div:first-child {
    padding-top: 0;
  }

  & > div:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
`;

export const PublicUserCardOuterContainer = styled.div`
  width: 100%;
  padding: 0 1rem;
  position: relative;
  margin-bottom: 2rem;

  @media (min-width: 665px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (min-width: 992px) {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  @media (max-width: 1150px) {
    padding: 0 0.4rem;
  }

  @media (min-width: 1285px) {
    flex: 0 0 25%;
    max-width: 25%;
  }

  @media (min-width: 1665px) {
    flex: 0 0 20%;
    max-width: 20%;
  }

  @media (max-width: 665px) {
    padding-left: 6rem;
    padding-right: 6rem;
  }
`;

export const PublicUserCardInnerContainer = styled.div<PublicUserCardInnerContainerProps>`
  overflow: hidden;
  position: relative;
  border-radius: 2rem;
  transition: transform 0.2s ease 0s;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(216, 221, 230, 0.35) 0 0.1rem 0 0.1rem;
  border: 0.2rem solid #ffffff;

  ${props => {
    if (props.selected) {
      return css`
        border-color: #5870f6;
      `;
    }
  }}

  &:hover {
    transform: scale(1.03);
  }
`;

export const PublicUserCardPictureContainer = styled.div`
  height: 23vw;
  display: flex;
  position: relative;
  align-items: center;
  -webkit-box-align: center;
  background: rgb(231, 234, 240);

  @media (min-width: 1665px) {
    height: 18.5vw;
  }

  @media (min-width: 1285px) and (max-width: 1664px) {
    height: 23vw;
  }

  @media (min-width: 992px) and (max-width: 1284px) {
    height: 31vw;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    height: 46vw;
  }

  @media (min-width: 665px) and (max-width: 767px) {
    height: 45vw;
  }

  @media (max-width: 665px) {
    height: 91vw;
  }
`;

export const PublicUserCardPictureOverlay = styled.div`
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  place-items: center;
  -webkit-box-align: center;
  transition: opacity 0.3s ease 0s;

  &:hover {
    opacity: 1;
  }
`;

export const PublicUserCardPictureOverlayActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`;

export const PublicUserCardPictureOverlayIcon = styled.i`
  cursor: pointer;
`;

export const PublicUserCardUserInfoContainer = styled.div`
  bottom: 0;
  width: 100%;
  display: flex;
  padding: 1rem 2rem;
  position: absolute;
  align-items: center;
  justify-content: space-between;
`;

export const PublicUserCardUsernameText = styled.h6`
  color: #fff;
  font-weight: 600;
  line-height: 1.6;
  font-size: 1.4rem;
`;

export const PublicUserCardHandlerText = styled.span`
  color: #fff;
  font-weight: 400;
  line-height: 1.8;
  font-size: 1.2rem;
`;

export const PublicUserCardPicture = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

export const PublicUserCardDescriptionContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  min-height: 6.9rem;
  align-items: center;
  padding: 1.4rem 2rem;
  -webkit-box-align: center;

  @media (max-width: 272px) {
    flex-wrap: wrap;
    align-items: center;
  }
`;

export const PublicUserCardDescriptionTextContainer = styled.a`
  flex-grow: 1;
  color: #292929;
  display: block;
  font-size: 1.28rem;
  overflow-x: overlay;
  -webkit-box-flex: 1;
  margin-right: 2.5rem;
  text-decoration: none;

  @media (max-width: 272px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
`;

export const PublicUserCardDescriptionText = styled.p`
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
`;

export const PublicUserCardCountryContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 10rem;
  justify-content: center;
  -webkit-box-pack: center;
  -webkit-box-align: center;

  @media (max-width: 272px) {
    width: 100%;
    margin-bottom: 0.2rem;
  }
`;

export const PublicUserCardGlobeIconContainer = styled.div`
  fill: #8492a6;
`;

export const PublicUserCardKpisContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 2rem 2rem 2rem;
`;

export const PublicUserCardKpiContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  flex: 1 1 50%;
  display: flex;
  line-height: 1.5;
  min-width: 8.5rem;
  margin-bottom: 1rem;

  svg {
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
  }
`;

export const PublicUserCardKpiInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PublicUserKpiLabel = styled.span`
  font-weight: 400;
  font-size: 1.2rem;
  color: rgb(132, 146, 166);
`;

export const PublicUserKpiValue = styled.span`
  line-height: 2;
  font-weight: 700;
  font-size: 1.4rem;
  white-space: nowrap;
  color: rgb(22, 43, 57);
  letter-spacing: 0.015rem;
`;

export const PublicUserCheckboxContainer = styled.div<PublicUserCheckboxContainerProps>`
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  z-index: 9;
`;

export const EmptyInfoContainer = styled.div`
  background-color: #fff;
  padding: 2rem;
  border-radius: 0.6rem;
  margin-bottom: 2rem;
  margin-top: 4.8rem;
  display: flex;
  justify-content: center;
`;

export const NotifyInnerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
