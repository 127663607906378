import {
  categoriesAvailableValues,
  gendersAvailableValues,
  socialNetworkAvailableValues,
} from 'features/Discover/data/constants';
import { useDiscoverContext } from 'features/Discover/hooks/contexts/use-discover-context';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';

import DiscoverToolBarFilterSideBarCalendar from '../DiscoverToolBarFilterSideBarCalendar';
import DiscoverToolBarFilterSideBarDropdown from '../DiscoverToolBarFilterSideBarDropdown';
import DiscoverToolBarFilterSideBarRangeInput from '../DiscoverToolBarFilterSideBarRangeInput';
import DiscoverToolBarFilterSideBarTextInput from '../DiscoverToolBarFilterSideBarTextInput';
import * as S from './Styles';

const ClickOutHandler = require('react-onclickout');

const DiscoverToolBarFilterButton = () => {
  const {
    gender,
    handler,
    network,
    keywords,
    category,
    countryCode,
    topicsInterest,
    handleApplyFilters,
    handleClearFilters,
    toggleFilterButton,
    countriesAvailableValues,
    handleToggleFilterButton,
    topicsOfInterestAvailableValues,
    handleClickOutToggleFilterButton,
  } = useDiscoverContext();

  return (
    <>
      <S.ToolBarFilterButton onClick={handleToggleFilterButton}>
        <svg viewBox="0 0 20 20">
          <path
            d="M3 5C7.7 5 12.3 5 17 5H3ZM18 5C18 4.4 17.6 4 17 4H3C2.4 4 2 4.4 2 5C2 5.6 2.4 6 3 6H17C17.6 6 18 5.6 18 5ZM7 15C9 15 11 15 13 15H7ZM14 15C14 14.4 13.6 14 13 14H7C6.4 14 6 14.4 6 15C6 15.6 6.4 16 7 16H13C13.6 16 14 15.6 14 15ZM5 10C8.3 10 11.7 10 15 10H5ZM16 10C16 9.4 15.6 9 15 9H5C4.4 9 4 9.4 4 10C4 10.6 4.4 11 5 11H15C15.6 11 16 10.6 16 10Z"
            fill="#676767"
          />
        </svg>
        {t('discover.filter')}
      </S.ToolBarFilterButton>

      <ClickOutHandler onClickOut={handleClickOutToggleFilterButton}>
        <S.ToolBarFilterSideBarBackground active={toggleFilterButton}>
          <S.ToolBarFilterSideBarContainer>
            <DiscoverToolBarFilterSideBarTextInput
              title="keywords"
              value={keywords}
              placeholder={t('discover.keywordsPlaceholder')}
            />

            {keywords?.[0]?.length! > 0 && keywords?.[0]?.trim() !== '' && (
              <DiscoverToolBarFilterSideBarCalendar />
            )}

            <DiscoverToolBarFilterSideBarDropdown
              isFilterable
              title="country"
              value={countryCode?.[0]}
              valueKey="countryCode"
              optionValues={countriesAvailableValues}
              placeholder={t('discover.countryPlaceholder')}
            />

            <DiscoverToolBarFilterSideBarTextInput
              title="handler"
              value={handler}
              placeholder={t('discover.handlerPlaceholder')}
            />

            <DiscoverToolBarFilterSideBarDropdown
              title="socialNetwork"
              value={network?.[0]}
              valueKey="network"
              optionValues={socialNetworkAvailableValues}
              placeholder={t('discover.socialNetworkPlaceholder')}
            />

            <DiscoverToolBarFilterSideBarDropdown
              title="category"
              value={category}
              valueKey="category"
              optionValues={categoriesAvailableValues}
              placeholder={t('discover.categoryPlaceholder')}
            />

            {category !== '' && category !== undefined && (
              <DiscoverToolBarFilterSideBarDropdown
                isFilterable
                type="multi"
                title="topcisOfInterest"
                value={topicsInterest}
                valueKey="topicsInterest"
                optionValues={topicsOfInterestAvailableValues}
                placeholder={t('discover.topcisOfInterestPlaceholder')}
              />
            )}

            {category === 'INFLUENCER' && (
              <DiscoverToolBarFilterSideBarDropdown
                title="gender"
                value={gender?.[0]}
                valueKey="gender"
                optionValues={gendersAvailableValues}
                placeholder={t('discover.genderPlaceholder')}
              />
            )}

            <S.ToolBarFilterSideBarDivider />

            <DiscoverToolBarFilterSideBarRangeInput />

            <div style={{ marginBottom: '8rem' }} />

            <S.ToolBarFilterSideBarFooterContainer>
              <S.ToolBarFilterSideBarFooterButton
                secondary
                onClick={handleClearFilters}
              >
                <S.ToolBarFilterSideBarFooterButtonText secondary>
                  {t('discover.clear')}
                </S.ToolBarFilterSideBarFooterButtonText>
              </S.ToolBarFilterSideBarFooterButton>

              <S.ToolBarFilterSideBarFooterButton
                onClick={() => handleApplyFilters(true)}
              >
                <S.ToolBarFilterSideBarFooterButtonText>
                  {t('discover.apply')}
                </S.ToolBarFilterSideBarFooterButtonText>
              </S.ToolBarFilterSideBarFooterButton>
            </S.ToolBarFilterSideBarFooterContainer>
          </S.ToolBarFilterSideBarContainer>
        </S.ToolBarFilterSideBarBackground>
      </ClickOutHandler>
    </>
  );
};

export default withTranslation()(DiscoverToolBarFilterButton);
