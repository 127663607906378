import { t } from 'i18next';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { withTranslation } from 'react-i18next';
import { SortByFields } from 'Services/SkorrApi/new/User/types';

import * as S from './Styles';

const DiscoverToolBarFilterSideBarRangeInput = () => {
  const { watch, setValue } = useFormContext();

  const filters = watch('filters');

  const getFieldValue = useCallback(
    (value: SortByFields) => {
      let item: any = value;
      if (value === 'engagement_rate') item = 'eng';

      const lowValue = `${item}ValueLow`;
      const highValue = `${item}ValueHigh`;

      return {
        lowValueKey: `filters.${lowValue}`,
        highValueKey: `filters.${highValue}`,
        lowValue: filters[lowValue],
        highValue: filters[highValue],
      };
    },
    [filters],
  );

  return (
    <>
      {Object.values(SortByFields).map(item => {
        if (item === 'pulsedSkorr' || item === 'reactions') return null;

        const { lowValue, lowValueKey, highValue, highValueKey } =
          getFieldValue(item);

        return (
          <S.ToolBarFilterSideBarInputContainer key={item}>
            <S.ToolBarFilterSideBarInputTitle>
              {t(`discover.sortByFields.${item}`)}
            </S.ToolBarFilterSideBarInputTitle>

            <S.ToolBarFilterSideBarInputContainerFlex>
              <input
                type="number"
                value={lowValue}
                name={lowValueKey}
                onChange={e => setValue(lowValueKey, Number(e.target.value))}
              />

              <S.ToolBarFilterSideBarInputMiddleText>
                {t('discover.to')}
              </S.ToolBarFilterSideBarInputMiddleText>

              <input
                type="number"
                value={highValue}
                name={highValueKey}
                onChange={e => setValue(highValueKey, Number(e.target.value))}
              />
            </S.ToolBarFilterSideBarInputContainerFlex>
          </S.ToolBarFilterSideBarInputContainer>
        );
      })}
    </>
  );
};

export default withTranslation()(DiscoverToolBarFilterSideBarRangeInput);
