/* eslint-disable no-empty-function */
/* eslint-disable import/extensions */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { useCallback } from 'react';

import PostTypeSingleImageIcon from '../../../assets/iconComponents/PostTypeSingleImageIcon';
import PostTypeGalleryIcon from '../../../assets/iconComponents/PostTypeGalleryIcon';
import PostTypeVideoIcon from '../../../assets/iconComponents/PostTypeVideoIcon';
import PostTypeTextIcon from '../../../assets/iconComponents/PostTypeTextIcon';
import EyeIcon from '../../../assets/iconComponents/EyeIcon';
import {
  DEFAULT_USER_AVATAR,
  IPublicUser,
  SocialNetworks,
} from '../../../Services/Utils/types';
import {
  IPublicPost,
  PublicPostTypes,
} from '../../../Services/SkorrApi/new/Post/types';
import {
  formatNumberByGroupingDigits,
  getSocialNetworkIcon,
} from '../../../Services/Utils/Utils';
import { IPublicPostProps } from './types';
import * as S from './styles';
import CheckBox from '../../../components/shared/Checkbox/CheckBox';

const CopyToClipboard = require('react-copy-to-clipboard');

const getPostTypeIcon = (postType: PublicPostTypes) => {
  switch (postType) {
    case PublicPostTypes.STORY:
    case PublicPostTypes.IMAGE:
      return <PostTypeSingleImageIcon fill={'white'} />;
    case PublicPostTypes.CAROUSEL:
      return <PostTypeGalleryIcon fill={'white'} />;
    case PublicPostTypes.VIDEO:
      return <PostTypeVideoIcon fill={'white'} />;
    default:
      return <PostTypeTextIcon fill={'white'} />;
  }
};

const copySmartLinkButtonClicked = (event: any) => {
  event.stopPropagation();
  const notification = event.currentTarget.querySelector('span');
  notification.style.opacity = '1';
  // eslint-disable-next-line no-return-assign
  setTimeout(() => (notification.style.opacity = '0'), 600);
};

const renderCopyContent = (post: IPublicPost, t: (path: string) => string) => {
  return (
    <S.CopyContentContainer
      onClick={event => copySmartLinkButtonClicked(event)}
    >
      <S.CopiedToClipboard>
        {t('mediaKit.post.description.copiedToClipboard')}
      </S.CopiedToClipboard>
      <CopyToClipboard text={post.content} id={`copyContent${post.id}`}>
        <span>
          <S.CopyIcon />
        </span>
      </CopyToClipboard>
      <S.CopyIconTooltip placement="top" target={`copyContent${post.id}`}>
        {t('mediaKit.post.description.copyText')}
      </S.CopyIconTooltip>
    </S.CopyContentContainer>
  );
};

const Post = ({
  post,
  user,
  isSelected,
  postSelectionActionInvoked,
  t,
}: IPublicPostProps) => {
  const { socialNetwork } = post;
  const getFormattedPostKpiValue = useCallback((value?: number) => {
    if (value === -1) {
      return '-';
    }

    return formatNumberByGroupingDigits(value ?? 0);
  }, []);

  const renderReactions = (
    post: IPublicPost,
    socialNetwork: SocialNetworks,
  ) => {
    return (
      <S.ReactionsContainer>
        {socialNetwork === SocialNetworks.YOUTUBE &&
          post.type !== PublicPostTypes.STORY && (
            <S.KpiContainer>
              <S.KpiLabel>{t('mediaKit.post.reactions.views')}</S.KpiLabel>
              <S.KpiValue>
                {getFormattedPostKpiValue(post.insights.views)}
              </S.KpiValue>
            </S.KpiContainer>
          )}
        {post.type !== PublicPostTypes.STORY && (
          <S.KpiContainer>
            <S.KpiLabel>{t('mediaKit.post.reactions.likes')}</S.KpiLabel>
            <S.KpiValue>
              {getFormattedPostKpiValue(post.insights.likes)}
            </S.KpiValue>
          </S.KpiContainer>
        )}
        {post.type !== PublicPostTypes.STORY && (
          <S.KpiContainer>
            <S.KpiLabel>{t('mediaKit.post.reactions.comments')}</S.KpiLabel>
            <S.KpiValue>
              {getFormattedPostKpiValue(post.insights.comments)}
            </S.KpiValue>
          </S.KpiContainer>
        )}
        {post.type !== PublicPostTypes.STORY &&
          socialNetwork !== SocialNetworks.YOUTUBE && (
            <S.KpiContainer>
              <S.KpiLabel>{t('mediaKit.post.reactions.shares')}</S.KpiLabel>
              <S.KpiValue>
                {getFormattedPostKpiValue(post.insights.shares)}
              </S.KpiValue>
            </S.KpiContainer>
          )}
        {post.type === PublicPostTypes.STORY && (
          <S.KpiContainer>
            <S.KpiLabel>{t('mediaKit.post.reactions.reach')}</S.KpiLabel>
            <S.KpiValue>
              {getFormattedPostKpiValue(post.insights.reach)}
            </S.KpiValue>
          </S.KpiContainer>
        )}
        {post.type === PublicPostTypes.STORY && (
          <S.KpiContainer>
            <S.KpiLabel>{t('mediaKit.post.reactions.impressions')}</S.KpiLabel>
            <S.KpiValue>
              {getFormattedPostKpiValue(post.insights.impressions)}
            </S.KpiValue>
          </S.KpiContainer>
        )}
        {post.type === PublicPostTypes.STORY && (
          <S.KpiContainer>
            <S.KpiLabel>{t('mediaKit.post.reactions.interactions')}</S.KpiLabel>
            <S.KpiValue>
              {getFormattedPostKpiValue(post.insights.total_interactions)}
            </S.KpiValue>
          </S.KpiContainer>
        )}
      </S.ReactionsContainer>
    );
  };

  const renderHeader = (post: IPublicPost, user: IPublicUser) => {
    return (
      <S.HeaderContainer>
        <span>
          <S.HeaderAvatar src={user?.image || DEFAULT_USER_AVATAR} />
        </span>
        <span>
          <S.HeaderName>{user?.name}</S.HeaderName>
          <S.HeaderDate>
            {moment(new Date(post?.date)).format('YYYY-MM-DD')}
          </S.HeaderDate>
        </span>
      </S.HeaderContainer>
    );
  };

  const renderPicture = (
    post: IPublicPost,
    isPostSelected: boolean,
    postId: string,
    postSelectionActionInvoked: (postId: string, toInclude: boolean) => void,
  ) => {
    return (
      <S.PictureContainer>
        <S.PictureElementContainer>
          <S.PictureElementInnerContainer>
            {post.image ? (
              <S.Picture src={post.image} />
            ) : (
              <S.TextContainer>
                <p>{post.content}</p>
              </S.TextContainer>
            )}

            <S.PictureOverlay>
              <S.PictureOverlayActions>
                <S.PictureOverlayIcon
                  onClick={() => window.open(post.url, '_blank')}
                >
                  <EyeIcon fill={'white'} width={'6rem'} height={'6rem'} />
                </S.PictureOverlayIcon>
              </S.PictureOverlayActions>
            </S.PictureOverlay>

            <S.TypeIconContainer>
              {getPostTypeIcon(post.type)}
            </S.TypeIconContainer>
          </S.PictureElementInnerContainer>
        </S.PictureElementContainer>

        <S.PostCheckMarkContainer
          onClick={() => postSelectionActionInvoked(postId, !isPostSelected)}
          checked={isPostSelected}
        >
          <CheckBox
            checked={isPostSelected}
            onChange={() => {}}
            marginLeft={'0.5rem'}
            width={'2rem'}
            height={'2rem'}
            borderWidth={'0.25rem'}
            borderRadius={'50%'}
          />
        </S.PostCheckMarkContainer>
      </S.PictureContainer>
    );
  };

  const renderDescription = (post: IPublicPost) => {
    return (
      <S.DescriptionContainer>
        <S.SocialNetworkContainer socialNetwork={socialNetwork}>
          {getSocialNetworkIcon({
            socialNetwork,
            width: '2rem',
            height: '2rem',
            gradientColored: true,
          })}
        </S.SocialNetworkContainer>

        {post.image ? (
          <S.DescriptionTextContainer>
            <S.DescriptionText>{post.content}</S.DescriptionText>
          </S.DescriptionTextContainer>
        ) : (
          <span className="--hide-from-pdf">
            <S.DescriptionTextContainer
              typeCursor={true}
              onClick={() => window.open(post.url, '_blank')}
            >
              <S.DescriptionText>
                <S.ViewPostContainer>
                  {t('mediaKit.post.description.viewPost')}
                  <S.ViewPostIcon />
                </S.ViewPostContainer>
              </S.DescriptionText>
            </S.DescriptionTextContainer>
          </span>
        )}

        {post.image && post.content && renderCopyContent(post, t)}
      </S.DescriptionContainer>
    );
  };

  return (
    <S.OuterContainer>
      <S.InnerContainer key={post.id}>
        {renderHeader(post, user)}
        {renderPicture(post, isSelected, post.id, postSelectionActionInvoked)}
        {renderDescription(post)}
        {renderReactions(post, socialNetwork)}
      </S.InnerContainer>
    </S.OuterContainer>
  );
};

export default withTranslation()(Post);
