/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable default-case */
/* eslint-disable no-empty-function */
/* eslint-disable eqeqeq */
/* eslint-disable no-use-before-define */
/* eslint-disable no-undef */
/* eslint-disable import/extensions */
import { useState, useEffect, useRef, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { toast } from 'react-toastify';
import ReactInputVerificationCode from 'react-input-verification-code';

import Button from '../../components/shared/Button/Button';
import * as S from './Styles';
import { IProjectWindow, ISignInProps } from './types';
import SkorrOnboardingBackgroundImage from '../../assets/skorr-onboarding-bg.jpg';
import SkorrLogo from '../../assets/logo-skorr-vh.svg';
import SkorrLogoSmallWindow from '../../assets/logo-skorr-vh-noslogan.svg';
import InputControlV2 from '../../components/shared/InputControlV2/InputControlV2';
import {
  emailValid,
  valueEmpty,
  passwordValid,
} from '../../validations/validationFunctions';
import BackArrowIcon from '../../assets/iconComponents/BackArrowIcon';
import ForwardArrowIcon from '../../assets/iconComponents/ForwardArrow';
import InputCheckMarkIcon from '../../assets/iconComponents/InputCheckMarkIcon';
import { getThemeStyles } from '../../css/ThemeGlobal';
import {
  useSendForgotPasswordCodeEmail,
  useSigninMutation,
  useUpdatePasswordWithCode,
  useVerifyForgotPasswordCode,
  verifyToken,
} from './RQCustomHooks';
import GenericModal from '../../components/shared/GenericModal/GenericModal';
import NotifyCustom from '../../components/shared/NotifyCustom/NotifyCustom';
import InputSpinnerIcon from '../../assets/iconComponents/InputSpinnerIcon/InputSpinnerIcon';
import EnvelopeIcon from '../../assets/iconComponents/EnvelopeIcon';
import { isAuthenticated } from '../../Services/Utils/Utils';
import { useGetAfterSignInData } from '../../Services/Utils/CustomHooks';

const SignIn = (props: ISignInProps) => {
  const [email, setEmail] = useState<string>('');
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [isEmailInputFocused, setIsEmailInputFocused] =
    useState<boolean>(false);
  const [hasEmailBeenChanged, setHasEmailBeenChanged] = useState(false);
  const [forceEmailErrorDisplay, setForceEmailErrorDisplay] = useState<
    string | undefined
  >();
  const [password, setPassword] = useState<string>('');
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);
  const [isPasswordInputFocused, setIsPasswordInputFocused] =
    useState<boolean>(false);
  const [hasPasswordBeenChanged, setHasPasswordBeenChanged] = useState(false);
  const [forcePasswordErrorDisplay, setForcePasswordErrorDisplay] = useState<
    string | undefined
  >();
  const [hasSignInButtonBeenClicked, setHasSignInButtonBeenClicked] =
    useState(false);
  const [displayForgotPasswordForm, setDisplayForgotPasswordForm] =
    useState<boolean>(false);
  const [forgotPasswordFormStep, setForgotPasswordFormStep] = useState<
    1 | 2 | 3
  >();
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState<string>('');
  const [isForgotPasswordEmailValid, setIsForgotPasswordEmailValid] =
    useState<boolean>(false);
  const [
    hasForgotPasswordEmailBeenChanged,
    setHasForgotPasswordEmailBeenChanged,
  ] = useState(false);
  const [
    isForgotPasswordEmailInputFocused,
    setIsForgotPasswordEmailInputFocused,
  ] = useState<boolean>(false);
  const [
    forceForgotPasswordEmailErrorDisplay,
    setForceForgotPasswordEmailErrorDisplay,
  ] = useState<string | undefined>();
  const [
    hasForgotPasswordEmailSendButtonBeenClicked,
    setHasForgotPasswordEmailSendButtonBeenClicked,
  ] = useState(false);
  const [validateCaptcha, setValidateCaptcha] = useState(false);
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [numberOfSecondsRemaining, setNumberOfSecondsRemaining] =
    useState<number>(0);
  const countDownTimerRefId = useRef<NodeJS.Timer>();
  const verificationCodeInputRef1 = useRef<HTMLInputElement>();
  const verificationCodeInputRef2 = useRef<HTMLInputElement>();
  const verificationCodeInputRef3 = useRef<HTMLInputElement>();
  const verificationCodeInputRef4 = useRef<HTMLInputElement>();
  const verificationCodeInputRef5 = useRef<HTMLInputElement>();
  const verificationCodeInputRef6 = useRef<HTMLInputElement>();
  const [
    showForgotPasswordVerificationCodeFormatNotValid,
    setShowForgotPasswordVerificationCodeFormatNotValid,
  ] = useState(false);
  const pageContainerRef = useRef<HTMLElement>();
  const errorModalRef = useRef<HTMLElement>();
  const [newPassword, setNewPassword] = useState<string>('');
  const [hasNewPasswordBeenChanged, setHasNewPasswordBeenChanged] =
    useState(false);
  const [isNewPasswordValid, setIsNewPasswordValid] = useState<boolean>(false);
  const [isNewPasswordInputFocused, setIsNewPasswordInputFocused] =
    useState<boolean>(false);
  const [forceNewPasswordErrorDisplay, setForceNewPasswordErrorDisplay] =
    useState<string | undefined>();
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');
  const [
    hasConfirmNewPasswordBeenChanged,
    setHasConfirmNewPasswordBeenChanged,
  ] = useState(false);
  const [isConfirmNewPasswordValid, setIsConfirmNewPasswordValid] =
    useState<boolean>(false);
  const [isConfirmNewPasswordProcessing, setIsConfirmNewPasswordProcessing] =
    useState(false);
  const [
    isConfirmNewPasswordInputFocused,
    setIsConfirmNewPasswordInputFocused,
  ] = useState<boolean>(false);
  const [
    forceConfirmNewPasswordErrorDisplay,
    setForceConfirmNewPasswordErrorDisplay,
  ] = useState<string | undefined>();
  const [
    triggerConfirmNewPasswordValidation,
    setTriggerConfirmNewPasswordValidation,
  ] = useState(0);
  const [
    hasUpdatePasswordButtonBeenClicked,
    setHasUpdatePasswordButtonBeenClicked,
  ] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [msgError, setMsgError] = useState('');
  const [isGetAfterSignInDataEnabled, setIsGetAfterSignInDataEnabled] =
    useState<boolean>(false);
  const [getAfterSignInDataQueryKey, setGetAfterSignInDataQueryKey] = useState(
    String(new Date().getTime()),
  );
  const {
    isLoading: isLoadingSendForgotPasswordCodeEmail,
    isSuccess: isSuccessSendForgotPasswordCodeEmail,
    mutate: mutateSendForgotPasswordCodeEmail,
  } = useSendForgotPasswordCodeEmail(setHasError, setMsgError, props.t);
  const [enabledVerifyForgotPasswordCode, setEnabledVerifyForgotPasswordCode] =
    useState<boolean>(false);
  const getPasswordInvalidMessage = useCallback((password: string): string => {
    if (!(/[a-z]/.test(password) && /[A-Z]/.test(password))) {
      return props.t('signup.passwordLetterCaseWarning');
    }
    if (!/[0-9]/.test(password)) {
      return props.t('signup.passwordNumbersWarning');
    }
    if (!/[!@_#$%^&*]/.test(password)) {
      return props.t('signup.passwordSpecialCharacterWarning');
    }
    if (password.length < 8) {
      return props.t('signup.passwordLengthWarning');
    }
    return '';
  }, []);
  const {
    data: verifyForgotPasswordCodeResult,
    isFetching: isFetchingVerifyForgotPasswordCode,
    isSuccess: isSuccessVerifyForgotPasswordCode,
    isError: isErrorVerifyForgotPasswordCode,
    refetch: refetchVerifyForgotPasswordCode,
  } = useVerifyForgotPasswordCode(
    forgotPasswordEmail || '',
    verificationCode,
    enabledVerifyForgotPasswordCode,
  );
  const {
    isLoading: isLoadingUpdatePasswordWithCode,
    isSuccess: isSuccessUpdatePasswordWithCode,
    isError: isErrorUpdatePasswordWithCode,
    mutate: mutateUpdatePasswordWithCode,
  } = useUpdatePasswordWithCode();
  const {
    isLoading: isLoadingSignin,
    isSuccess: isSuccessSignin,
    mutate: mutateSignIn,
  } = useSigninMutation(setHasError, setMsgError, props.t);
  const {
    data: afterSignInData,
    isFetching: isFetchingGetAfterSignInData,
    isSuccess: isSuccessGetAfterSignInData,
    isError: isErrorGetAfterSignInData,
  } = useGetAfterSignInData(
    isGetAfterSignInDataEnabled,
    email,
    getAfterSignInDataQueryKey,
  );
  const navigate = useNavigate();
  const themeGlobal = useTheme();
  const [showCodeSentToEmailNotification, setShowCodeSentToEmailNotification] =
    useState<boolean>(false);
  const [showPasswordUpdatedSuccessfully, setShowPasswordUpdatedSuccessfully] =
    useState<boolean>(false);

  useEffect(() => {
    if (isAuthenticated()) {
      setIsGetAfterSignInDataEnabled(true);
    }
  }, []);

  useEffect(() => {
    if (isSuccessSignin) {
      if (isSuccessGetAfterSignInData) {
        successfulSignInHandler();
      } else {
        setIsGetAfterSignInDataEnabled(true);
      }
    }
  }, [isSuccessSignin]);

  useEffect(() => {
    if (isSuccessGetAfterSignInData) {
      successfulSignInHandler();
    }
  }, [isSuccessGetAfterSignInData]);

  useEffect(() => {
    setIsForgotPasswordEmailValid(false);
    if (forgotPasswordFormStep == 1) setIsEmailValid(false);
  }, [displayForgotPasswordForm, forgotPasswordFormStep]);

  useEffect(() => {
    if (forceEmailErrorDisplay && isEmailInputFocused == true) {
      setForceEmailErrorDisplay(undefined);
    }
    if (forcePasswordErrorDisplay && isPasswordInputFocused == true) {
      setForcePasswordErrorDisplay(undefined);
    }
  }, [isEmailInputFocused, isPasswordInputFocused]);

  useEffect(() => {
    if (forceEmailErrorDisplay) setForceEmailErrorDisplay(undefined);
  }, [email]);

  useEffect(() => {
    if (forcePasswordErrorDisplay) setForcePasswordErrorDisplay(undefined);
  }, [password]);

  const executeReCaptcha = () => {
    const { grecaptcha } = window as unknown as IProjectWindow;
    grecaptcha.ready(() => {
      grecaptcha
        .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_SITE_KEY, {
          action: 'homepage',
        })
        .then((token: string) => {
          Promise.all([verifyToken(token)])
            .then(([data]) => {
              if (data) {
                setValidateCaptcha(true);
                toast.success(
                  props.t('signin.generic.googleRecaptchaTokenValid'),
                );
              } else {
                setValidateCaptcha(false);
                toast.error(
                  props.t('signin.generic.googleRecaptchaTokenInvalid'),
                );
              }
            })
            .catch(err => {});
        });
    });
  };

  useEffect(() => {
    if (forceForgotPasswordEmailErrorDisplay)
      setForceForgotPasswordEmailErrorDisplay(undefined);
  }, [forgotPasswordEmail]);

  useEffect(() => {
    if (emailValid(forgotPasswordEmail!) && isForgotPasswordEmailValid) {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_SITE_KEY}`;
      script.addEventListener('load', executeReCaptcha);
      document.body.appendChild(script);
    }
  }, [isForgotPasswordEmailValid]);

  useEffect(() => {
    if (isForgotPasswordEmailInputFocused) {
      setForceForgotPasswordEmailErrorDisplay(undefined);
    }
  }, [isForgotPasswordEmailInputFocused]);

  useEffect(() => {
    if (isNewPasswordInputFocused) {
      setForceNewPasswordErrorDisplay(undefined);
    }
    if (isConfirmNewPasswordInputFocused) {
      setForceConfirmNewPasswordErrorDisplay(undefined);
    }
  }, [isNewPasswordInputFocused, isConfirmNewPasswordInputFocused]);

  useEffect(() => {
    if (countDownTimerRefId.current && forgotPasswordFormStep != 2) {
      clearInterval(countDownTimerRefId.current);
    }
  }, [forgotPasswordFormStep]);

  useEffect(() => {
    if (countDownTimerRefId.current && numberOfSecondsRemaining == 0) {
      clearInterval(countDownTimerRefId.current);
    }
  }, [numberOfSecondsRemaining]);

  useEffect(() => {
    return () => {
      if (countDownTimerRefId.current) {
        clearInterval(countDownTimerRefId.current);
      }
    };
  }, []);

  useEffect(() => {
    if (
      isSuccessSendForgotPasswordCodeEmail &&
      (forgotPasswordFormStep == 1 || forgotPasswordFormStep == 2)
    ) {
      setShowCodeSentToEmailNotification(true);
      setForgotPasswordFormStep(2);
      setTimeout(() => {
        setShowCodeSentToEmailNotification(false);
      }, 2000);
    }
  }, [isSuccessSendForgotPasswordCodeEmail]);

  useEffect(() => {
    if (isSuccessUpdatePasswordWithCode && forgotPasswordFormStep == 3) {
      setShowPasswordUpdatedSuccessfully(true);
      setTimeout(() => {
        setShowPasswordUpdatedSuccessfully(false);
      }, 2000);
      setDisplayForgotPasswordForm(false);
      setForgotPasswordEmail('');
      setVerificationCode('');
      setNewPassword('');
    }
  }, [isSuccessUpdatePasswordWithCode]);

  useEffect(() => {
    if (isErrorVerifyForgotPasswordCode) {
      setHasError(true);
      setMsgError(
        props.t(
          'signin.generic.verificationCodeDoesNotMatchVerificationCodeSentToEmail',
        ),
      );
    }
  }, [isErrorVerifyForgotPasswordCode]);

  useEffect(() => {
    if (isErrorUpdatePasswordWithCode) {
      setHasError(true);
      setMsgError(props.t('signin.generic.couldNotUpdatePasswordSuccessfully'));
    }
  }, [isErrorUpdatePasswordWithCode]);

  useEffect(() => {
    if (isErrorGetAfterSignInData) {
      setHasError(true);
      setMsgError(props.t('signin.generic.couldNotSignIn'));
      setIsGetAfterSignInDataEnabled(false);
      sessionStorage.removeItem('jwt');
    }
  }, [isErrorGetAfterSignInData]);

  useEffect(() => {
    if (isSuccessSendForgotPasswordCodeEmail) {
      setNumberOfSecondsRemaining(30);
    }
  }, [isSuccessSendForgotPasswordCodeEmail]);

  useEffect(() => {
    if (numberOfSecondsRemaining == 30 && forgotPasswordFormStep == 2) {
      countDownTimerRefId.current = setInterval(() => {
        if (numberOfSecondsRemaining > 0) {
          setNumberOfSecondsRemaining(
            prevNumberOfSecondsRemaining => prevNumberOfSecondsRemaining - 1,
          );
        }
      }, 1400);
    }
  }, [numberOfSecondsRemaining]);

  useEffect(() => {
    if (
      !isFetchingVerifyForgotPasswordCode &&
      isSuccessVerifyForgotPasswordCode &&
      forgotPasswordFormStep == 2
    ) {
      if (verifyForgotPasswordCodeResult == false) {
        setHasError(true);
        setMsgError(
          props.t(
            'signin.generic.verificationCodeDoesNotMatchVerificationCodeSentToEmail',
          ),
        );
      } else setForgotPasswordFormStep(3);
    }
  }, [isSuccessVerifyForgotPasswordCode, isFetchingVerifyForgotPasswordCode]);

  useEffect(() => {
    if (verificationCode.replace(/\s/, '').length < 6)
      setEnabledVerifyForgotPasswordCode(false);
  }, [verificationCode]);

  useEffect(() => {
    if (!displayForgotPasswordForm) {
      setIsEmailValid(false);
    }
  }, [displayForgotPasswordForm]);

  useEffect(() => {
    if (hasError) {
      errorModalRef.current!.focus();
    } else if (errorModalRef.current) {
      errorModalRef.current.blur();
      if (displayForgotPasswordForm) {
        if (forgotPasswordFormStep == 3) {
          pageContainerRef.current!.focus();
        } else if (forgotPasswordFormStep == 2) {
          pageContainerRef.current!.focus();
        } else if (forgotPasswordFormStep == 1) {
          pageContainerRef.current!.focus();
        }
      }
    }
  }, [hasError]);

  const isSignInButtonEnabled = () => {
    return (
      !valueEmpty(email) &&
      isEmailValid &&
      !valueEmpty(password) &&
      isPasswordValid &&
      !isLoadingSignin &&
      !isFetchingGetAfterSignInData
    );
  };

  const isSubmitVerificationCodeButtonEnabled = () => {
    if (
      !isFetchingVerifyForgotPasswordCode &&
      verificationCode.replace(/\s/, '').length == 6
    )
      return true;
    return false;
  };

  const signInActionInvoked = () => {
    if (
      valueEmpty(email) ||
      !isEmailValid ||
      valueEmpty(password) ||
      !isPasswordValid
    ) {
      if (valueEmpty(email)) {
        setForceEmailErrorDisplay(props.t('generic.emailIsRequired'));
      }
      if (valueEmpty(password)) {
        setForcePasswordErrorDisplay(props.t('generic.passwordIsRequired'));
      }
    } else {
      mutateSignIn({ email: email!, password: password! });
    }

    setHasSignInButtonBeenClicked(true);
  };

  const successfulSignInHandler = () => {
    setIsGetAfterSignInDataEnabled(false);
    if (!afterSignInData.isEmailVerified) {
      navigate('/account-activation');
    } else if (afterSignInData.isAdditionalDataStepComplete) {
      navigate('/discover');
    } else navigate('/additional-data-step');
  };

  const isConfirmNewPasswordButtonEnabled = () => {
    if (
      isNewPasswordValid &&
      isConfirmNewPasswordValid &&
      newPassword.localeCompare(confirmNewPassword) == 0 &&
      !isLoadingUpdatePasswordWithCode
    )
      return true;
  };

  const isForgotPasswordSendEmailContinueButtonEnabled = () => {
    if (isForgotPasswordEmailValid && validateCaptcha) return true;
    return false;
  };

  const forgotPasswordSentEmailActionInvoked = () => {
    if (!isForgotPasswordEmailValid) {
      if (valueEmpty(forgotPasswordEmail))
        setForceForgotPasswordEmailErrorDisplay(
          props.t('generic.emailIsRequired'),
        );
      else
        setForceForgotPasswordEmailErrorDisplay(
          props.t('generic.emailFormatIsNotValid'),
        );
    } else if (isForgotPasswordSendEmailContinueButtonEnabled()) {
      mutateSendForgotPasswordCodeEmail(forgotPasswordEmail as string);
    }

    setHasForgotPasswordEmailSendButtonBeenClicked(true);
  };

  const backArrowClickHandler = () => {
    switch (forgotPasswordFormStep) {
      case 1: {
        setDisplayForgotPasswordForm(false);
        setForgotPasswordEmail('');
        setHasForgotPasswordEmailBeenChanged(false);
        setForceForgotPasswordEmailErrorDisplay('');
        setHasForgotPasswordEmailSendButtonBeenClicked(false);
        setVerificationCode('');
        setShowForgotPasswordVerificationCodeFormatNotValid(false);
        setNewPassword('');
        setHasNewPasswordBeenChanged(false);
        setForceNewPasswordErrorDisplay('');
        setConfirmNewPassword('');
        setHasConfirmNewPasswordBeenChanged(false);
        setForceConfirmNewPasswordErrorDisplay('');
        setHasUpdatePasswordButtonBeenClicked(false);
        break;
      }
      case 2: {
        setForgotPasswordFormStep(1);
        break;
      }
      case 3: {
        setForgotPasswordFormStep(2);
      }
    }
  };

  const isForwardArrowButtonEnabled = () => {
    switch (forgotPasswordFormStep) {
      case 1: {
        if (
          isForgotPasswordEmailValid &&
          !isLoadingSendForgotPasswordCodeEmail &&
          isSuccessSendForgotPasswordCodeEmail
        ) {
          return true;
        }
        return false;
      }
      case 2: {
        if (!isSuccessVerifyForgotPasswordCode) return false;
        return true;
      }
    }
  };

  const forwardArrowClickHandler = () => {
    switch (forgotPasswordFormStep) {
      case 1: {
        setForgotPasswordFormStep(2);
        break;
      }
      case 2: {
        setForgotPasswordFormStep(3);
        break;
      }
    }
  };

  const onPageContainerKeyDownHandler = async (event: any) => {
    if (displayForgotPasswordForm && forgotPasswordFormStep == 2) {
      if (event.keyCode == 86 && event.ctrlKey) {
        const clipboardContent = await navigator.clipboard.readText();
        if (typeof clipboardContent === 'string') {
          let verificationCodeLocal = '';
          let i = 0;
          for (; i < 6; i++) {
            if (clipboardContent[i]) {
              verificationCodeLocal = verificationCodeLocal.concat(
                clipboardContent[i],
              );
            } else break;
          }
          const j = i;
          if (i != 6) {
            for (; i < 6; i++) {
              verificationCodeLocal = verificationCodeLocal.concat(' ');
            }
            switch (j) {
              case 0:
                verificationCodeInputRef1.current?.focus();
                break;
              case 1:
                verificationCodeInputRef2.current?.focus();
                break;
              case 2:
                verificationCodeInputRef3.current?.focus();
                break;
              case 3:
                verificationCodeInputRef4.current?.focus();
                break;
              case 4:
                verificationCodeInputRef5.current?.focus();
                break;
              case 5:
                verificationCodeInputRef6.current?.focus();
                break;
            }
          } else verificationCodeInputRef6.current?.focus();
          setVerificationCode(verificationCodeLocal);
        }
      }
    }
  };

  const onPageContainerKeyUpHandler = (event: any) => {
    if (event.keyCode == 13) {
      if (hasError) {
        setHasError(false);
      } else if (displayForgotPasswordForm) {
        if (forgotPasswordFormStep == 1) {
          if (isForgotPasswordEmailValid) {
            mutateSendForgotPasswordCodeEmail(forgotPasswordEmail as string);
          }
        } else if (forgotPasswordFormStep == 2) {
          if (verificationCode.replace(/\s/, '').length == 6) {
            refetchVerifyForgotPasswordCode();
          }
        } else if (forgotPasswordFormStep == 3) {
          if (isNewPasswordValid) {
            mutateUpdatePasswordWithCode({
              email: forgotPasswordEmail!,
              code: verificationCode!,
              password: newPassword!,
            });
          }
        }
      } else {
        signInActionInvoked();
      }
    }
  };

  const onErrorModalCloseButtonClick = () => {
    if (isErrorGetAfterSignInData) {
      setIsGetAfterSignInDataEnabled(true);
      setGetAfterSignInDataQueryKey(String(new Date().getTime()));
    }
    setHasError(false);
  };

  const getForgotPasswordForm = (step: number) => {
    switch (step) {
      case 1: {
        return (
          <>
            <S.ForgotPasswordHeaderContainer>
              <S.ForgotPasswordBackArrowContainer
                onClick={() => backArrowClickHandler()}
                onMouseDown={(event: any) => event.preventDefault()}
              >
                <BackArrowIcon width={'2.4rem'} height={'2.4rem'} />
              </S.ForgotPasswordBackArrowContainer>
              <S.ForgotPasswordTitleContainer>
                {props.t('signin.generic.recoverPassword')}
              </S.ForgotPasswordTitleContainer>
              {isForwardArrowButtonEnabled() ? (
                <S.ForgotPasswordBackArrowContainer
                  onClick={() => forwardArrowClickHandler()}
                >
                  <ForwardArrowIcon width={'2.4rem'} height={'2.4rem'} />
                </S.ForgotPasswordBackArrowContainer>
              ) : (
                <S.ForgotPasswordHeaderHiddenFlexItem />
              )}
            </S.ForgotPasswordHeaderContainer>

            <S.ForgotPasswordEmailInputContainer>
              <S.FormInputContainer>
                <InputControlV2
                  value={forgotPasswordEmail}
                  onChange={(inputValue: string) => {
                    setForgotPasswordEmail(inputValue);
                    setHasForgotPasswordEmailBeenChanged(true);
                  }}
                  setIsInvalidOrProcessing={(isInvalidOrProcessing: boolean) =>
                    setIsForgotPasswordEmailValid(!isInvalidOrProcessing)
                  }
                  validationFunction={emailValid}
                  title={props.t('generic.email')}
                  setFocused={focused =>
                    setIsForgotPasswordEmailInputFocused(focused)
                  }
                  setBlurred={blurred => {
                    setIsForgotPasswordEmailInputFocused(!blurred);
                    if (
                      !forgotPasswordEmail.length &&
                      (hasForgotPasswordEmailBeenChanged ||
                        hasForgotPasswordEmailSendButtonBeenClicked)
                    ) {
                      setForceForgotPasswordEmailErrorDisplay(
                        props.t('generic.emailIsRequired'),
                      );
                    }
                  }}
                  forceErrorDisplay={forceForgotPasswordEmailErrorDisplay}
                  forceBorderColorRed={Boolean(
                    forceForgotPasswordEmailErrorDisplay,
                  )}
                  enterKeyPressedHandler={() =>
                    forgotPasswordSentEmailActionInvoked()
                  }
                  allowGreenBorder={true}
                  allowCheckMarkIcon={true}
                  validateOnFirstRender={false}
                  focusOnMount={true}
                  invalidFieldWarningMessage={props.t(
                    'generic.emailFormatIsNotValid',
                  )}
                />
              </S.FormInputContainer>
            </S.ForgotPasswordEmailInputContainer>

            <S.CaptchaContinueButtonContainer>
              <Button
                themeStyles={getThemeStyles(
                  ['button', 'validate-captcha'],
                  themeGlobal,
                )}
                borderRadius={'0.6rem'}
                borderWidth={'0rem'}
                height={'4rem'}
                fontSize={'1.6rem'}
                display={'block'}
                width={'100%'}
                marginBottom="1rem"
                textAlign={'center'}
                fontWeight={'500'}
                cursorOnDisable={'default'}
                blockHoverStylesOnDisable={true}
                pointerEventsOnDisable={'all'}
                disabledNotNative={!validateCaptcha}
                onClick={() => forgotPasswordSentEmailActionInvoked()}
              >
                {props.t('signin.generic.sendEmail')}
              </Button>

              <S.SignInFormFooterCaptcha>
                {props.t('signin.recaptchaFooter.part1')}
                <br />
                <S.SignInFormFooterLink href="https://policies.google.com/privacy">
                  {props.t('signin.recaptchaFooter.part2')}
                </S.SignInFormFooterLink>
                {props.t('signin.recaptchaFooter.part3')}
                <S.SignInFormFooterLink href="https://policies.google.com/terms">
                  {props.t('signin.recaptchaFooter.part4')}
                </S.SignInFormFooterLink>
                {props.t('signin.recaptchaFooter.part5')}
              </S.SignInFormFooterCaptcha>
            </S.CaptchaContinueButtonContainer>
          </>
        );
      }
      case 2: {
        return (
          <>
            <S.ForgotPasswordHeaderContainer>
              <S.ForgotPasswordBackArrowContainer
                onClick={() => backArrowClickHandler()}
              >
                <BackArrowIcon width={'2.4rem'} height={'2.4rem'} />
              </S.ForgotPasswordBackArrowContainer>
              <S.ForgotPasswordTitleContainer>
                {props.t('signin.generic.typeVerificationCode')}
              </S.ForgotPasswordTitleContainer>
              {isForwardArrowButtonEnabled() ? (
                <S.ForgotPasswordBackArrowContainer
                  onClick={() => forwardArrowClickHandler()}
                >
                  <ForwardArrowIcon width={'2.4rem'} height={'2.4rem'} />
                </S.ForgotPasswordBackArrowContainer>
              ) : (
                <S.ForgotPasswordHeaderHiddenFlexItem />
              )}
            </S.ForgotPasswordHeaderContainer>

            <S.ForgotPasswordContentOuterContainer>
              <S.ForgotPasswordContentTextContainer>
                {props.t('signin.generic.verificationCodeWasSentToEmail')}
              </S.ForgotPasswordContentTextContainer>
              <S.ForgotPasswordCodeInputContainer
                redBorder={showForgotPasswordVerificationCodeFormatNotValid}
                onClick={(event: any) => {
                  if (
                    Array.from(event.target.classList).includes(
                      'ReactInputVerificationCode__container',
                    ) ||
                    Array.from(event.target.classList).includes(
                      'ReactInputVerificationCode__input',
                    )
                  ) {
                    setShowForgotPasswordVerificationCodeFormatNotValid(false);
                  }
                }}
              >
                <ReactInputVerificationCode
                  autoFocus={true}
                  length={6}
                  placeholder=""
                  onChange={setVerificationCode}
                  value={verificationCode}
                />
              </S.ForgotPasswordCodeInputContainer>

              {showForgotPasswordVerificationCodeFormatNotValid && (
                <S.InvalidForgotPasswordVerificationCodeWarning>
                  {props.t('signin.generic.invalidVerificationCodeFormat')}
                </S.InvalidForgotPasswordVerificationCodeWarning>
              )}
            </S.ForgotPasswordContentOuterContainer>

            <S.ForgotPasswordSubmitVerificationCodeButtonContainer>
              <Button
                borderRadius={'0.6rem'}
                borderWidth={'0rem'}
                height={'4rem'}
                fontSize={'1.6rem'}
                display={'block'}
                width={'100%'}
                textAlign={'center'}
                marginRight={'4rem'}
                fontWeight={'500'}
                cursorOnDisable={'default'}
                blockHoverStylesOnDisable={true}
                pointerEventsOnDisable={'all'}
                disabledNotNative={!isSubmitVerificationCodeButtonEnabled()}
                onClick={() => {
                  if (isSubmitVerificationCodeButtonEnabled()) {
                    refetchVerifyForgotPasswordCode();
                  } else if (
                    !(verificationCode.replace(/\s/, '').length == 6)
                  ) {
                    setShowForgotPasswordVerificationCodeFormatNotValid(true);
                  }
                }}
              >
                {props.t('generic.submit')}
              </Button>
            </S.ForgotPasswordSubmitVerificationCodeButtonContainer>
          </>
        );
      }
      case 3: {
        return (
          <>
            <S.ForgotPasswordHeaderContainer>
              <S.ForgotPasswordBackArrowContainer
                onClick={() => backArrowClickHandler()}
              >
                <BackArrowIcon width={'2.4rem'} height={'2.4rem'} />
              </S.ForgotPasswordBackArrowContainer>
              <S.ForgotPasswordTitleContainer>
                {props.t('signin.generic.enterNewPassword')}
              </S.ForgotPasswordTitleContainer>
              <S.ForgotPasswordHeaderHiddenFlexItem />
            </S.ForgotPasswordHeaderContainer>

            <S.ForgotPasswordEmailInputContainer>
              <S.FormInputContainer>
                <S.FormInputContainer>
                  <InputControlV2
                    value={newPassword}
                    onChange={(inputValue: string) => {
                      setNewPassword(inputValue);
                      setHasNewPasswordBeenChanged(true);
                    }}
                    setIsInvalidOrProcessing={(
                      isInvalidOrProcessing: boolean,
                      isProcessing: boolean,
                    ) => {
                      setIsNewPasswordValid(!isInvalidOrProcessing);
                      if (
                        !isProcessing &&
                        hasNewPasswordBeenChanged &&
                        confirmNewPassword &&
                        !isConfirmNewPasswordProcessing
                      ) {
                        setTriggerConfirmNewPasswordValidation(
                          prevState => prevState + 1,
                        );
                      }
                    }}
                    validationFunction={passwordValid}
                    type={'password'}
                    title={props.t('generic.password')}
                    setFocused={focused =>
                      setIsNewPasswordInputFocused(focused)
                    }
                    setBlurred={blurred => {
                      setIsNewPasswordInputFocused(!blurred);
                      if (
                        !newPassword.length &&
                        (hasNewPasswordBeenChanged ||
                          hasUpdatePasswordButtonBeenClicked)
                      ) {
                        setForceNewPasswordErrorDisplay(
                          props.t('generic.passwordIsRequired'),
                        );
                      }
                    }}
                    isFocused={isNewPasswordInputFocused}
                    forceErrorDisplay={forceNewPasswordErrorDisplay}
                    forceBorderColorRed={Boolean(forceNewPasswordErrorDisplay)}
                    invalidFieldWarningMessage={getPasswordInvalidMessage(
                      password,
                    )}
                    validateOnFirstRender={true}
                    allowGreenBorder={true}
                    allowCheckMarkIcon={true}
                    showPasswordTooltip={!!isNewPasswordInputFocused}
                    passwordTooltipMarginTop={'1.7rem'}
                  />
                </S.FormInputContainer>

                <S.FormInputContainer>
                  <InputControlV2
                    value={confirmNewPassword}
                    onChange={(inputValue: string) => {
                      setConfirmNewPassword(inputValue);
                      setHasConfirmNewPasswordBeenChanged(true);
                    }}
                    setIsInvalidOrProcessing={(
                      isInvalidOrProcessing: boolean,
                      isProcessing: boolean,
                    ) => {
                      setIsConfirmNewPasswordValid(!isInvalidOrProcessing);
                      setIsConfirmNewPasswordProcessing(isProcessing);
                    }}
                    validationFunction={() =>
                      !confirmNewPassword.localeCompare(newPassword)
                    }
                    type={'password'}
                    title={props.t('signup.confirmPassword')}
                    setFocused={focused =>
                      setIsConfirmNewPasswordInputFocused(focused)
                    }
                    setBlurred={blurred => {
                      setIsConfirmNewPasswordInputFocused(!blurred);
                      if (
                        !confirmNewPassword.length &&
                        (hasConfirmNewPasswordBeenChanged ||
                          hasUpdatePasswordButtonBeenClicked)
                      ) {
                        setForceConfirmNewPasswordErrorDisplay(
                          props.t('signup.confirmPasswordIsRequired'),
                        );
                      }
                    }}
                    isFocused={isConfirmNewPasswordInputFocused}
                    forceErrorDisplay={forceConfirmNewPasswordErrorDisplay}
                    forceBorderColorRed={Boolean(
                      forceConfirmNewPasswordErrorDisplay,
                    )}
                    invalidFieldWarningMessage={props.t(
                      'signup.passwordsNeedToMatch',
                    )}
                    validateOnFirstRender={true}
                    allowGreenBorder={true}
                    allowCheckMarkIcon={true}
                    triggerValidationFunction={
                      triggerConfirmNewPasswordValidation
                    }
                    triggeredValidationFunctionTimer={0}
                  />
                </S.FormInputContainer>
              </S.FormInputContainer>
            </S.ForgotPasswordEmailInputContainer>

            <S.ForgotPasswordSubmitVerificationCodeButtonContainer>
              <Button
                borderRadius={'0.6rem'}
                borderWidth={'0rem'}
                height={'4rem'}
                fontSize={'1.6rem'}
                display={'block'}
                width={'100%'}
                textAlign={'center'}
                marginRight={'4rem'}
                fontWeight={'500'}
                cursorOnDisable={'default'}
                blockHoverStylesOnDisable={true}
                pointerEventsOnDisable={'all'}
                disabledNotNative={!isConfirmNewPasswordButtonEnabled()}
                onClick={() => {
                  if (isConfirmNewPasswordButtonEnabled()) {
                    mutateUpdatePasswordWithCode({
                      email: forgotPasswordEmail || '',
                      code: verificationCode,
                      password: newPassword,
                    });
                  } else {
                    if (!newPassword) {
                      setForceNewPasswordErrorDisplay(
                        props.t('generic.passwordIsRequired'),
                      );
                    }
                    if (!confirmNewPassword) {
                      setForceConfirmNewPasswordErrorDisplay(
                        props.t('signup.confirmPasswordIsRequired'),
                      );
                    }
                  }
                  setHasUpdatePasswordButtonBeenClicked(true);
                }}
              >
                {props.t('generic.confirm')}
              </Button>
            </S.ForgotPasswordSubmitVerificationCodeButtonContainer>
          </>
        );
      }
    }
  };

  const getForgotPasswordFormFooter = () => {
    switch (forgotPasswordFormStep) {
      case 1:
      case 3: {
        return (
          <>
            {props.t('signin.generic.havingProblemsWithYourEmail')}
            <S.SignInFormFooterLink
              href={`mailto:app@skorr.social?subject=Skorr Help :: Question`}
            >
              {props.t('signin.generic.contactUs')}
            </S.SignInFormFooterLink>
          </>
        );
      }
      case 2: {
        return (
          <>
            {props.t('signin.generic.didntReceiveAnEmail')}
            {numberOfSecondsRemaining == 0 ? (
              <S.SignInFormFooterLink
                onClick={() =>
                  mutateSendForgotPasswordCodeEmail(
                    forgotPasswordEmail as string,
                  )
                }
              >
                {props.t('signin.generic.resendEmail')}
              </S.SignInFormFooterLink>
            ) : (
              <S.ForgotPasswordFooterResendEmailTimer>
                {`${props.t(
                  'signin.generic.resendIn',
                )} ${numberOfSecondsRemaining}s`}
              </S.ForgotPasswordFooterResendEmailTimer>
            )}
          </>
        );
      }
    }
  };

  return (
    <S.Container
      tabIndex={-1}
      onKeyDown={event => onPageContainerKeyDownHandler(event)}
      onKeyUp={event => onPageContainerKeyUpHandler(event)}
      ref={pageContainerRef}
    >
      <S.BackgroundImageContainer>
        <S.BackgroundImageInnerContainer>
          <S.BackgroundImageLeft src={SkorrOnboardingBackgroundImage} />
        </S.BackgroundImageInnerContainer>
        <S.BackgroundImageInnerContainer>
          <S.BackgroundImageRight src={SkorrOnboardingBackgroundImage} />
        </S.BackgroundImageInnerContainer>
      </S.BackgroundImageContainer>

      <S.Navbar>
        <S.NavbarInnerContainer>
          <S.NavbarAnchorTag>
            <S.NavbarImage
              src={SkorrLogo}
              onClick={() => window.open('https://skorr.social', '_blank')}
            />
            <S.NavbarImageSmallWindow
              src={SkorrLogoSmallWindow}
              onClick={() => window.open('https://skorr.social', '_blank')}
            />
          </S.NavbarAnchorTag>
        </S.NavbarInnerContainer>
      </S.Navbar>

      <S.FormSectionContainer>
        <S.FormOuterContainer>
          <S.FormContainer>
            {displayForgotPasswordForm != true && (
              <S.FormContainerFirstHalf>
                <S.FormContainerFirstHalfInnerContainer>
                  <S.Title>{props.t('signin.generic.signin')}</S.Title>

                  <S.FormInputContainer>
                    <InputControlV2
                      value={email}
                      onChange={(inputValue: string) => {
                        setEmail(inputValue);
                        setHasEmailBeenChanged(true);
                      }}
                      setIsInvalidOrProcessing={(
                        isInvalidOrProcessing: boolean,
                      ) => setIsEmailValid(!isInvalidOrProcessing)}
                      validationFunction={emailValid}
                      title={props.t('generic.email')}
                      setFocused={focused => setIsEmailInputFocused(focused)}
                      setBlurred={blurred => {
                        setIsEmailInputFocused(!blurred);
                        if (
                          !email.length &&
                          (hasEmailBeenChanged || hasSignInButtonBeenClicked)
                        ) {
                          setForceEmailErrorDisplay(
                            props.t('generic.emailIsRequired'),
                          );
                        }
                      }}
                      forceErrorDisplay={forceEmailErrorDisplay}
                      forceBorderColorRed={Boolean(forceEmailErrorDisplay)}
                      enterKeyPressedHandler={() => signInActionInvoked()}
                      validateOnFirstRender={true}
                      focusOnMount={true}
                      invalidFieldWarningMessage={props.t(
                        'generic.emailFormatIsNotValid',
                      )}
                    />
                  </S.FormInputContainer>

                  <S.FormInputContainer>
                    <InputControlV2
                      value={password}
                      onChange={(inputValue: string) => {
                        setPassword(inputValue);
                        setHasPasswordBeenChanged(true);
                      }}
                      setIsInvalidOrProcessing={(
                        isInvalidOrProcessing: boolean,
                      ) => {
                        setIsPasswordValid(!isInvalidOrProcessing);
                      }}
                      type={'password'}
                      title={props.t('generic.password')}
                      validationFunction={passwordValid}
                      setFocused={focused => setIsPasswordInputFocused(focused)}
                      setBlurred={blurred => {
                        setIsPasswordInputFocused(!blurred);
                        if (
                          !password.length &&
                          (hasPasswordBeenChanged || hasSignInButtonBeenClicked)
                        ) {
                          setForcePasswordErrorDisplay(
                            props.t('generic.passwordIsRequired'),
                          );
                        }
                      }}
                      forceErrorDisplay={forcePasswordErrorDisplay}
                      forceBorderColorRed={Boolean(forcePasswordErrorDisplay)}
                      invalidFieldWarningMessage={getPasswordInvalidMessage(
                        password,
                      )}
                      enterKeyPressedHandler={() => signInActionInvoked()}
                    />
                  </S.FormInputContainer>

                  <S.PasswordOptionsContainer>
                    {/* <CheckBox 
                                            checked={keepMeSignedIn} 
                                            onChange={(checked: boolean) => setKeepMeSignedIn(checked)}
                                            label={props.t('signin.generic.keepMeSignedIn')}
                                            labelPosition={'right'}
                                            display={'flex'}
                                        /> */}
                    <S.ForgotPasswordLinkContainer
                      onClick={() => {
                        setDisplayForgotPasswordForm(true);
                        setForgotPasswordFormStep(1);
                      }}
                      onMouseDown={(event: any) => event.preventDefault()}
                    >
                      {props.t('signin.forgotPassword')}
                    </S.ForgotPasswordLinkContainer>
                  </S.PasswordOptionsContainer>

                  <Button
                    borderRadius={'0.6rem'}
                    borderWidth={'0rem'}
                    height={'4rem'}
                    fontSize={'1.6rem'}
                    display={'block'}
                    width={'100%'}
                    textAlign={'center'}
                    marginBlock={'2.4rem'}
                    marginTop={'3.2rem'}
                    fontWeight={'500'}
                    disabledNotNative={!isSignInButtonEnabled()}
                    cursorOnDisable={'default'}
                    blockHoverStylesOnDisable={true}
                    pointerEventsOnDisable={'all'}
                    onClick={() => signInActionInvoked()}
                  >
                    {props.t('signin.generic.signin')}
                  </Button>

                  {/* <S.SignInMethodDivider />

                                    <S.SignInSocialButtonsContainer>

                                        <Button
                                            themeStyles={(themeGlobal as any)['button']['signin-facebook']}
                                            paddingLeft={'0.6rem'} 
                                            paddingRight={'0.6rem'}
                                            paddingTop={'0.1rem'} 
                                            paddingBottom={'0.1rem'}
                                        >
                                            <FacebookIcon 
                                                svgWidth={'3.4rem'} 
                                                svgHeight={'2rem'} 
                                                flexBasis={'3.4rem'}
                                                svgMarginRight={'2rem'}
                                                justifyContent={'start'}
                                                containerHeight={'2rem'}
                                            />
                                                <S.SignInFacebookButtonLabelContainer>
                                                    {props.t('signin.generic.continueWithFacebook')}
                                                </S.SignInFacebookButtonLabelContainer>
                                        </Button>

                                        <Button
                                            themeStyles={(themeGlobal as any)['button']['signin-twitter']}
                                            paddingLeft={'0.6rem'} 
                                            paddingRight={'0.6rem'}
                                            paddingTop={'0.1rem'} 
                                            paddingBottom={'0.1rem'}
                                            textAlign={'center'}
                                            display={'flex'}
                                            justifyContent={'center'}
                                            alignItems={'center'}
                                        >
                                            <TwitterIcon 
                                                svgWidth={'2rem'} 
                                                svgHeight={'2rem'}
                                                containerHeight={'2rem'}
                                            />
                                        </Button>

                                    </S.SignInSocialButtonsContainer> */}
                </S.FormContainerFirstHalfInnerContainer>
              </S.FormContainerFirstHalf>
            )}

            {displayForgotPasswordForm == true &&
              getForgotPasswordForm(forgotPasswordFormStep as number)}

            <S.SignInFormContentDivider />

            <S.SignInFormFooter>
              {displayForgotPasswordForm != true ? (
                <S.SignInFormFooterInner>
                  <div>
                    {props.t('signin.generic.dontHaveAnAccount')}
                    <S.SignInFormFooterLink
                      onClick={() => navigate('/signup')}
                      onMouseDown={(event: any) => event.preventDefault()}
                    >
                      {props.t('signin.generic.signup')}
                    </S.SignInFormFooterLink>
                  </div>
                </S.SignInFormFooterInner>
              ) : (
                getForgotPasswordFormFooter()
              )}
            </S.SignInFormFooter>
          </S.FormContainer>

          <S.SignInPageFooter>
            <S.SignInPageFooterLinksContainer>
              <S.SignInPageFooterLink
                onClick={() => window.open('https://skorr.social', '_blank')}
              >
                {props.t('signin.generic.about')}
              </S.SignInPageFooterLink>
              <S.SignInPageFooterLink
                onClick={() =>
                  window.open('https://skorr.social/terms', '_blank')
                }
              >
                {props.t('signin.generic.terms')}
              </S.SignInPageFooterLink>
            </S.SignInPageFooterLinksContainer>
            <S.SignInPageFooterLangCopyRightsContainer>
              <S.CopyRightSpan>
                {props.t('signin.generic.copyright')}
              </S.CopyRightSpan>
            </S.SignInPageFooterLangCopyRightsContainer>
          </S.SignInPageFooter>
        </S.FormOuterContainer>

        <S.SecondBackgroundImageContainer />
      </S.FormSectionContainer>

      <NotifyCustom
        location={'bottom'}
        show={isLoadingSendForgotPasswordCodeEmail}
        themeStyles={(themeGlobal as any).signin['regular-notify']}
      >
        <S.NotifyInnerContainer>
          <InputSpinnerIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
          />
          {props.t('signin.generic.sendingForgotPasswordCodeToEmail')}
        </S.NotifyInnerContainer>
      </NotifyCustom>

      <NotifyCustom
        location={'bottom'}
        show={showCodeSentToEmailNotification}
        themeStyles={(themeGlobal as any).signin['regular-notify']}
      >
        <S.NotifyInnerContainer>
          <EnvelopeIcon width={'2rem'} height={'2rem'} marginRight={'1rem'} />
          {props.t('signin.generic.verificationCodeWasSentToEmail')}
        </S.NotifyInnerContainer>
      </NotifyCustom>

      <NotifyCustom
        location={'bottom'}
        show={isFetchingVerifyForgotPasswordCode}
        themeStyles={(themeGlobal as any).signin['regular-notify']}
      >
        <S.NotifyInnerContainer>
          <InputSpinnerIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
          />
          {props.t('signin.generic.validatingVerificationCode')}
        </S.NotifyInnerContainer>
      </NotifyCustom>

      <NotifyCustom
        location={'bottom'}
        show={isLoadingUpdatePasswordWithCode}
        themeStyles={(themeGlobal as any).signin['regular-notify']}
      >
        <S.NotifyInnerContainer>
          <InputSpinnerIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
          />
          {props.t('signin.generic.updatingPassword')}
        </S.NotifyInnerContainer>
      </NotifyCustom>

      <NotifyCustom
        location={'bottom'}
        show={showPasswordUpdatedSuccessfully}
        themeStyles={(themeGlobal as any).signin['success-notify']}
      >
        <S.NotifyInnerContainer>
          <InputCheckMarkIcon
            usage={'notifyCustom'}
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
          />
          {props.t('signin.generic.passwordUpdatedSuccessfully')}
        </S.NotifyInnerContainer>
      </NotifyCustom>

      <NotifyCustom
        location={'bottom'}
        show={isLoadingSignin || isFetchingGetAfterSignInData}
        themeStyles={(themeGlobal as any).signin['regular-notify']}
      >
        <S.NotifyInnerContainer>
          <InputSpinnerIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
          />
          {props.t('signin.generic.signingIn')}
        </S.NotifyInnerContainer>
      </NotifyCustom>

      <GenericModal
        show={hasError}
        title={props.t('generic.uhoh')}
        body={msgError}
        primaryButtonText={props.t('generic.close')}
        primaryButtonAction={() => onErrorModalCloseButtonClick()}
        refNotNative={errorModalRef}
      />
    </S.Container>
  );
};

export default withTranslation()(SignIn);
