import styled from 'styled-components';

export const ToolBarOrderByButton = styled.div<{ active: boolean }>`
  height: 4rem;
  display: flex;
  color: #676767;
  padding: 1.2rem;
  font-weight: 400;
  line-height: 1.8;
  font-size: 1.4rem;
  align-items: center;
  border-radius: 0.6rem;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 1.6rem;
    height: 1.6rem;
    margin-left: 1rem;
    transition: transform 1s ease;
    transform: ${({ active }) => (active ? 'rotate(180deg)' : undefined)};
  }

  &:hover {
    background: #f7f8fa;
  }

  &:active {
    background: #eff1f6;
  }
`;

export const ToolBarOrderByDropdownContainer = styled.div<{ active: boolean }>`
  top: 11.5rem;
  right: 15.5rem;
  z-index: 10;
  width: 16rem;
  height: auto;
  display: flex;
  padding: 1rem;
  background: #fff;
  overflow: hidden;
  position: absolute;
  transition: all 0.08s;
  border-radius: 0.6rem;
  box-sizing: border-box;
  flex-direction: column;
  opacity: ${({ active }) => (active ? '1' : '0')};
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  box-shadow: 0 0.6rem 1rem rgba(0, 0, 0, 0.14),
    0 0.1rem 1.8rem rgba(0, 0, 0, 0.12), 0 0.3rem 0.5rem rgba(0, 0, 0, 0.2);
`;

export const ToolBarOrderByDropdownItemContainer = styled.div<{
  active: boolean;
}>`
  width: 100%;
  display: flex;
  padding: 1rem 2rem;
  position: relative;
  margin-top: 0.4rem;
  align-items: center;
  border-radius: 0.6rem;
  background: ${({ active }) => (active ? '#eff1f6' : undefined)};
  cursor: pointer;

  &:first-of-type {
    margin-top: 0;
  }

  &:hover {
    background: #f7f8fa;
  }

  &:active {
    background: #eff1f6;
  }
`;

export const ToolBarOrderByDropdownItemText = styled.div`
  color: #292929;
  font-weight: 400;
  overflow: hidden;
  font-size: 1.4rem;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
